import * as CryptoJS from "crypto-js";

export function encryptString(value: string, secretKey: string): string {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
}

export function decryptString(
  encryptedText: string,
  secretKey: string
): string {
  const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
