import { useContext } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import styleModal from "./modal-pdf.styles.module.scss";
import myFont from "./font/Arial-Light.ttf";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
import { formatNumber } from "../../utils/format-number.ts";
function convertToSpaces(str: string) {
  if (str === "criF_National_Id") {
    return "CRIF National ID";
  }
  if (str === "dbrVerificationStatus") {
    return "DBR verification status";
  }

  if (str === "nationalIdsVerificationStatus") {
    return "National ID verification status";
  }
  if (str === "maxDelayDaysStatus") {
    return "Max delay days verification status";
  }

  if (str === "kyC_National_Id") {
    return "KYC National ID";
  }
  if (str === "dbr") {
    return "DBR";
  }
  if (str === "probabilityOfGood") {
    return "Probability of good";
  }
  // Replace all underscore characters with a space
  str = str.replace(/_/g, " ");

  // Insert a space before all capital letters that are followed by a lowercase letter
  str = str.replace(/([A-Z])(?=[a-z])/g, " $1");

  // Make the first character of each word uppercase and the rest lowercase
  str = str
    .toLowerCase()
    .replace(/(^|\s)(\S)/g, function (_match: any, p1, p2) {
      return p1.toUpperCase() + p2;
    });

  str = str.charAt(0).toUpperCase() + str.slice(1);

  return str;
}

Font.register({
  family: "Arial",
  src: myFont,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  viewer: {
    width: "90%",
    height: "80%",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.1)",
  },

  container_left_texts: {},

  text_header: {
    fontFamily: "Arial",
    fontSize: "25px",
  },

  text_left_header: {
    fontFamily: "Arial",
    fontSize: "14px",
    marginLeft: "15px",
  },

  table_container: {
    border: "1px solid silver",
    width: "94%",
    margin: "3%",
  },

  table_rows: {
    width: "100%",
    height: "25px",
    borderBottom: "1px solid silver",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  table_item: {
    borderRight: "1px solid silver",
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },

  text_row: {
    fontFamily: "Arial",
    fontSize: "14px",
    marginLeft: "15px",
  },

  footer_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "94%",
    margin: "3%",
  },

  text_footer: {
    fontFamily: "Arial",
    fontSize: "16px",
  },
});

export default function ModalPdfGroupLoanComponent({
  data,
  modal,
  setModal,
}: {
  data: any;
  modal: boolean;
  setModal: any;
}) {
  const { authData } = useContext(AuthContext);
  const navigate = useNavigate();
  const { lang } = useContext(LanguageContext);

  function formatDate(data: string, lang: "ar" | "en") {
    const dateString = data;
    if (!dateString) return;

    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return Intl.DateTimeFormat(
      lang === "en" ? "en-EN" : "ar-u-nu-arab",
      options as object
    ).format(date);
  }

  return (
    <div
      style={
        modal
          ? lang === "ar"
            ? { direction: "rtl" }
            : {}
          : { display: "none" }
      }
      className={styleModal.pdf_container}
      onClick={() => {
        setModal(false);
        navigate("/app/dashboard");
      }}
    >
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text style={styles.text_header}>{text.score[lang]} - Loan</Text>
            </View>
            <View style={styles.container_left_texts}>
              <Text style={styles.text_left_header}>
                {text.name_of_expert[lang]}: {authData?.firstName}{" "}
                {authData?.lastName} ({authData?.username})
              </Text>
              <Text style={styles.text_left_header}>
                {text.date_time[lang]}: {formatDate(data.requestTime, lang)}
              </Text>
              <Text style={styles.text_left_header}>
                {text.request_id[lang]}: {data.loanScoreRequestId}
              </Text>
            </View>

            {data.input.loanModels.map((e: any, index: number) => (
              <>
                <Text
                  style={{
                    paddingTop: 20,
                    fontFamily: "Arial",
                    fontSize: "14px",
                    marginLeft: "15px",
                  }}
                >
                  Client {index + 1} Data
                </Text>
                <View style={styles.table_container}>
                  {Object.keys(e).map((key) =>
                    key === "ocR_status" ||
                    key === "probabilityOfGood" ||
                    key === "requestTime" ||
                    key === "systemUsername" ? null : (
                      <View key={key} style={styles.table_rows}>
                        <View style={styles.table_item}>
                          <Text style={styles.text_row}>
                            {convertToSpaces(key)}
                          </Text>
                        </View>
                        <View style={styles.table_item}>
                          <Text style={styles.text_row}>
                            {key === "gender"
                              ? e[key] === true
                                ? "Male"
                                : "Female"
                              : key === "interestRateMonthly"
                              ? e[key] * 100 + "%"
                              : e[key]}{" "}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>
              </>
            ))}

            {data.singleLoanResponses.map((e: any, index: number) => (
              <>
                <Text style={styles.text_left_header}>
                  Client {index + 1} Scoring Result
                </Text>
                <View style={styles.table_container}>
                  {Object.keys(e).map((key) =>
                    key === "input" ||
                    key === "isBadLoan" ||
                    key === "ocrStatus" ||
                    key === "isBadLoan" ? (
                      <></>
                    ) : key === "ocR_status" ||
                      key === "probabilityOfGood" ||
                      key === "requestTime" ||
                      key === "loanScoreRequestId" ||
                      key === "systemUsername" ? null : (
                      <View key={key} style={styles.table_rows}>
                        <View style={styles.table_item}>
                          <Text style={styles.text_row}>
                            {convertToSpaces(key)}
                          </Text>
                        </View>
                        <View style={styles.table_item}>
                          <Text style={styles.text_row}>
                            {key === "gender"
                              ? e[key] === true
                                ? "Male"
                                : "Female"
                              : typeof e[key] === "boolean"
                              ? e[key]
                                ? "Pass"
                                : "Fail"
                              : key === "threshold"
                              ? e[key] * 100
                              : key === "dbr_value"
                              ? Number(e[key] * 100).toFixed(2) + "%"
                              : e[key]}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </View>
              </>
            ))}

            <Text style={styles.text_left_header}>Result group loan</Text>

            <View style={styles.table_container}>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Loan ID</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{data.input.id}</Text>
                </View>
              </View>
              {/* <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Inflation rate </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.input.inflationRate}
                  </Text>
                </View>
              </View> */}
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Interest rate</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.input.interestRate}%
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Loan duration</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{data.input.loanDuration}</Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Age verification status</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.ageVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>DBR verification status</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.dbrVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    National ID verification status
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.nationalIdsVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    Obligations verification status
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.obligationsVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    Max delay days verification status
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.maxDelayDaysStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Probability of good</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {Number(data.probabilityOfGood * 100).toFixed(2)}%
                  </Text>
                </View>
              </View>{" "}
            </View>

            <View style={styles.footer_container}>
              <Text style={styles.text_footer}>
                {text.result[lang]}:{" "}
                {lang === "en"
                  ? Number(data.probabilityOfGood * 100).toFixed(2) + "%"
                  : formatNumber(
                      Number(Number(data.probabilityOfGood * 100).toFixed(2))
                    )}{" "}
                {!(
                  data.ageVerificationStatus &&
                  data.dbrVerificationStatus &&
                  data.maxDelayDaysStatus &&
                  data.nationalIdsVerificationStatus &&
                  data.obligationsVerificationStatus
                )
                  ? `Rejected (Verification)`
                  : data.scoringStatus === "Approve"
                  ? `"${text.approved[lang]}"`
                  : data.scoringStatus === "Review"
                  ? "Review"
                  : data.scoringStatus === "Reject"
                  ? "Rejected (Scoring)"
                  : !data.isBadLoan
                  ? "Approve"
                  : "Reject"}
              </Text>
              <Text style={styles.text_footer}>
                {text.threshold[lang]}:{" "}
                {lang === "en"
                  ? Number(data.threshold * 100).toFixed(2)
                  : formatNumber(
                      Number(Number(data.threshold * 100).toFixed(2))
                    )}
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}
