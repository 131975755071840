import React, { useContext, useState } from "react";
import styles from "./login-styles.module.scss";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import ImageLight from "../../assets/img/img.png";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { Input } from "../../ui-components/input/input.component.tsx";
import { encryptString } from "../../utils/decrypt-encrypt-string.ts";
import { Config } from "../../app/AppConfig.ts";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
export default function LoginPageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const navigate = useNavigate();
  const [formData, setFormData] = useState<{
    username: string;
    password: string;
  }>({
    username: "",
    password: "",
  });
  const [errorValidate, setErrorValidate] = useState<{
    username: boolean;
    password: boolean;
    errorLogin: boolean;
  }>({
    username: false,
    password: false,
    errorLogin: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthData } = useContext(AuthContext);
  const [hidden, setHidden] = useState(true);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------
  function validate() {
    const user = formData.username.trim();
    setErrorValidate((prevState) => ({
      ...prevState,
      username: !user,
    }));
    const password = formData.password.trim();
    setErrorValidate((prevState) => ({
      ...prevState,
      password: !password,
    }));
    return !!(formData.username.trim() && formData.password.trim());
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const val = validate();
    if (val) {
      setLoading(true);
      const res = await sendPostRequest("/Login/login", {
        username: formData.username,
        password: formData.password,
      });
      setLoading(false);
      if (!res) {
        return;
      }
      if (res.status === 200) {
        const resJson = JSON.stringify(await res.json());
        const encrypt = encryptString(resJson, Config.jwtSecret);
        localStorage.setItem("user", encrypt);
        setAuthData(JSON.parse(resJson));
        navigate("/app/dashboard");
        window.location.reload();
      } else {
        setErrorValidate((prevState) => ({
          ...prevState,
          errorLogin: true,
        }));
      }
    }
  }

  // ---------------------------------------------------------------------------
  return (
    <div className={styles.login_container}>
      <div className={styles.login_block}>
        <div className={styles.login_img_block}>
          <img
            aria-hidden="true"
            className={styles.login_img}
            src={ImageLight}
            alt="Office"
          />
        </div>
        <div className={styles.login_form_block}>
          <form className={styles.login_form} onSubmit={handleSubmit}>
            <h3
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              Login
            </h3>
            <div className={styles.username_block}>
              <label htmlFor="username" className={styles.username_label}>
                Username
              </label>
              <Input
                mode={"primary"}
                type="text"
                name="username"
                id="username"
                placeholder="username"
                autoFocus={true}
                className={styles.username_input}
                onChange={(e) => {
                  setErrorValidate((prevState) => ({
                    ...prevState,
                    errorLogin: false,
                  }));
                  setFormData((prevState) => ({
                    ...prevState,
                    username: e.target.value,
                  }));
                }}
              />
              <p className={styles.error_text}>
                {errorValidate.username
                  ? "The Username field is required."
                  : ""}
              </p>
            </div>

            <div className={styles.password_block}>
              <label htmlFor="password" className={styles.password_label}>
                Password
              </label>
              <Input
                mode={"primary"}
                type={hidden ? "password" : "text"}
                name="password"
                id="password"
                placeholder="password"
                className={styles.password_input}
                icon={hidden ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"}
                onIconClick={() => {
                  setHidden(!hidden);
                }}
                onChange={(e) => {
                  setErrorValidate((prevState) => ({
                    ...prevState,
                    errorLogin: false,
                  }));
                  setFormData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }));
                }}
              />

              {!errorValidate.password && !errorValidate.username ? (
                <p className={styles.error_text_login}>
                  {errorValidate.errorLogin
                    ? "Username or password is incorrect"
                    : ""}
                </p>
              ) : (
                <p className={styles.error_text}>
                  {errorValidate.password
                    ? "The Password field is required."
                    : ""}
                </p>
              )}
            </div>
            <div className={styles.button_block}>
              <ButtonComponent
                mode={"primary"}
                disabled={loading}
                style={{ height: "40px", fontSize: "18px" }}
              >
                {!loading ? "Login" : <LoadingSpinner />}
              </ButtonComponent>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
