import { useContext, useEffect, useState } from "react";
import styles from "./sidebar.styles.module.scss";
import classNames from "classnames";
import { SidebarOptions } from "./sidebar.context.tsx";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { HiOutlineClipboardList } from "react-icons/hi";
import { TbLicense } from "react-icons/tb";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
export function Sidebar(props: {
  isOpened: boolean;
  close: () => void;
  options: SidebarOptions;
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { authData } = useContext(AuthContext);
  const [role, setRole] = useState<string | null>(null);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects

  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData?.role) {
      setRole(authData?.role.toLocaleLowerCase());
    }
  }, []);
  useEffect(() => {
    if (!props.isOpened) return;
    if (!props.options.closeOnEsc) return;

    const handleKeydown = (e: any) => {
      if (e.keyCode === 27) {
        props.close();
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [props.isOpened, props.options.closeOnEsc]);

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  function onOverlayClick(event: any) {
    if (!props.options.closeOnOutsideClick) return;

    if (event.target?.id === "overlay") {
      props.close();
    }
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      id="overlay"
      className={classNames({
        [styles.overlay]: true,
        [styles.opened]: props.isOpened && lang === "en",
        [styles.closed]: !props.isOpened && lang === "en",
        [styles.opened_ar]: props.isOpened && lang === "ar",
        [styles.closed_ar]: !props.isOpened && lang === "ar",
      })}
      onClick={(event) => onOverlayClick(event)}
      style={lang === "ar" ? { direction: "rtl" } : {}}
    >
      <div
        className={classNames({
          [styles.sidebar]: true,
          [styles.opened]: props.isOpened,
          [styles.closed]: !props.isOpened,
          [styles.opened_ar]: props.isOpened && lang === "ar",
          [styles.closed_ar]: !props.isOpened && lang === "ar",
        })}
        style={
          lang === "ar"
            ? { width: `${props.options.width}em` || "30em", right: 0 }
            : { width: `${props.options.width}em` || "30em", left: 0 }
        }
      >
        <div className={styles.nav_container}>
          <h1 className={styles.nav_h1}>zypl.score | FINCA Jordan</h1>
          <ul className={styles.nav_ul}>
            <li className={styles.nav_li}>
              <NavLink
                onClick={() => {
                  props.close();
                }}
                to={"/app/dashboard"}
                className={({ isActive }) =>
                  isActive ? styles.nav_link_active : styles.nav_link
                }
              >
                <FaHome size={24} />
                <span className={styles.nav_span}>{text.home[lang]}</span>
              </NavLink>
            </li>
            <li className={styles.nav_li}>
              <NavLink
                onClick={() => {
                  props.close();
                }}
                to={"/app/prediction"}
                className={({ isActive }) =>
                  isActive ? styles.nav_link_active : styles.nav_link
                }
              >
                <HiOutlineClipboardList size={24} />
                <span className={styles.nav_span}>
                  {text.get_scoring_result[lang]}
                </span>
              </NavLink>
            </li>
            {(role === "zypl" || role === "fincamanager") && (
              <li className={styles.nav_li}>
                <NavLink
                  onClick={() => {
                    props.close();
                  }}
                  to={"/app/license"}
                  className={({ isActive }) =>
                    isActive ? styles.nav_link_active : styles.nav_link
                  }
                >
                  <TbLicense size={24} />
                  <span className={styles.nav_span}>{text.license[lang]}</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.contentContainer}>{props.options.component}</div>
      </div>
    </div>
  );
}
