import styles from "./modal-refresh.module.scss";
import React, { useContext, useState } from "react";
import { sendPutRequest } from "../../../utils/send-put-request.ts";
import { LoadingSpinner } from "../../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { RiErrorWarningLine } from "react-icons/ri";
import { Input } from "../../../ui-components/input/input.component.tsx";
import { ButtonComponent } from "../../../ui-components/button/button.component.tsx";
import { BiCheckCircle } from "react-icons/bi";
import { LanguageContext } from "../../../contexts/language-context/language.context.tsx";
import { text } from "../../../../text.tsx";
export default function ModalRefresh({
  setModalCancelActive,
}: {
  setModalCancelActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [error, setError] = useState(false);
  const [onInputChange, setInputChange] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  async function send() {
    if (!onInputChange.trim()) {
      setError(true);
      return;
    }
    setError(false);

    setLoading(true);
    const encodeURI = encodeURIComponent(onInputChange);
    const res = await sendPutRequest(
      `/License/update?encryptedLicense=${encodeURI}`
    );
    setLoading(false);
    if (!res) return;
    if (res.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setSuccess(false);
      }, 3000);
    } else {
      setModalWarning(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setModalWarning(false);
      }, 3000);
    }
  }
  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.modal_container}
      onClick={() => setModalCancelActive(false)}
    >
      {success ? (
        <div className={styles.modal_container_success}>
          <div className={styles.statusModal}>
            <BiCheckCircle size={100} />
            <p>{text.license_successfully_updated[lang]}</p>
          </div>
        </div>
      ) : modalWarning ? (
        <div
          style={{
            width: "400px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
          <p style={{ color: "orangered", textAlign: "center" }}>
            {
              text.you_have_a_issues_with_an_automatic_update_of_the_license[
                lang
              ]
            }
          </p>
        </div>
      ) : (
        <div
          className={styles.modal_block}
          onClick={(e) => e.stopPropagation()}
        >
          <h3>{text.upgrade_license[lang]}</h3>
          <label htmlFor={"1"} className={styles.label}>
            <span>{text.cipher[lang]}</span>
            <Input
              style={{ width: "95%", marginLeft: "2.5%" }}
              mode={"primary"}
              id={"1"}
              onChange={(e) => {
                setInputChange(e.target.value);
              }}
              value={onInputChange}
              onEnter={send}
            />
            <p className={styles.error_text}>
              {error && `${text.this_field_is_required[lang]}`}
            </p>
          </label>
          <div className={styles.button_container}>
            <ButtonComponent
              mode={"border"}
              className={styles.button_cancel}
              onClick={() => {
                setModalCancelActive(false);
              }}
            >
              {text.cancel[lang]}
            </ButtonComponent>
            <ButtonComponent
              mode={"primary"}
              className={styles.button_save}
              onClick={send}
            >
              {loading ? <LoadingSpinner /> : `${text.save[lang]}`}
            </ButtonComponent>
          </div>
        </div>
      )}
    </div>
  );
}
