import styles from "./change-password-styles.module.scss";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import React, { useContext, useEffect, useState } from "react";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { useNavigate } from "react-router-dom";
import { BiCheckCircle } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";
import { Input } from "../../ui-components/input/input.component.tsx";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
export default function ChangePasswordPageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { lang } = useContext(LanguageContext);
  const [newPasswordData, setNewPasswordData] = useState<{
    oldPassword: string;
    password: string;
    rePassword: string;
  }>({ oldPassword: "", password: "", rePassword: "" });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [field, setField] = useState(false);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const { authData } = useContext(AuthContext);
  const [hidden, setHidden] = useState({
    oldPassword: true,
    newPassword: true,
    reNewPassword: true,
  });
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (authData?.token) {
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status !== 200) {
          navigate("/login");
          return;
        }
      });
    } else {
      navigate("/login");
    }
  }, []);

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  async function send(e: React.FormEvent) {
    e.preventDefault();
    const val = validate();
    if (val) {
      setErrorText("");
      setLoading(true);
      const res = await sendPostRequest("/Login/updatePassword", {
        oldPassword: newPasswordData.oldPassword,
        newPassword: newPasswordData.password,
      });
      if (!res) {
        return;
      }
      if (res.status === 400) {
        setField(true);
        setTimeout(() => {
          setField(false);
        }, 3000);
        setLoading(false);
      }
      if (res.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        return;
      }
      setLoading(false);
    } else {
      return;
    }
  }

  function validate() {
    if (newPasswordData.password.length < 6) {
      setErrorText("Password must be at least 6 characters long");
      return false;
    }
    if (newPasswordData.password === newPasswordData.oldPassword) {
      setErrorText("Old password cannot be the same as the new password");
      return false;
    }
    if (newPasswordData.password !== newPasswordData.rePassword) {
      setErrorText("Passwords do not match");
    }
    return !!(
      newPasswordData.oldPassword.trim() &&
      newPasswordData.password.trim() &&
      newPasswordData.rePassword.trim() &&
      newPasswordData.password === newPasswordData.rePassword
    );
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.change_password_container}
      style={lang === "ar" ? { direction: "rtl" } : {}}
    >
      {success && (
        <div className={styles.modal_container}>
          <div className={styles.statusModal}>
            <BiCheckCircle size={100} />
            <p>{text.password_changed_successfully[lang]}</p>
          </div>
        </div>
      )}
      {field && (
        <div className={styles.modal_container}>
          <div className={styles.statusModal}>
            <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
            <p style={{ color: "orangered" }}>
              {text.password_doesnt_match[lang]}
            </p>
          </div>
        </div>
      )}
      <div className={styles.header_name}>
        <h2 className={styles.header_name_text}>
          {text.change_password[lang]}
        </h2>
      </div>
      <form className={styles.container_form} onSubmit={send}>
        <div className={styles.container_input}>
          <label htmlFor={"1"} className={styles.label}>
            {text.old_password[lang]}
          </label>
          <Input
            required
            mode={"primary"}
            className={styles.input}
            id={"1"}
            type={hidden.oldPassword ? "password" : "text"}
            onChange={(e) => {
              setErrorText("");
              setNewPasswordData((prevState) => ({
                ...prevState,
                oldPassword: e.target.value,
              }));
            }}
            value={newPasswordData.oldPassword}
            icon={
              hidden.oldPassword
                ? "fa-regular fa-eye"
                : "fa-regular fa-eye-slash"
            }
            onIconClick={() => {
              setHidden({ ...hidden, oldPassword: !hidden.oldPassword });
            }}
          />
        </div>
        <div className={styles.container_input}>
          <label htmlFor={"2"} className={styles.label}>
            {text.new_password[lang]}
          </label>
          <Input
            required
            mode={"primary"}
            className={styles.input}
            id={"2"}
            type={hidden.newPassword ? "password" : "text"}
            onChange={(e) => {
              setErrorText("");
              setNewPasswordData((prevState) => ({
                ...prevState,
                password: e.target.value,
              }));
            }}
            value={newPasswordData.password}
            icon={
              hidden.newPassword
                ? "fa-regular fa-eye"
                : "fa-regular fa-eye-slash"
            }
            onIconClick={() => {
              setHidden({ ...hidden, newPassword: !hidden.newPassword });
            }}
          />
        </div>
        <div className={styles.container_input}>
          <label htmlFor={"3"} className={styles.label}>
            {text.repeat_new_password[lang]}
          </label>
          <Input
            required
            mode={"primary"}
            className={styles.input}
            id={"3"}
            type={hidden.reNewPassword ? "password" : "text"}
            onChange={(e) => {
              setErrorText("");
              setNewPasswordData((prevState) => ({
                ...prevState,
                rePassword: e.target.value,
              }));
            }}
            value={newPasswordData.rePassword}
            icon={
              hidden.reNewPassword
                ? "fa-regular fa-eye"
                : "fa-regular fa-eye-slash"
            }
            onIconClick={() => {
              setHidden({ ...hidden, reNewPassword: !hidden.reNewPassword });
            }}
          />
          <p
            style={{
              width: "100%",
              height: "20px",
              color: "red",
              fontSize: "12px",
            }}
          >
            {errorText}
          </p>
        </div>
        <div className={styles.container_button}>
          <ButtonComponent mode={"primary"} className={styles.button}>
            {loading ? <LoadingSpinner /> : `${text.change[lang]}`}
          </ButtonComponent>
        </div>
      </form>
    </div>
  );
}
