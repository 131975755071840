import { useLocation } from "react-router-dom";
import { TbLicense } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { HiOutlineClipboardList } from "react-icons/hi";
import styles from "./panel.styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { text } from "../../../text.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";

export default function PanelComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const location = useLocation();
  const [find, setFind] = useState<number>(-1);
  const { authData } = useContext(AuthContext);
  const [role, setRole] = useState<string | null>(null);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects

  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData?.role) {
      setRole(authData?.role.toLocaleLowerCase());
    }
  }, []);
  useEffect(() => {
    setFind(location.pathname.indexOf("login"));
  }, [location.pathname]);
  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.nav_container}
      style={
        find !== -1
          ? { display: "none" }
          : lang === "ar"
          ? { direction: "rtl" }
          : {}
      }
    >
      <h1 className={styles.nav_h1}>zypl.score | FINCA Jordan</h1>
      <ul className={styles.nav_ul}>
        <li className={styles.nav_li}>
          <NavLink
            to={"/app/dashboard"}
            className={({ isActive }) =>
              isActive ? styles.nav_link_active : styles.nav_link
            }
          >
            <FaHome size={24} />
            <span className={styles.nav_span}>{text.home[lang]}</span>
          </NavLink>
        </li>
        <li className={styles.nav_li}>
          <NavLink
            to={"/app/prediction"}
            className={({ isActive }) =>
              isActive ? styles.nav_link_active : styles.nav_link
            }
          >
            <HiOutlineClipboardList size={24} />
            <span className={styles.nav_span}>
              {text.get_scoring_result[lang]}
            </span>
          </NavLink>
        </li>
        {(role === "zypl" || role === "fincamanager") && (
          <li className={styles.nav_li}>
            <NavLink
              to={"/app/license"}
              className={({ isActive }) =>
                isActive ? styles.nav_link_active : styles.nav_link
              }
            >
              <TbLicense size={24} />
              <span className={styles.nav_span}>{text.license[lang]}</span>
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}
