import React, { useContext, useEffect, useState } from "react";
import styles from "./sign-up-styles.module.scss";
import Select from "react-select";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { BiCheckCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { RiErrorWarningLine } from "react-icons/ri";
import { Input } from "../../ui-components/input/input.component.tsx";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
export default function SignUpPageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { lang } = useContext(LanguageContext);
  const options1 = [
    { value: "FincaLoanExpert", label: `${text.loan_expert[lang]}` },
    { value: "FincaManager", label: `${text.finca_manager[lang]}` },
  ];
  const options2 = [
    { value: "FincaLoanExpert", label: `${text.loan_expert[lang]}` },
    { value: "FincaManager", label: `${text.finca_manager[lang]}` },
    { value: "zypl", label: "Zypl" },
  ];
  const [formData, setFormData] = useState<{
    name: string;
    surname: string;
    username: string;
    password: string;
    rePassword: string;
    role: string;
  }>({
    name: "",
    surname: "",
    username: "",
    password: "",
    rePassword: "",
    role: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [modalRegistered, setModalRegistered] = useState(false);
  const navigate = useNavigate();
  const [userAlreadyExist, setUserAlreadyExist] = useState(false);
  const { authData } = useContext(AuthContext);
  const [role, setRole] = useState<string | null>(null);
  const [error, setError] = useState("");

  const [hidden, setHidden] = useState({ password: true, rePassword: true });
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData?.role) {
      setRole(authData?.role.toLocaleLowerCase());
    }
  }, []);

  useEffect(() => {
    if (authData?.token) {
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) return;
        if (res.status !== 200) {
          navigate("/login");
        }
      });
    } else {
      navigate("/login");
    }
  }, [loading]);
  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (formData.password.length < 6) {
      setError(
        lang === "en"
          ? "Password must be at least 6 characters long"
          : "كلمة المرور يجب أن تكون على الأقل 6 حرفا"
      );
      return;
    }

    if (formData.password !== formData.rePassword) {
      setError(
        lang === "en" ? "Passwords do not match" : "كلمة المرور غير مطابقة"
      );
      return;
    } else {
      setError("");
    }
    setLoading(true);
    const res = await sendPostRequest("/Login/register", {
      firstName: formData.name,
      lastName: formData.surname,
      username: formData.username,
      password: formData.password,
      role:
        formData.role === "Кредитный эксперт"
          ? "FincaLoanExpert"
          : formData.role,
    });
    setLoading(false);

    if (!res) return;

    if (res.status === 200) {
      setModalRegistered(true);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        username: "",
        password: "",
        rePassword: "",
        role: "",
        surname: "",
      }));
    }

    if (res.status === 400) {
      setModalRegistered(true);
      setUserAlreadyExist(true);
    }

    setTimeout(() => {
      setModalRegistered(false);
      setUserAlreadyExist(false);
    }, 3000);
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.reg_container}
      style={lang === "ar" ? { direction: "rtl" } : {}}
    >
      {modalRegistered && (
        <div className={styles.modal_registered}>
          <div className={styles.modal_block}>
            <p className={styles.modal_chak}>
              {!userAlreadyExist ? (
                <BiCheckCircle size={100} />
              ) : (
                <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
              )}
            </p>
            <p
              className={styles.modal_text}
              style={
                userAlreadyExist ? { color: "orangered" } : { display: "flex" }
              }
            >
              {userAlreadyExist
                ? `${text.user_already_exist[lang]}`
                : `${text.user_successfully_registered[lang]}`}
            </p>
          </div>
        </div>
      )}
      <div className={styles.reg_block}>
        <form className={styles.reg_form} onSubmit={handleSubmit}>
          <h2 className={styles.reg_h2}>{text.register_user[lang]}</h2>
          <div className={styles.reg_div}>
            <label className={styles.reg_label} htmlFor={"name"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "200px",
                }}
              >
                <span className={styles.reg_span}>{text.name[lang]}</span>
              </div>
              <Input
                mode={"primary"}
                className={styles.reg_input}
                type={"text"}
                id={"name"}
                placeholder={lang === "en" ? "Jack" : "محمد"}
                value={formData.name}
                onChange={(e) => {
                  const regex = /^[a-zA-Z]*$/;
                  const newInputValue = e.target.value;
                  if (newInputValue === "" || regex.test(newInputValue)) {
                    setFormData((prevState) => ({
                      ...prevState,
                      name: newInputValue,
                    }));
                  }
                }}
                required
              />
            </label>
            <label className={styles.reg_label} htmlFor={"surname"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "200px",
                }}
              >
                <span className={styles.reg_span}>{text.surname[lang]}</span>
              </div>
              <Input
                mode={"primary"}
                type={"text"}
                id={"surname"}
                placeholder={lang === "en" ? "Milner" : "علي"}
                className={styles.reg_input}
                value={formData.surname}
                onChange={(e) => {
                  const regex = /^[a-zA-Z]*$/;
                  const newInputValue = e.target.value;
                  if (newInputValue === "" || regex.test(newInputValue)) {
                    setFormData((prevState) => ({
                      ...prevState,
                      surname: newInputValue,
                    }));
                  }
                }}
                required
              />
            </label>
          </div>
          <div className={styles.reg_div}>
            <label className={styles.reg_label} htmlFor={"username"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "200px",
                }}
              >
                <span className={styles.reg_span}>{text.username[lang]}</span>
              </div>
              <Input
                mode={"primary"}
                type={"text"}
                id={"username"}
                placeholder={lang === "en" ? "ivanexpert" : "muhammad"}
                className={styles.reg_input}
                value={formData.username}
                onChange={(e) => {
                  const regex = /^[a-zA-Z0-9_]*$/;
                  const newInputValue = e.target.value;
                  if (newInputValue === "" || regex.test(newInputValue)) {
                    setFormData((prevState) => ({
                      ...prevState,
                      username: newInputValue,
                    }));
                  }
                }}
                required
              />
            </label>
            <div className={styles.reg_div_select}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "200px",
                }}
              >
                <span className={styles.reg_span}>{text.role[lang]}</span>
              </div>
              <Select
                options={role !== "zypl" ? options1 : options2}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    text: "white",
                    primary25: "white",
                    primary: "#8b5cf6",
                  },
                })}
                placeholder={`${text.choose[lang]}`}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    role: e!.value,
                  }));
                }}
                required
              />
            </div>
          </div>
          <div className={styles.reg_div}>
            <label className={styles.reg_label} htmlFor={"password"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "200px",
                }}
              >
                <span className={styles.reg_span}>{text.password[lang]}</span>
              </div>

              <Input
                mode={"primary"}
                type={hidden.password ? "password" : "text"}
                id={"password"}
                placeholder={`${text.password[lang]}`}
                className={styles.reg_input}
                value={formData.password}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    password: e.target.value,
                  }));
                }}
                icon={
                  hidden.password
                    ? "fa-regular fa-eye"
                    : "fa-regular fa-eye-slash"
                }
                onIconClick={() => {
                  setHidden({ ...hidden, password: !hidden.password });
                }}
                required
              />
            </label>

            <label className={styles.reg_label} htmlFor={"rePassword"}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "200px",
                }}
              >
                <span className={styles.reg_span}>
                  {text.repeat_password[lang]}
                </span>
              </div>
              <Input
                mode={"primary"}
                type={hidden.rePassword ? "password" : "text"}
                id={"rePassword"}
                placeholder={`${text.repeat_password[lang]}`}
                className={styles.reg_input}
                value={formData.rePassword}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    rePassword: e.target.value,
                  }));
                }}
                icon={
                  hidden.rePassword
                    ? "fa-regular fa-eye"
                    : "fa-regular fa-eye-slash"
                }
                onIconClick={() => {
                  setHidden({ ...hidden, rePassword: !hidden.rePassword });
                }}
                required
              />
            </label>
          </div>
          <div>
            <p className={styles.reg_p}>{error}</p>
          </div>
          <div className={styles.reg_button_block}>
            <ButtonComponent
              mode={"primary"}
              className={styles.reg_button}
              disabled={loading}
            >
              {!loading ? `${text.create[lang]}` : <LoadingSpinner />}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
}
