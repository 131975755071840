export default function textWrap(text: string, number: number) {
  let newText = "";
  if (text.length > number) {
    for (let i = 0; i < number; i++) {
      newText += text[i];
    }
    newText += "...";
  } else {
    newText = text;
  }
  return newText;
}
