import { useEffect, useState } from "react";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import downloadExcel from "../../utils/download-xls.ts";
import styles from "./dashboard-styles.module.scss";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { formatDate } from "../../utils/formatDate.ts";
import { text } from "../../../text.tsx";
import { formatNumber } from "../../utils/format-number.ts";
import ModalPdfGroupLoanComponent from "../../ui-components/modal-pdf/modal-pdf-group-loan.component.tsx";

type TypeApplication = {
  maxDelayDaysStatus: boolean;
  ageVerificationStatus: boolean;
  obligationsVerificationStatus: boolean;
  dbrVerificationStatus: boolean;
  nationalIdsVerificationStatus: boolean;
  ocrStatus: boolean;
  systemUsername: string;
  loanScoreRequestId: string;
  requestTime: string;
  probabilityOfGood: number;
  probabilityOfBad: number;
  modelName: string;
  threshold: number;
  isBadLoan: boolean;
  scoringStatus: "Approve" | "Reject" | "Review";
  id: number;
  interestRate: number;
  inflationRate: number;
  loanDuration: number;
  branch: string;
  loanAmount: number;
  monthlyPayment: number;
  singleLoanResponses: {
    input: {
      familyStatus: string;
      totalIncome: number;
      gender: boolean;
      age: number;
      dependants: number;
      creditScore: number;
      numberOfLoans: number;
      numberOfActiveLoans: number;
      totalMonthlyPayment: number;
      delayPayments: number;
      kyC_National_Id: string;
      criF_National_Id: string;
      dbr: number;
      obligations: number;
      ocR_status: number;
      maxDelayDays: number;
    };
    dbr_value: number | null;
    ageVerificationStatus: boolean;
    obligationsVerificationStatus: boolean;
    dbrVerificationStatus: boolean;
    nationalIdsVerificationStatus: boolean;
    maxDelayDaysStatus: boolean;
    ocrStatus: boolean;
    systemUsername: string;
    loanScoreRequestId: string;
    requestTime: string;
    probabilityOfGood: number;
    probabilityOfBad: number;
    modelName: string;
    threshold: number;
    isBadLoan: boolean;
  };
};

export default function TableComponent({
  loading,
  historyGroupLoan,
  loadingTable,
  lang,
}: {
  loading: boolean;
  historyGroupLoan: any;
  loadingTable: boolean;
  lang: "en" | "ar";
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [modalState, setModalState] = useState(false);
  const [selectedItemTable, setSelectedItemTable] =
    useState<TypeApplication | null>(null);
  const [per, setPer] = useState<10 | 20 | 50 | 100>(10);
  const [of, setOf] = useState<{ first: number; second: number }>({
    first: 1,
    second: per,
  });
  const [historyFilter, setHistoryFilter] = useState<TypeApplication[]>([]);
  const [history, setHistory] = useState<TypeApplication[]>([]);

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    const newArray: TypeApplication[] = [];
    historyGroupLoan.forEach((e: any) => {
      e.singleLoanResponses.forEach((element: any) => {
        const newObject = {
          ageVerificationStatus: e.ageVerificationStatus,
          obligationsVerificationStatus: e.obligationsVerificationStatus,
          dbrVerificationStatus: e.dbrVerificationStatus,
          nationalIdsVerificationStatus: e.nationalIdsVerificationStatus,
          maxDelayDaysStatus: e.maxDelayDaysStatus,
          ocrStatus: e.ocrStatus,
          systemUsername: e.systemUsername,
          loanScoreRequestId: e.loanScoreRequestId,
          requestTime: e.requestTime,
          probabilityOfGood: e.probabilityOfGood,
          probabilityOfBad: e.probabilityOfBad,
          modelName: e.modelName,
          threshold: e.threshold,
          isBadLoan: e.isBadLoan,
          scoringStatus: e.scoringStatus,
          id: e.input.id,
          interestRate: e.input.interestRate,
          inflationRate: e.input.inflationRate,
          loanDuration: e.input.loanDuration,
          branch: e.input.branch,
          loanAmount: element.input.loanAmount,
          monthlyPayment: e.input.monthlyPayment,
          singleLoanResponses: element,
        };
        newArray.push(newObject);
      });
      setHistory(newArray);
    });
  }, [historyGroupLoan]);

  useEffect(() => {
    if (history.length === 0) {
      setOf({
        first: 0,
        second: 0,
      });
    } else {
      setOf({ first: 1, second: per });
    }
  }, [history, per]);

  useEffect(() => {
    if (of.second !== 0 && of.second !== 0) {
      const newArray: TypeApplication[] = [];
      for (let i = of.first - 1; i < of.second && i < history.length; i++) {
        newArray.push(history[i]);
      }
      setHistoryFilter(newArray);
    }
  }, [historyGroupLoan, of, per]);

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.dashboard_container}
      style={lang === "ar" ? { direction: "rtl" } : {}}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "90%",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ width: "100%" }} className={styles.dashboard_block}>
          {selectedItemTable && (
            <ModalPdfGroupLoanComponent
              modal={modalState}
              data={selectedItemTable}
              setModal={setModalState}
            />
          )}
          <h2 style={{ marginTop: 50 }} className={styles.header_name_text}>
            Group Loan
          </h2>

          <div className={styles.table_container}>
            <ButtonComponent
              mode={"primary"}
              className={styles.table_button}
              onClick={() => {
                const jsonForExcel: any = {};
                for (let i = 0; i < historyGroupLoan.length; i++) {
                  const jsonSingleLoanResponseExcel: any = {};
                  const jsonsInput: any = {};
                  for (
                    let j = 0;
                    j < historyGroupLoan[i].singleLoanResponses.length;
                    j++
                  ) {
                    jsonSingleLoanResponseExcel[`${j + 1}`] = {
                      "[Single Loan Responses] Loan Score Request Id":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .loanScoreRequestId,
                      "[Single Loan Responses] Request Time":
                        historyGroupLoan[i].singleLoanResponses[j].requestTime,
                      "[Single Loan Responses] System Username":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .systemUsername,
                      "[Single Loan Responses] Age Verification Status":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .ageVerificationStatus,
                      "[Single Loan Responses] Obligations Verification Status":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .obligationsVerificationStatus,
                      "[Single Loan Responses] DBR Verification Status":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .dbrVerificationStatus,
                      "[Single Loan Responses] National Ids Verification Status":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .nationalIdsVerificationStatus,
                      "[Single Loan Responses] OCR Status":
                        historyGroupLoan[i].singleLoanResponses[j].ocrStatus,
                      "[Single Loan Responses] Loan Amount":
                        historyGroupLoan[i].singleLoanResponses[j].input
                          .loanAmount,
                      "[Single Loan Responses] Interest Rate":
                        historyGroupLoan[i].singleLoanResponses[j].input
                          .interestRateMonthly * 100,
                      "[Single Loan Responses] Probability Of Good":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .probabilityOfGood,
                      "[Single Loan Responses] Probability of bad":
                        historyGroupLoan[i].singleLoanResponses[j]
                          .probabilityOfBad,
                      "[Single Loan Responses] Model Name":
                        historyGroupLoan[i].singleLoanResponses[j].modelName,
                      "[Single Loan Responses] Threshold":
                        historyGroupLoan[i].singleLoanResponses[j].threshold *
                        100,
                      "[Single Loan Responses] Is Bad Loan":
                        !!historyGroupLoan[i].singleLoanResponses[j].isBadLoan,
                      "[Single Loan Responses] Status":
                        !!historyGroupLoan[i].singleLoanResponses[j]
                          .scoringStatus,
                    };
                  }

                  let resultSingleLoanResponsesForExcel = {};

                  for (const key in jsonSingleLoanResponseExcel) {
                    const prefixedObject = {};
                    for (const innerKey in jsonSingleLoanResponseExcel[key]) {
                      (prefixedObject as any)[`${key}${innerKey}`] =
                        jsonSingleLoanResponseExcel[key][innerKey];
                    }
                    resultSingleLoanResponsesForExcel = {
                      ...resultSingleLoanResponsesForExcel,
                      ...prefixedObject,
                    };
                  }

                  for (
                    let j = 0;
                    j < historyGroupLoan[i].input.loanModels.length;
                    j++
                  ) {
                    jsonsInput[`${j + 1}`] = {
                      "[Input] Family Status":
                        historyGroupLoan[i].input.loanModels[j].familyStatus,
                      "[Input] Total Income":
                        historyGroupLoan[i].input.loanModels[j].totalIncome,
                      "[Input] Gender":
                        historyGroupLoan[i].input.loanModels[j].gender,
                      "[Input] Age":
                        historyGroupLoan[i].input.loanModels[j].age,
                      "[Input] Dependants":
                        historyGroupLoan[i].input.loanModels[j].dependants,
                      "[Input] Credit Score":
                        historyGroupLoan[i].input.loanModels[j].creditScore,
                      "[Input] Max delayed days":
                        historyGroupLoan[i].input.loanModels[j].maxDelayDays,
                      "[Input] Number Of Loans":
                        historyGroupLoan[i].input.loanModels[j].numberOfLoans,
                      "[Input] Number Of Active Loans":
                        historyGroupLoan[i].input.loanModels[j]
                          .numberOfActiveLoans,
                      "[Input] Total Monthly Payment":
                        historyGroupLoan[i].input.loanModels[j]
                          .totalMonthlyPayment,
                      "[Input] Delay Payments":
                        historyGroupLoan[i].input.loanModels[j].delayPayments,
                      "[Input] KYC National Id":
                        historyGroupLoan[i].input.loanModels[j].kyC_National_Id,
                      "[Input] CRIF National Id":
                        historyGroupLoan[i].input.loanModels[j]
                          .criF_National_Id,
                      "[Input] DBR": Number(
                        historyGroupLoan[i]?.dbr_value * 100
                      ).toFixed(2),
                      "[Input] Obligations":
                        historyGroupLoan[i].input.loanModels[j].obligations,
                      "[Input] OCR Status":
                        historyGroupLoan[i].input.loanModels[j].ocR_status,
                    };
                  }

                  let reconsultInput = {};

                  for (const key in jsonsInput) {
                    const prefixedObject = {};
                    for (const innerKey in jsonsInput[key]) {
                      (prefixedObject as any)[`${key}${innerKey}`] =
                        jsonsInput[key][innerKey];
                    }
                    reconsultInput = {
                      ...reconsultInput,
                      ...prefixedObject,
                    };
                  }

                  jsonForExcel[`${i + 1}`] = {
                    ID: historyGroupLoan[i].input.id,
                    ...resultSingleLoanResponsesForExcel,
                    ...reconsultInput,
                    "Loan Score Request Id":
                      historyGroupLoan[i].loanScoreRequestId,
                    "Request Time": historyGroupLoan[i].requestTime,
                    "System Username": historyGroupLoan[i].systemUsername,
                    "Age Verification Status":
                      historyGroupLoan[i].ageVerificationStatus,
                    "Obligations Verification Status":
                      historyGroupLoan[i].obligationsVerificationStatus,
                    "DBR Verification Status":
                      historyGroupLoan[i].dbrVerificationStatus,
                    "National Ids Verification Status":
                      historyGroupLoan[i].nationalIdsVerificationStatus,
                    "OCR Status": historyGroupLoan[i].ocrStatus,
                    "Probability of good":
                      historyGroupLoan[i].probabilityOfGood,
                    "Model Name": historyGroupLoan[i].modelName,
                    Threshold: historyGroupLoan[i].threshold * 100,
                    "Is Bad Loan": !!historyGroupLoan[i].isBadLoan,
                    Status: !!historyGroupLoan[i].scoringStatus,
                  };
                }
                const sheetData = Object.values(jsonForExcel);
                if (historyGroupLoan.length === 0) return;
                downloadExcel(sheetData);
              }}
            >
              {text.download_excel[lang]}
            </ButtonComponent>
            <div className={styles.table}>
              <div className={styles.footer_table}>
                <div className={styles.footer_table_first_column}>
                  <span className={styles.span_footer_table}>
                    {text.rows_per_page[lang]}:
                  </span>
                  {lang === "en" ? (
                    <select
                      style={{ backgroundColor: "white", color: "black" }}
                      onChange={(e) => {
                        setPer(Number(e.target.value) as any);
                      }}
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  ) : (
                    <select
                      style={{ backgroundColor: "white", color: "black" }}
                      onChange={(e) => {
                        setPer(Number(e.target.value) as any);
                      }}
                    >
                      <option value={"10"}>{formatNumber(10)}</option>
                      <option value={"20"}>{formatNumber(20)}</option>
                      <option value={"50"}>{formatNumber(50)}</option>
                      <option value={"100"}>{formatNumber(100)}</option>
                    </select>
                  )}
                </div>

                <div className={styles.footer_table_second_column}>
                  <span className={styles.span_footer_table}>
                    {lang === "en"
                      ? of.first +
                        "-" +
                        Math.min(of.second, history.length) +
                        " of " +
                        history.length
                      : formatNumber(of.first) +
                        "-" +
                        formatNumber(Math.min(of.second, history.length)) +
                        " من " +
                        formatNumber(history.length)}
                  </span>
                  <ButtonComponent
                    mode={"simple"}
                    className={styles.button_footer}
                    disabled={history.length === 0 || of.first === 1}
                    onClick={() => {
                      setOf({
                        first: of.first - per,
                        second: of.second - per,
                      });
                    }}
                  >
                    {"<"}
                  </ButtonComponent>
                  <ButtonComponent
                    mode={"simple"}
                    className={styles.button_footer}
                    disabled={
                      history.length === 0 || of.second >= history.length
                    }
                    onClick={() => {
                      setOf({
                        first: of.first + per,
                        second: of.second + per,
                      });
                    }}
                  >
                    {">"}
                  </ButtonComponent>
                </div>
              </div>
              <div className={styles.block_table}>
                <div className={styles.header_table_result}>
                  <span className={styles.table_th}>ID</span>
                  <span className={styles.table_th}>{text.date[lang]}</span>
                  <span className={styles.table_th}>{text.result[lang]}</span>
                  <span className={styles.table_th}>Probability of good</span>
                  <span className={styles.table_th}>
                    {text.loan_duration[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.interest_rate[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.amount_of_loan[lang]}
                  </span>
                  <span className={styles.table_th}>{text.branch[lang]}</span>
                  <span className={styles.table_th}>
                    {text.national_id[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.total_income[lang]}
                  </span>
                  <span className={styles.table_th}>{text.age[lang]}</span>
                  <span className={styles.table_th}>
                    {text.family_status[lang]}
                  </span>
                  <span className={styles.table_th}>{text.gender[lang]}</span>
                  <span className={styles.table_th}>
                    {text.credit_score[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.dependants[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.number_of_loans[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.number_of_active_loans[lang]}
                  </span>

                  <span className={styles.table_th}>
                    {text.total_monthly_payment[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.delay_payments[lang]}
                  </span>

                  <span className={styles.table_th}>Number of obligations</span>
                  <span className={styles.table_th}>DBR</span>
                  <span className={styles.table_th}>Max delayed days</span>
                  <span className={styles.table_th}>
                    {text.threshold[lang]}
                  </span>
                  <span className={styles.table_th_last}>
                    {text.actions[lang]}
                  </span>
                </div>
                <div className={styles.table_result_items_container}>
                  {historyFilter.length > 0 ? (
                    historyFilter.map((e, i: number) => (
                      <div
                        key={i}
                        className={styles.table_result_item_container}
                      >
                        <div className={styles.td}>
                          <p>{e.id}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{formatDate(e.requestTime, lang)}</p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {e.ageVerificationStatus &&
                            e.dbrVerificationStatus &&
                            e.maxDelayDaysStatus &&
                            e.obligationsVerificationStatus &&
                            e.nationalIdsVerificationStatus
                              ? e.scoringStatus === "Approve"
                                ? `${text.approved[lang]}`
                                : e.scoringStatus === "Review"
                                ? `Review`
                                : e.scoringStatus === "Reject"
                                ? `${text.refusalScoring[lang]}`
                                : e.isBadLoan
                                ? "Reject"
                                : "Approve"
                              : `${text.refusalVerification[lang]}`}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p
                            className={styles.span_pr}
                            style={
                              e.ageVerificationStatus &&
                              e.maxDelayDaysStatus &&
                              e.dbrVerificationStatus &&
                              e.obligationsVerificationStatus &&
                              e.nationalIdsVerificationStatus &&
                              e.scoringStatus === "Approve"
                                ? {
                                    backgroundColor: "#48bb78",
                                  }
                                : e.scoringStatus === "Review"
                                ? {
                                    backgroundColor: "orange",
                                  }
                                : e.scoringStatus === "Reject"
                                ? {
                                    backgroundColor: "red",
                                  }
                                : !e.isBadLoan
                                ? {
                                    backgroundColor: "#48bb78",
                                  }
                                : {
                                    backgroundColor: "red",
                                  }
                            }
                          >
                            {e.ageVerificationStatus &&
                            e.dbrVerificationStatus &&
                            e.maxDelayDaysStatus &&
                            e.nationalIdsVerificationStatus &&
                            e.obligationsVerificationStatus ? (
                              lang === "en" ? (
                                Number(e.probabilityOfGood * 100).toFixed(2) +
                                "%"
                              ) : (
                                formatNumber(
                                  Number(
                                    Number(e.probabilityOfGood * 100).toFixed(2)
                                  )
                                ) + "%"
                              )
                            ) : (
                              <>--</>
                            )}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanDuration
                              : formatNumber(e.loanDuration)}
                          </p>
                        </div>

                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.interestRate
                              : formatNumber(e.interestRate)}
                            %
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanAmount
                              : formatNumber(e.loanAmount)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>{(text as any)[e.branch][lang]}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.kyC_National_Id}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.totalIncome}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.age}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.familyStatus}</p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {e.singleLoanResponses.input.gender
                              ? `${text.male[lang]}`
                              : `${text.female[lang]}`}
                          </p>
                        </div>

                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.creditScore}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.dependants}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.numberOfLoans}</p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {e.singleLoanResponses.input.numberOfActiveLoans}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {e.singleLoanResponses.input.totalMonthlyPayment}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.delayPayments}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.obligations}</p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {Number(e.singleLoanResponses.dbr_value).toFixed(2)}
                            %
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.input.maxDelayDays}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{e.singleLoanResponses.threshold * 100}</p>
                        </div>
                        <div className={styles.td}>
                          <ButtonComponent
                            mode={"primary"}
                            className={styles.button_download}
                            onClick={() => {
                              setModalState(!modalState);
                              const find = historyGroupLoan.find(
                                (groupLoan: any) => groupLoan.input.id === e.id
                              );
                              setSelectedItemTable(find);
                            }}
                          >
                            {text.download[lang]}
                          </ButtonComponent>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.table_no_result}>
                      <div style={{ color: "black" }}>
                        {loadingTable ? (
                          <LoadingSpinner />
                        ) : (
                          `${text.no_rows[lang]}`
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
