import React, { useState } from "react";
import { UserType } from "../../types/types.ts";
import { AuthContext } from "./auth.context.tsx";
import { decryptString } from "../../utils/decrypt-encrypt-string.ts";
import { Config } from "../../app/AppConfig.ts";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const encryptDataLocalStorage = localStorage.getItem("user");
  const decrypt = encryptDataLocalStorage
    ? JSON.parse(decryptString(encryptDataLocalStorage, Config.jwtSecret))
    : null;
  const [authData, setAuthData] = useState<UserType | null>(decrypt);
  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
}
