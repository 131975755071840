import { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { sendGetRequest } from "../../utils/send-get-request.ts";
import ModalPdfComponent from "../../ui-components/modal-pdf/modal-pdf.component.tsx";
import downloadExcel from "../../utils/download-xls.ts";
import styles from "./dashboard-styles.module.scss";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/formatDate.ts";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { HistoryType } from "../../types/types.ts";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
import { formatNumber } from "../../utils/format-number.ts";
import TableComponent from "./table.component.tsx";
import { Input } from "../../ui-components/input/input.component.tsx";

export default function DashboardPageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { lang } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().subtract(10, "day"));
  const [toDate, setToDate] = useState(dayjs());
  const [history, setHistory] = useState<HistoryType[]>([]);
  const [historyGroupLoan, setHistoryGroupLoan] = useState<any>([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [selectedItemTable, setSelectedItemTable] =
    useState<HistoryType | null>(null);
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  const [per, setPer] = useState<10 | 20 | 50 | 100>(10);
  const [of, setOf] = useState<{ first: number; second: number }>({
    first: 1,
    second: per,
  });
  const [historyFilter, setHistoryFilter] = useState<HistoryType[]>([]);
  const [KYCNationalId, setKYCNationalId] = useState("");

  const getHistory = useCallback(
    async (kyc_national_id: string) => {
      try {
        setHistory([]);
        setHistoryFilter([]);
        setLoadingTable(true);

        const fromDateForRequest = moment(fromDate.toDate()).format(
          "YYYY/MM/DD"
        );
        const toDateForRequest = moment(toDate.toDate()).format("YYYY/MM/DD");
        const role = authData?.role.toLocaleLowerCase();

        let link, linkGroupLoan;

        if (role === "zypl" || role === "fincamanager") {
          link = `/Score/History?fromDate=${fromDateForRequest}&toDate=${toDateForRequest}`;
          linkGroupLoan = `/BulkScore/HistoryForBulkLoans?fromDate=${fromDateForRequest}&toDate=${toDateForRequest}`;
        } else {
          link = `/Score/UserHistory?fromDate=${fromDateForRequest}&toDate=${toDateForRequest}`;
          linkGroupLoan = `/BulkScore/UserHistoryForBulkLoans?fromDate=${fromDateForRequest}&toDate=${toDateForRequest}`;
        }

        if (kyc_national_id) {
          link += `&KYC_National_Id=${kyc_national_id}`;
          linkGroupLoan += `&KYC_National_Id=${kyc_national_id}`;
        }

        const [res, resHistoryGroupLoan] = await Promise.all([
          sendGetRequest(link),
          sendGetRequest(linkGroupLoan),
        ]);

        await sleep(2000);
        setLoadingTable(false);

        if (!res || !resHistoryGroupLoan) return;

        const json = await res.json();
        const jsonHistoryGroupLoan = await resHistoryGroupLoan.json();

        setHistory(role === "zypl" || role === "fincamanager" ? json : json);
        setHistoryGroupLoan(
          role === "zypl" || role === "fincamanager"
            ? jsonHistoryGroupLoan
            : jsonHistoryGroupLoan
        );
      } catch (error) {
        console.error("Error fetching history:", error);
        // Handle error: e.g., display an error message
      }
    },
    [fromDate, toDate, authData]
  );

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (history.length === 0) {
      setOf({
        first: 0,
        second: 0,
      });
    } else {
      setOf({ first: 1, second: per });
    }
  }, [history, per]);

  useEffect(() => {
    if (of.second !== 0 && of.second !== 0) {
      const newArray: HistoryType[] = [];
      for (let i = of.first - 1; i < of.second && i < history.length; i++) {
        newArray.push(history[i]);
      }
      setHistoryFilter(newArray);
    }
  }, [history, of, per]);

  useEffect(() => {
    setHistoryFilter(history);
    getHistory(KYCNationalId);
  }, []);

  useEffect(() => {
    if (authData && authData.token) {
      setLoading(true);
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status !== 200) {
          navigate("/login");
          return;
        }
      });
    } else {
      navigate("/login");
    }
    setLoading(false);
  }, []);
  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  async function sleep(ms: number) {
    ms = Math.max(0, ms);
    // ms += (Math.random() - 0.5) * ms / 10;
    return new Promise<void>((r) => setTimeout(() => r(), ms));
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.dashboard_container}
      style={lang === "ar" ? { direction: "rtl" } : {}}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "90%",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div className={styles.dashboard_block}>
          {selectedItemTable && (
            <ModalPdfComponent
              modal={modalState}
              data={selectedItemTable}
              setModal={setModalState}
            />
          )}

          <div className={styles.header_name} style={{ marginBottom: "20px" }}>
            <h2 className={styles.header_name_text}>{text.home[lang]}</h2>
          </div>
          <div className={styles.header_history}>
            <h3 className={styles.header_history_text}>{text.history[lang]}</h3>
          </div>
          <div className={styles.date_container}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label={`${text.date_from[lang]}`}
                onChange={(e: any) => {
                  setFromDate(e);
                }}
                value={fromDate}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label={`${text.date_to[lang]}`}
                onChange={(e: any) => {
                  setToDate(e);
                }}
                value={toDate}
              />
            </LocalizationProvider>

            <Input
              height="40px"
              className={styles.input_search}
              value={KYCNationalId}
              onChange={(e) => {
                setKYCNationalId(e.target.value);
              }}
              placeholder={"KYC National Id"}
            />
            <ButtonComponent
              mode={"primary"}
              className={styles.date_block_button}
              onClick={() => getHistory(KYCNationalId)}
              style={{ marginLeft: "10px" }}
            >
              {loadingTable ? <LoadingSpinner /> : `${text.apply[lang]}`}
            </ButtonComponent>
          </div>
          <div className={styles.table_container}>
            <ButtonComponent
              mode={"primary"}
              className={styles.table_button}
              onClick={() => {
                const jsonForExcel: any = {};
                for (let i = 0; i < history.length; i++) {
                  jsonForExcel[`${i + 1}`] = {
                    "KYC National ID": `${history[i].loanModelInput.kyC_National_Id}`,
                    "CRIF National ID": `${history[i].loanModelInput.criF_National_Id}`,
                    Date: `${formatDate(history[i].requestTime, lang)}`,
                    "Result (PG)": `${
                      history[i].ageVerificationStatus &&
                      history[i].maxDelayDaysStatus &&
                      history[i].dbrVerificationStatus &&
                      history[i].obligationsVerificationStatus &&
                      history[i].nationalIdsVerificationStatus &&
                      history[i].scoringStatus === "Approve"
                        ? "Approve"
                        : history[i].scoringStatus === "Review"
                        ? "Review"
                        : "Reject"
                    }`,
                    "probability of good": `${
                      Number(history[i].probabilityOfGood * 100).toFixed(2) +
                      "%"
                    }`,
                    "Probability of failure": `${
                      Number(history[i].probabilityOfBad * 100).toFixed(2) + "%"
                    }`,
                    Age: `${history[i].loanModelInput.age}`,
                    Branch: `${history[i].loanModelInput.branch}`,
                    "Family Status": `${history[i].loanModelInput.familyStatus}`,
                    Gender: `${history[i].loanModelInput.gender}`,
                    // "Inflation Rate": `${history[i].loanModelInput.inflationRate}`,
                    "Interest Rate": `${
                      history[i].loanModelInput.interestRateMonthly * 100
                    }`,
                    "Loan Amount": `${history[i].loanModelInput.loanAmount}`,
                    "Loan Duration": `${history[i].loanModelInput.loanDuration}`,
                    DBR: `${Number(history[i].dbr_value * 100).toFixed(2)}`,
                    "Total Income": `${history[i].loanModelInput.totalIncome}`,
                    Obligations: `${history[i].loanModelInput.obligations}`,
                    "Credit Score": `${history[i].loanModelInput.creditScore}`,
                    "Max delayed days": `${history[i].loanModelInput.maxDelayDays}`,
                    Dependants: `${history[i].loanModelInput.dependants}`,
                    "Number of Loans": `${history[i].loanModelInput.numberOfLoans}`,
                    "Number of Active Loans": `${history[i].loanModelInput.numberOfActiveLoans}`,
                    "Total Monthly Payment": `${history[i].loanModelInput.totalMonthlyPayment}`,
                    "Delay Payments": `${history[i].loanModelInput.delayPayments}`,
                    Threshold: `${history[i].threshold * 100}`,
                  };
                }
                const sheetData = Object.values(jsonForExcel);
                if (history.length === 0) return;
                downloadExcel(sheetData);
              }}
            >
              {text.download_excel[lang]}
            </ButtonComponent>
            <div className={styles.table}>
              <div className={styles.footer_table}>
                <div className={styles.footer_table_first_column}>
                  <span className={styles.span_footer_table}>
                    {text.rows_per_page[lang]}:
                  </span>
                  {lang === "en" ? (
                    <select
                      style={{ backgroundColor: "white", color: "black" }}
                      onChange={(e) => {
                        setPer(Number(e.target.value) as any);
                      }}
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  ) : (
                    <select
                      style={{ backgroundColor: "white", color: "black" }}
                      onChange={(e) => {
                        setPer(Number(e.target.value) as any);
                      }}
                    >
                      <option value={"10"}>{formatNumber(10)}</option>
                      <option value={"20"}>{formatNumber(20)}</option>
                      <option value={"50"}>{formatNumber(50)}</option>
                      <option value={"100"}>{formatNumber(100)}</option>
                    </select>
                  )}
                </div>

                <div className={styles.footer_table_second_column}>
                  <span className={styles.span_footer_table}>
                    {lang === "en"
                      ? of.first +
                        "-" +
                        Math.min(of.second, history.length) +
                        " of " +
                        history.length
                      : formatNumber(of.first) +
                        "-" +
                        formatNumber(Math.min(of.second, history.length)) +
                        " من " +
                        formatNumber(history.length)}
                  </span>
                  <ButtonComponent
                    mode={"simple"}
                    className={styles.button_footer}
                    disabled={history.length === 0 || of.first === 1}
                    onClick={() => {
                      setOf({
                        first: of.first - per,
                        second: of.second - per,
                      });
                    }}
                  >
                    {"<"}
                  </ButtonComponent>
                  <ButtonComponent
                    mode={"simple"}
                    className={styles.button_footer}
                    disabled={
                      history.length === 0 || of.second >= history.length
                    }
                    onClick={() => {
                      setOf({
                        first: of.first + per,
                        second: of.second + per,
                      });
                    }}
                  >
                    {">"}
                  </ButtonComponent>
                </div>
              </div>
              <div className={styles.block_table}>
                <div className={styles.header_table_result}>
                  <span className={styles.table_th}>
                    {text.national_id[lang]}
                  </span>
                  <span className={styles.table_th}>{text.date[lang]}</span>
                  <span className={styles.table_th}>{text.result[lang]}</span>
                  <span className={styles.table_th}>Probability of good</span>
                  <span className={styles.table_th}>{text.age[lang]}</span>
                  <span className={styles.table_th}>{text.branch[lang]}</span>
                  <span className={styles.table_th}>
                    {text.family_status[lang]}
                  </span>
                  <span className={styles.table_th}>{text.gender[lang]}</span>

                  <span className={styles.table_th}>DBR</span>
                  {/*<span className={styles.table_th}>*/}
                  {/*  {text.inflation_rate[lang]}*/}
                  {/*</span>*/}
                  <span className={styles.table_th}>
                    {text.interest_rate[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.amount_of_loan[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.loan_duration[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.total_income[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.credit_score[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.dependants[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.number_of_loans[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.number_of_active_loans[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.total_monthly_payment[lang]}
                  </span>
                  <span className={styles.table_th}>
                    {text.delay_payments[lang]}
                  </span>
                  <span className={styles.table_th}>{"obligations"}</span>
                  <span className={styles.table_th}>Max delayed days</span>
                  <span className={styles.table_th}>
                    {text.threshold[lang]}
                  </span>
                  <span className={styles.table_th_last}>
                    {text.actions[lang]}
                  </span>
                </div>
                <div className={styles.table_result_items_container}>
                  {historyFilter.length > 0 ? (
                    historyFilter.map((e: HistoryType, i: number) => (
                      <div
                        key={i}
                        className={styles.table_result_item_container}
                      >
                        <div className={styles.td}>
                          <p>{e.loanModelInput.criF_National_Id}</p>
                        </div>
                        <div className={styles.td}>
                          <p>{formatDate(e.requestTime, lang)}</p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {e.ageVerificationStatus &&
                            e.dbrVerificationStatus &&
                            e.obligationsVerificationStatus &&
                            e.maxDelayDaysStatus &&
                            e.nationalIdsVerificationStatus
                              ? e.scoringStatus === "Approve"
                                ? `${text.approved[lang]}`
                                : e.scoringStatus === "Reject"
                                ? `${text.refusalScoring[lang]}`
                                : e.scoringStatus === "Review"
                                ? "Review"
                                : e.isBadLoan
                                ? "Reject"
                                : "Approve"
                              : `${text.refusalVerification[lang]}`}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p
                            className={styles.span_pr}
                            style={
                              e.ageVerificationStatus &&
                              e.dbrVerificationStatus &&
                              e.maxDelayDaysStatus &&
                              e.obligationsVerificationStatus &&
                              e.nationalIdsVerificationStatus &&
                              e.scoringStatus === "Approve"
                                ? {
                                    backgroundColor: "#48bb78",
                                  }
                                : e.scoringStatus === "Reject"
                                ? {
                                    backgroundColor: "red",
                                  }
                                : e.scoringStatus === "Review"
                                ? {
                                    backgroundColor: "orange",
                                  }
                                : !e.isBadLoan
                                ? { backgroundColor: "#48bb78" }
                                : { backgroundColor: "red" }
                            }
                          >
                            {e.ageVerificationStatus &&
                            e.dbrVerificationStatus &&
                            e.maxDelayDaysStatus &&
                            e.nationalIdsVerificationStatus &&
                            e.obligationsVerificationStatus ? (
                              lang === "en" ? (
                                Number(e.probabilityOfGood * 100).toFixed(2) +
                                "%"
                              ) : (
                                formatNumber(
                                  Number(
                                    Number(e.probabilityOfGood * 100).toFixed(2)
                                  )
                                ) + "%"
                              )
                            ) : (
                              <>--</>
                            )}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.age
                              : formatNumber(e.loanModelInput.age)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {(text as any)[e.loanModelInput.branch]
                              ? (text as any)[e.loanModelInput.branch][lang]
                              : e.loanModelInput.branch}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {(text as any)[e.loanModelInput.familyStatus][lang]}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {e.loanModelInput.gender
                              ? `${text.male[lang]}`
                              : `${text.female[lang]}`}
                          </p>
                        </div>

                        <div className={styles.td}>
                          <p>{Number(e.dbr_value * 100).toFixed(2)}%</p>
                        </div>

                        {/*<div className={styles.td}>*/}
                        {/*  <p>*/}
                        {/*    {lang === "en"*/}
                        {/*      ? e.loanModelInput.inflationRate*/}
                        {/*      : formatNumber(e.loanModelInput.inflationRate)}*/}
                        {/*  </p>*/}
                        {/*</div>*/}

                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.interestRateMonthly
                                ? Number(
                                    e.loanModelInput.interestRateMonthly * 100
                                  ).toFixed(2)
                                : 0
                              : formatNumber(
                                  e.loanModelInput.interestRateMonthly
                                    ? Number(
                                        Number(
                                          e.loanModelInput.interestRateMonthly *
                                            100
                                        ).toFixed(2)
                                      )
                                    : 0
                                )}
                            %
                          </p>
                        </div>

                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.loanAmount
                              : formatNumber(e.loanModelInput.loanAmount)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.loanDuration
                              : formatNumber(e.loanModelInput.loanDuration)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.totalIncome
                              : formatNumber(e.loanModelInput.totalIncome)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.creditScore
                              : formatNumber(e.loanModelInput.creditScore)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.dependants
                              : formatNumber(e.loanModelInput.dependants)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.numberOfLoans
                              : formatNumber(e.loanModelInput.numberOfLoans)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.numberOfActiveLoans
                              : formatNumber(
                                  e.loanModelInput.numberOfActiveLoans
                                )}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.totalMonthlyPayment
                              : formatNumber(
                                  e.loanModelInput.totalMonthlyPayment
                                )}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.delayPayments
                              : formatNumber(e.loanModelInput.delayPayments)}
                          </p>
                        </div>

                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.obligations
                              : formatNumber(e.loanModelInput.obligations)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.loanModelInput.maxDelayDays
                              : formatNumber(e.loanModelInput.maxDelayDays)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <p>
                            {lang === "en"
                              ? e.threshold * 100
                              : formatNumber(e.threshold * 100)}
                          </p>
                        </div>
                        <div className={styles.td}>
                          <ButtonComponent
                            mode={"primary"}
                            className={styles.button_download}
                            onClick={() => {
                              setModalState(!modalState);
                              setSelectedItemTable(e);
                            }}
                          >
                            {text.download[lang]}
                          </ButtonComponent>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.table_no_result}>
                      <div style={{ color: "black" }}>
                        {loadingTable ? (
                          <LoadingSpinner />
                        ) : (
                          `${text.no_rows[lang]}`
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <TableComponent
            loading={loading}
            historyGroupLoan={historyGroupLoan}
            loadingTable={loadingTable}
            lang={lang}
          />
        </div>
      )}
    </div>
  );
}
