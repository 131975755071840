import { useContext } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import styleModal from "./modal-pdf.styles.module.scss";
import myFont from "./font/Arial-Light.ttf";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
import { formatNumber } from "../../utils/format-number.ts";

Font.register({
  family: "Arial",
  src: myFont,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  viewer: {
    width: "90%",
    height: "80%",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0 10px 10px -5px rgba(0, 0, 0, 0.1)",
  },

  container_left_texts: {},

  text_header: {
    fontFamily: "Arial",
    fontSize: "25px",
  },

  text_left_header: {
    fontFamily: "Arial",
    fontSize: "14px",
    marginLeft: "15px",
  },

  table_container: {
    border: "1px solid silver",
    width: "94%",
    margin: "3%",
  },

  table_rows: {
    width: "100%",
    height: "25px",
    borderBottom: "1px solid silver",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
  },

  table_item: {
    borderRight: "1px solid silver",
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },

  text_row: {
    fontFamily: "Arial",
    fontSize: "14px",
    marginLeft: "15px",
  },

  footer_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "94%",
    margin: "3%",
  },

  text_footer: {
    fontFamily: "Arial",
    fontSize: "16px",
  },
});
export default function ModalPdfComponent({
  data,
  modal,
  setModal,
}: {
  data: any;
  modal: boolean;
  setModal: any;
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { authData } = useContext(AuthContext);
  const navigate = useNavigate();
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  function formatDate(data: string, lang: "ar" | "en") {
    const dateString = data;
    if (!dateString.trim()) return;

    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return Intl.DateTimeFormat(
      lang === "en" ? "en-EN" : "ar-u-nu-arab",
      options as object
    ).format(date);
  }
  // ---------------------------------------------------------------------------
  return (
    <div
      style={
        modal
          ? lang === "ar"
            ? { direction: "rtl" }
            : {}
          : { display: "none" }
      }
      className={styleModal.pdf_container}
      onClick={() => {
        setModal(false);
        navigate("/app/dashboard");
      }}
    >
      <PDFViewer style={styles.viewer}>
        {/* Start of the document*/}
        <Document>
          {/*render a single page*/}
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <Text style={styles.text_header}>{text.score[lang]}</Text>
            </View>
            <View style={styles.container_left_texts}>
              <Text style={styles.text_left_header}>
                {text.name_of_expert[lang]}: {authData?.firstName}{" "}
                {authData?.lastName} ({authData?.username})
              </Text>
              <Text style={styles.text_left_header}>
                {text.date_time[lang]}: {formatDate(data.requestTime, lang)}
              </Text>
              <Text style={styles.text_left_header}>
                {text.request_id[lang]}: {data.loanScoreRequestId}
              </Text>
            </View>

            <View style={styles.table_container}>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    KYC {text.national_id[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.loanModelInput.kyC_National_Id}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    CRIF {text.national_id[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.loanModelInput.criF_National_Id}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{text.age[lang]}</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.age
                      : formatNumber(data.loanModelInput.age)}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{text.branch[lang]}</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {(text as any)[data.loanModelInput.branch][lang]}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.family_status[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {(text as any)[data.loanModelInput.familyStatus][lang]}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{text.gender[lang]}</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.loanModelInput.gender
                      ? `${text.male[lang]}`
                      : `${text.female[lang]}`}
                  </Text>
                </View>
              </View>
              {/*<View style={styles.table_rows}>*/}
              {/*  <View style={styles.table_item}>*/}
              {/*    <Text style={styles.text_row}>*/}
              {/*      {text.inflation_rate[lang]}*/}
              {/*    </Text>*/}
              {/*  </View>*/}
              {/*  <View style={styles.table_item}>*/}
              {/*    <Text style={styles.text_row}>*/}
              {/*      {lang === "en"*/}
              {/*        ? data.loanModelInput.inflationRate*/}
              {/*        : formatNumber(data.loanModelInput.inflationRate)}*/}
              {/*    </Text>*/}
              {/*  </View>*/}
              {/*</View>*/}

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.interest_rate[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? Number(
                          data.loanModelInput.interestRateMonthly * 100
                        ).toFixed(2) ?? ""
                      : formatNumber(
                          Number(
                            Number(
                              data.loanModelInput.interestRateMonthly * 100
                            ).toFixed(2)
                          ) ?? 0
                        )}
                    %
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.amount_of_loan[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.loanAmount
                      : formatNumber(data.loanModelInput.loanAmount)}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.loan_duration[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.loanDuration
                      : formatNumber(data.loanModelInput.loanDuration)}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{text.total_income[lang]}</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.totalIncome
                      : formatNumber(data.loanModelInput.totalIncome)}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{text.credit_score[lang]}</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.creditScore
                      : formatNumber(data.loanModelInput.creditScore)}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>{text.dependants[lang]}</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.dependants
                      : formatNumber(data.loanModelInput.dependants)}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.number_of_loans[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.numberOfLoans
                      : formatNumber(data.loanModelInput.numberOfLoans)}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.number_of_active_loans[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.numberOfActiveLoans
                      : formatNumber(data.loanModelInput.numberOfActiveLoans)}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.total_monthly_payment[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.totalMonthlyPayment
                      : formatNumber(data.loanModelInput.totalMonthlyPayment)}
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {text.delay_payments[lang]}
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {lang === "en"
                      ? data.loanModelInput.delayPayments
                      : formatNumber(data.loanModelInput.delayPayments)}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>DBR</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {Number(data.dbr_value * 100).toFixed(2)}%
                  </Text>
                </View>
              </View>
              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Max delayed days</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.loanModelInput.maxDelayDays}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Obligations</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.loanModelInput.obligations}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Age verification status</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.ageVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>DBR verification status</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.dbrVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    National ID verification status
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.nationalIdsVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    Obligations verification status
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.obligationsVerificationStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    Max delay days verification status
                  </Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {data.maxDelayDaysStatus ? "Pass" : "Fail"}
                  </Text>
                </View>
              </View>

              <View style={styles.table_rows}>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>Probability of good</Text>
                </View>
                <View style={styles.table_item}>
                  <Text style={styles.text_row}>
                    {Number(Number(data.probabilityOfGood * 100).toFixed(2))}%
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.footer_container}>
              <Text style={styles.text_footer}>
                {text.result[lang]}:{" "}
                {lang === "en"
                  ? Number(data.probabilityOfGood * 100).toFixed(2) + "%"
                  : formatNumber(
                      Number(Number(data.probabilityOfGood * 100).toFixed(2))
                    )}{" "}
                {!(
                  data.ageVerificationStatus &&
                  data.dbrVerificationStatus &&
                  data.maxDelayDaysStatus &&
                  data.nationalIdsVerificationStatus &&
                  data.obligationsVerificationStatus
                )
                  ? `Rejected (Verification)`
                  : data.scoringStatus === "Reject"
                  ? "Rejected (Scoring)"
                  : data.scoringStatus === "Approve"
                  ? `"${text.approved[lang]}"`
                  : data.scoringStatus === "Review"
                  ? "Review"
                  : !data.isBadLoan
                  ? "Approve"
                  : "Reject"}
              </Text>
              <Text style={styles.text_footer}>
                {text.threshold[lang]}:{" "}
                {lang === "en"
                  ? Number(data.threshold * 100).toFixed(2)
                  : formatNumber(
                      Number(Number(data.threshold * 100).toFixed(2))
                    )}
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
}
