import { sendPutRequest } from "../../utils/send-put-request.ts";
import styles from "./modal-macro-update.styles.module.scss";
import { LoadingSpinner } from "../loading-spinner/loading-spinner.component.tsx";
import React, { useContext, useState } from "react";
import { Input } from "../input/input.component.tsx";
import { ButtonComponent } from "../button/button.component.tsx";
import { BiCheckCircle } from "react-icons/bi";
import { text } from "../../../text.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { formatDate } from "../../utils/formatDate.ts";

export default function ModalUpdateMacroComponent({
  getMacroVariables,
  setModal,
  dataMacro,
  setDataMacro,
  setGetMacroVariables,
}: {
  getMacroVariables: {
    id: string;
    lastUpdateTime: string;
    groupLoanThreshold: number;
    indivLoanThreshold: number;
  };
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  dataMacro: {
    groupLoan: number;
    loan: number;
  };
  setDataMacro: React.Dispatch<
    React.SetStateAction<{
      groupLoan: number;
      loan: number;
    }>
  >;
  setGetMacroVariables: React.Dispatch<
    React.SetStateAction<{
      id: string;
      lastUpdateTime: string;
      groupLoanThreshold: number;
      indivLoanThreshold: number;
    }>
  >;
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const { lang } = useContext(LanguageContext);

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const res = await sendPutRequest("/Score/UpdateMacroVariables", {
      groupLoanThreshold: dataMacro.groupLoan / 100,
      indivLoanThreshold: dataMacro.loan / 100,
    });
    setLoading(false);
    if (!res) return;
    if (res.status !== 200) {
      console.error(res.status);
      return;
    }
    setModalStatus(true);
    setTimeout(() => {
      setModalStatus(false);
      setModal(false);
    }, 3000);
    setGetMacroVariables((prevState) => ({
      ...prevState,
      groupLoanThreshold: dataMacro.groupLoan,
      indivLoanThreshold: dataMacro.loan,
    }));
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.modal_container}
      onClick={() => {
        setModal(false);
      }}
      style={
        lang === "ar"
          ? {
              direction: "rtl",
            }
          : {}
      }
    >
      {modalStatus ? (
        <div className={styles.modal_success}>
          <BiCheckCircle size={100} />
          <p>{text.threshold_successfully_updated[lang]}</p>
        </div>
      ) : (
        <div
          className={styles.modal_block}
          onClick={(e) => e.stopPropagation()}
        >
          <h3 className={styles.modal_h3}>{text.setting[lang]}</h3>
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <div className={styles.modal_int_block}>
              <p className={styles.last_update}>
                <span>{text.last_update[lang]}: </span>{" "}
                {formatDate(getMacroVariables.lastUpdateTime, lang)}
              </p>
              <label htmlFor={"3"} className={styles.modal_label}>
                <span className={styles.modal_span}>Group loan threshold</span>
                <Input
                  mode={"primary"}
                  id={"3"}
                  type={"number"}
                  min={1}
                  max={100}
                  onChange={(e) => {
                    setDataMacro({
                      ...dataMacro,
                      groupLoan: Number(e.target.value),
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  value={dataMacro ? dataMacro.groupLoan : ""}
                  required
                />
              </label>

              <label htmlFor={"4"} className={styles.modal_label}>
                <span className={styles.modal_span}>
                  Individual loan threshold
                </span>
                <Input
                  mode={"primary"}
                  id={"3"}
                  type={"number"}
                  min={1}
                  max={100}
                  onChange={(e) => {
                    setDataMacro({
                      ...dataMacro,
                      loan: Number(e.target.value),
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  value={dataMacro ? dataMacro.loan : ""}
                  required
                />
              </label>
            </div>

            <div className={styles.modal_button_container}>
              <ButtonComponent
                type={"button"}
                mode={"border"}
                style={
                  lang === "en"
                    ? {
                        width: "100px",
                        height: "35px",
                        fontSize: "14px",
                        marginRight: "10px",
                      }
                    : {
                        width: "100px",
                        height: "35px",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }
                }
                className={styles.modal_button_cancel}
                onClick={() => {
                  setModal(false);
                }}
              >
                {text.cancel[lang]}
              </ButtonComponent>
              <ButtonComponent
                style={{ width: "100px", height: "35px" }}
                mode={"primary"}
                className={styles.modal_button}
                disabled={loading}
              >
                {loading ? <LoadingSpinner /> : `${text.save[lang]}`}
              </ButtonComponent>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
