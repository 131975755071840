import * as React from "react";
import Popover from "@mui/material/Popover";
import { useContext, useEffect, useState } from "react";
import styles from "./popper.styles.module.scss";
import { FaUsers } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { FaSignOutAlt } from "react-icons/fa";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import ModalUpdateMacroComponent from "../modal-update-macro/modal-update-macro.component.tsx";
import { sendGetRequest } from "../../utils/send-get-request.ts";
import { text } from "../../../text.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";

export default function PopperComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  const [role, setRole] = useState<string | null>(null);
  const [modal, setModal] = useState(false);
  const [dataMacro, setDataMacro] = useState<{
    groupLoan: number;
    loan: number;
  }>({
    groupLoan: 0,
    loan: 0,
  });
  const [getMacroVariables, setGetMacroVariables] = useState<{
    id: string;
    lastUpdateTime: string;
    groupLoanThreshold: number;
    indivLoanThreshold: number;
  }>({
    id: "",
    lastUpdateTime: "",
    groupLoanThreshold: 0,
    indivLoanThreshold: 0,
  });
  const { lang } = useContext(LanguageContext);

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (authData?.role) {
      setRole(authData?.role.toLocaleLowerCase());
    }
    sendGetRequest("/Score/GetMacroVariables").then((res) => {
      if (!res) return;
      res.json().then((data) => {
        const date = new Date(data.lastUpdateTime);
        setDataMacro({
          groupLoan: Number((data.groupLoanThreshold * 100).toFixed(2)),
          loan: Number((data.indivLoanThreshold * 100).toFixed(2)),
        });

        const newData = {
          id: data.id,
          lastUpdateTime: String(date),
          groupLoanThreshold: data.groupLoanThreshold * 100,
          indivLoanThreshold: data.indivLoanThreshold * 100,
        };
        setGetMacroVariables(newData);
      });
    });
  }, []);
  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ---------------------------------------------------------------------------
  return (
    <div>
      <button className={styles.button_user} onClick={handleClick}>
        <FaUsers size={22} style={{ fill: "#5b21b6" }} />
      </button>
      {modal && (
        <ModalUpdateMacroComponent
          getMacroVariables={getMacroVariables}
          setModal={setModal}
          dataMacro={dataMacro}
          setDataMacro={setDataMacro}
          setGetMacroVariables={setGetMacroVariables}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ul
          className={styles.popper_ul}
          style={
            lang === "ar"
              ? {
                  direction: "rtl",
                }
              : {}
          }
        >
          <div className={styles.popper_div}>
            <span className={styles.popper_span}>
              <i className={styles.popper_i}>
                {authData?.firstName} {authData?.lastName}
              </i>
            </span>
            <span className={styles.popper_span}>
              <i style={{ color: "#7e3af2" }}> {authData?.username}</i>
            </span>
          </div>
          {(role === "zypl" || role === "fincamanager") && (
            <li className={styles.popper_li}>
              <Link
                to={"/app/createUser"}
                className={styles.popper_link}
                onClick={handleClose}
              >
                <FaUser />
                <span className={styles.popper_span}>
                  {text.register_user[lang]}
                </span>
              </Link>
            </li>
          )}
          {(role === "zypl" || role === "fincamanager") && (
            <li className={styles.popper_li}>
              <Link
                to={"/app/users"}
                className={styles.popper_link}
                onClick={handleClose}
              >
                <FaUsers />
                <span className={styles.popper_span}>
                  {text.user_list[lang]}
                </span>
              </Link>
            </li>
          )}
          <li className={styles.popper_li}>
            <Link
              to={"/app/changePassword"}
              className={styles.popper_link}
              onClick={handleClose}
            >
              <FaEdit />
              <span className={styles.popper_span}>
                {text.change_password[lang]}
              </span>
            </Link>
          </li>
          {(role === "zypl" || role === "fincamanager") && (
            <li className={styles.popper_li}>
              <div
                className={styles.popper_link}
                onClick={() => {
                  handleClose();
                  setModal(true);
                }}
              >
                <FiSettings />
                <span className={styles.popper_span}>{text.setting[lang]}</span>
              </div>
            </li>
          )}
          <li
            className={styles.popper_li}
            onMouseDown={() => {
              localStorage.clear();
              navigate("/login");
              handleClose();
            }}
          >
            <FaSignOutAlt />
            <span className={styles.popper_span}>{text.log_out[lang]}</span>
          </li>
        </ul>
      </Popover>
    </div>
  );
}
