import "./App.css";
import { SidebarProvider } from "./ui-components/sidebar";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPageComponent from "./pages/login/login-page.component.tsx";
import { PanelProvider } from "./ui-components/panel";
import DashboardPageComponent from "./pages/dashboard/dashboard-page.component.tsx";
import { HeaderProvider } from "./ui-components/header/header.provider.tsx";
import PredictionPageComponent from "./pages/prediction/prediction-page.component.tsx";
import LicensePageComponent from "./pages/license/license-page.component.tsx";
import SignUpPageComponent from "./pages/sign-up/sign-up-page.component.tsx";
import ChangePasswordPageComponent from "./pages/change-password/change-password-page.component.tsx";
import PageNotFound from "./pages/404/404-page.tsx";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./contexts/auth-context/auth.context.tsx";
import ListUserPageComponent from "./pages/list-user/list-user-page.component.tsx";
export default function App() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { authData } = useContext(AuthContext);

  const [role, setRole] = useState<string | null>(null);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData?.role) {
      setRole(authData?.role.toLocaleLowerCase());
    }
  }, []);
  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------
  return (
    <SidebarProvider>
      <PanelProvider>
        <div className={"all_container"}>
          <HeaderProvider>
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/app/dashboard" replace />}
              />
              <Route path={"/login"} element={<LoginPageComponent />} />
              <Route
                path={"/app/dashboard"}
                element={<DashboardPageComponent />}
              />
              <Route
                path={"/app/prediction"}
                element={<PredictionPageComponent />}
              />
              {(role === "zypl" || role === "fincamanager") && (
                <Route
                  path={"/app/license"}
                  element={<LicensePageComponent />}
                />
              )}
              {(role === "zypl" || role === "fincamanager") && (
                <Route
                  path={"/app/createUser"}
                  element={<SignUpPageComponent />}
                />
              )}
              {(role === "zypl" || role === "fincamanager") && (
                <Route
                  path={"/app/users"}
                  element={<ListUserPageComponent />}
                />
              )}
              <Route
                path={"/app/changePassword"}
                element={<ChangePasswordPageComponent />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </HeaderProvider>
        </div>
      </PanelProvider>
    </SidebarProvider>
  );
}
