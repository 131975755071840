import { useContext, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./select-language.styles.module.css";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
const languageChoices = ["EN", "AR"];

const LanguageDropdown = () => {
  const getLanguageLS = localStorage.getItem("lang");
  const validateLS =
    getLanguageLS && (getLanguageLS === "en" || getLanguageLS === "ar");
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(
    validateLS ? getLanguageLS.toUpperCase() : "EN"
  );
  const { setLang } = useContext(LanguageContext);
  function handleChangeLanguage(newLang: string) {
    setListOpen(false);
    setLanguage(newLang);
    localStorage.setItem("lang", newLang.toLowerCase());
  }

  useEffect(() => {
    setLang(language.toLowerCase() as any);
  }, [language]);

  return (
    <div className={styles.language}>
      <div
        className={styles.language__header}
        onClick={() => setListOpen(!isListOpen)}
        style={
          isListOpen
            ? {
                backgroundColor: "#bcbcbc",
                borderRadius: "5px",
                width: "70px",
              }
            : {}
        }
      >
        <span style={{ textAlign: "center" }}>{language}</span>
      </div>
      <CSSTransition in={isListOpen} timeout={100} unmountOnExit>
        <>
          <div className={styles.language__overview}>
            <div className={styles.language__list}>
              {languageChoices.map((item: string, index: number) => (
                <div
                  key={index}
                  className={styles.language__list__item}
                  onClick={() => handleChangeLanguage(item)}
                >
                  <span style={{ textAlign: "center" }}>{item}</span>
                </div>
              ))}
            </div>
          </div>
          <div
            className={styles.language__cover}
            onMouseDown={() => setListOpen(false)}
          />
        </>
      </CSSTransition>
    </div>
  );
};

export default LanguageDropdown;
