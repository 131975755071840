export function formatDate(data: string, lang: "ar" | "en") {
  const dateString = data;
  if (!dateString.trim()) return;

  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return Intl.DateTimeFormat(
    lang === "en" ? "en-EN" : "ar-u-nu-arab",
    options as object
  ).format(date);
}
