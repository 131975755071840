import React from "react";
import styles from "./modal-license.styles.module.scss";
import { Icon } from "../icon/icon.component.tsx";
import { RiErrorWarningLine } from "react-icons/ri";

export default function ModalLicense({
  setModal,
}: {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  function close() {
    setModal(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.container__x} onClick={close}>
        <Icon name={"fa-solid fa-x"} size={"tiny"} />
      </div>
      <div className={styles.container__text}>
        <RiErrorWarningLine size={40} style={{ fill: "red" }} />
        <p>License expired</p>
      </div>
    </div>
  );
}
