export const Config = {
  host:
    import.meta.env.VITE_API_BASE_URL,
  hostOCR:
    import.meta.env.VITE_API_OCR_URL ??
    "https://ocr-test-zypl.westeurope.cloudapp.azure.com/read_jordan_new",
  clim_url: import.meta.env.VITE_CLAIM_URL ?? "http://localhost:0000",
  jwtSecret:
    "751db26ea0d648458af454a9142ff9c9b7c12d2ac653c1336b85a669897ac36652935ab0017ebf41df68ac042e24f6201e2b1dcc14c734eeaa69f12bbb4818dd",
};
