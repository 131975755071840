export const text = {
  home: {
    en: "Home",
    ar: "العنوان",
  },

  national_id: {
    en: "National ID",
    ar: "الهوية الوطنية",
  },
  loan_monthly_payment: {
    en: "Loan monthly payment",
    ar: "Loan monthly payment",
  },
  get_scoring_result: {
    en: "Scoring",
    ar: "نتيجة التقييم بالنقاط للمستعلم عنه هي",
  },
  license: {
    en: "License",
    ar: "الرخصه",
  },
  history: {
    en: "History",
    ar: "الماضي",
  },

  date_from: {
    en: "Date from",
    ar: "التاريخ من",
  },

  date_to: {
    en: "Date to",
    ar: "التاريخ لغاية",
  },

  apply: {
    en: "Apply",
    ar: "تقديم",
  },

  download_excel: {
    en: "Download Excel",
    ar: "تحميل الاكسل",
  },

  date: {
    en: "Date",
    ar: "التاريخ",
  },

  result: {
    en: "Result (PG)",
    ar: "النتيجة",
  },

  probability_of_success: {
    en: "Scoring prediction",
    ar: "احتمالية النجاح",
  },

  age: {
    en: "Age",
    ar: "العمر",
  },

  branch: {
    en: "Branch",
    ar: "الفرع",
  },

  family_status: {
    en: "Marital status",
    ar: "الوضع العائلي",
  },

  gender: {
    en: "Gender",
    ar: "الجنس",
  },

  Inflation_rate: {
    en: "Inflation Rate",
    ar: "معدل التضخم",
  },

  amount_of_loan: {
    en: "Loan amount",
    ar: "القيمة",
  },
  loan_duration: {
    en: "Loan duration",
    ar: "مدة القرض",
  },

  prior_loans: {
    en: "Prior Loans",
    ar: "القروض السابقة",
  },

  threshold: {
    en: "Threshold",
    ar: "الحد",
  },

  actions: {
    en: "Actions",
    ar: "الإجراءات",
  },

  download: {
    en: "Download",
    ar: "تحميل",
  },

  client_and_credit_data: {
    en: "Client and credit data",
    ar: "بيانات العميل والائتمان",
  },

  interest_rate: {
    en: "Interest rate",
    ar: "سعر الفائدة",
  },

  duration: {
    en: "Duration",
    ar: "مدة القرض",
  },

  total_income: {
    en: "Total Income",
    ar: "اجمالي الدخل",
  },

  male: {
    en: "Male",
    ar: "ذكر",
  },

  female: {
    en: "Female",
    ar: "أنثى",
  },

  inflation_rate: {
    en: "Inflation Rate",
    ar: "معدل التضخم",
  },

  married: {
    en: "Married",
    ar: "متزوج",
  },

  divorced: {
    en: "Divorced",
    ar: "مطلق",
  },

  single: {
    en: "Single",
    ar: "أعزب",
  },

  widowed: {
    en: "Widow/Widower",
    ar: "أرمل",
  },

  separated: {
    en: "Separated",
    ar: "منفصل",
  },

  choose: {
    en: "Choose",
    ar: "اختيار",
  },

  hussein: {
    en: "Hussein",
    ar: "الحسين",
  },

  salt_branch: {
    en: "Salt Branch",
    ar: "السلط",
  },
  rsaifeh_branch: {
    en: "Rsaifeh Branch",
    ar: "Rsaifeh Branch",
  },

  hitteen: {
    en: "Hitteen",
    ar: "حطين",
  },

  sahab: {
    en: "Sahab",
    ar: "سحاب",
  },

  zarqa: {
    en: "Zarqa",
    ar: "الزرقاء",
  },

  koura_branch: {
    en: "Koura Branch",
    ar: "الكورة",
  },

  irbid: {
    en: "Irbid",
    ar: "اربد",
  },

  dayr_alla: {
    en: "Dayr Alla",
    ar: "دير علا",
  },

  madaba: {
    en: "Madaba",
    ar: "مادبا",
  },

  n_shouna: {
    en: "N Shouna",
    ar: "الشونة الشمالية",
  },

  beni_kenana_branch: {
    en: "Beni Kenana Branch",
    ar: "بني كنانة",
  },

  jerash: {
    en: "Jerash",
    ar: "جرش",
  },

  marka_branch: {
    en: "Marka Branch",
    ar: "ماركا",
  },

  al_karak_branch: {
    en: "Al Karak Branch",
    ar: "الكرك",
  },

  other: {
    en: "Other",
    ar: "Other",
  },

  zyzia: {
    en: "Zyzia",
    ar: "Zyzia",
  },

  Mafraq: {
    en: "Mafraq",
    ar: "Mafraq",
  },
  Aqaba: {
    en: "Aqaba",
    ar: "Aqaba",
  },
  biader_office: {
    en: "Biader Office",
    ar: "البيادر",
  },

  license_expires: {
    en: "License expires",
    ar: "تاريخ انتهاء الرخصة",
  },

  request_an_extension: {
    en: "Request an extension",
    ar: "طلب تمديد",
  },

  update: {
    en: "Update",
    ar: "تحديث",
  },

  are_you_sure_you_want_to_request_an_extension: {
    en: "Are you sure you want to request an extension?",
    ar: "هل انت متاكد انك تريد طلب تمديد؟",
  },

  enable_automatic_license_renewal: {
    en: "Enable automatic license renewal",
    ar: "تفعيل التجديد التلقائي للرخصة",
  },

  you_have_a_issues_with_an_automatic_update_of_the_license: {
    en: "You have a issues with an automatic update of the license",
    ar: "لديك مشاكل في التحديث التلقائي للرخصة",
  },

  upgrade_license: {
    en: "Upgrade license",
    ar: "ترقية الرخصة",
  },

  cipher: {
    en: "Cipher",
    ar: "الشفرة",
  },

  this_field_is_required: {
    en: "This field is required",
    ar: "الخانة مطلوبة",
  },

  cancel: {
    en: "Cancel",
    ar: "سحاب",
  },

  save: {
    en: "Save",
    ar: "احفظ",
  },

  yes: {
    en: "Yes",
    ar: "نعم",
  },

  refusalScoring: {
    en: "Rejected (Scoring)",
    ar: "رفض",
  },
  refusalVerification: {
    en: "Rejected (Verification)",
    ar: "رفض",
  },

  register_user: {
    en: "Register User",
    ar: "إسم العميل (عربي)",
  },

  name: {
    en: "Name",
    ar: "الإسم الأول (عربي)",
  },

  surname: {
    en: "Surname",
    ar: "الإسم الأخير (عربي)",
  },

  username: {
    en: "Username",
    ar: "إسم المستخدم",
  },

  password: {
    en: "Password",
    ar: "كلمة السر",
  },

  repeat_password: {
    en: "Repeat Password",
    ar: "اعد كلمة السر",
  },

  role: {
    en: "Role",
    ar: "قاعدة",
  },

  change_password: {
    en: "Change password",
    ar: "تغيير كلمة السر",
  },

  old_password: {
    en: "Old Password",
    ar: "كلمة السر القديمة",
  },

  new_password: {
    en: "New password",
    ar: "كلمة السر الحديثة",
  },

  repeat_new_password: {
    en: "Repeat new password",
    ar: "اعادة ادخال كلمة السر الحديثة",
  },

  create: {
    en: "Create",
    ar: "إنشاء",
  },

  change: {
    en: "Change ",
    ar: "تغيير",
  },

  setting: {
    en: "Setting",
    ar: "اعدادات",
  },

  last_update: {
    en: "Last update",
    ar: "اخر تحديث",
  },

  log_out: {
    en: "Log out",
    ar: "تسجيل الخروج",
  },

  approved: {
    en: "Approved",
    ar: "موافقه",
  },

  no_rows: {
    en: "no rows",
    ar: "لا توجد صفوف",
  },

  rows_per_page: {
    en: "Rows per page",
    ar: "صفوف في الصفحة",
  },

  license_expired: {
    en: "License expired",
    ar: "الرخصة انتهت",
  },
  create_a_new_license: {
    en: "Create a new license",
    ar: "قم بإنشاء ترخيص جديد",
  },

  license_successfully_updated: {
    en: "License successfully updated!",
    ar: "تم تحديث الترخيص بنجاح!",
  },

  license_successfully_created: {
    en: "License successfully created!",
    ar: "تم إنشاء الترخيص بنجاح!",
  },

  you_do_not_have_access_to_create_a_license: {
    en: "You do not have access to create a license",
    ar: "ليس لديك حق الوصول لإنشاء ترخيص",
  },
  denied: {
    en: "Denied",
    ar: "رفض",
  },

  user_already_exist: {
    en: "User already exist",
    ar: "المستخدم موجود بالفعل",
  },

  user_successfully_registered: {
    en: "User successfully registered",
    ar: "تم تسجيل المستخدم بنجاح",
  },

  loan_expert: {
    en: "Loan Expert",
    ar: "خبير القرض",
  },

  finca_manager: {
    en: "Finca Manager",
    ar: "Finca مدير",
  },

  password_changed_successfully: {
    en: "Password changed successfully",
    ar: "تم تغيير كلمة المرور بنجاح",
  },

  password_doesnt_match: {
    en: "Password doesn't match",
    ar: "كلمة السر غير متطابقة",
  },

  credit_score: {
    en: "Credit Score",
    ar: "مستوى التوازن",
  },

  number_of_loans: {
    en: "Number of Loans",
    ar: "عدد القروض",
  },

  dependants: {
    en: "Dependants",
    ar: "المعالين",
  },

  total_monthly_payment: {
    en: "Total Monthly Payment",
    ar: "إجمالي المدفوعات الشهرية",
  },

  number_of_active_loans: {
    en: "Number of Active Loans",
    ar: "عدد القروض النشطة",
  },

  delay_payments: {
    en: "Delay Payments",
    ar: "تأخير الدفعات",
  },

  blocked: {
    en: "Block",
    ar: "منعت",
  },

  status: {
    en: "Status",
    ar: "ولاية",
  },

  name_user_list: {
    en: "name",
    ar: "اسم",
  },

  user_list: {
    en: "User List",
    ar: "قائمة المستخدم",
  },

  active: {
    en: "Activate",
    ar: "نشيط",
  },

  threshold_successfully_updated: {
    en: "Threshold successfully updated!",
    ar: "تم تحديث العتبة بنجاح!",
  },

  score: {
    en: "Score",
    ar: "Score",
  },

  name_of_expert: {
    en: "Name of Expert",
    ar: "اسم الخبير",
  },

  date_time: {
    en: "Date Time",
    ar: "تاريخ الوقت",
  },

  request_id: {
    en: "Request ID",
    ar: "طلب الهوية",
  },
};
