import { Config } from "../app/AppConfig.ts";
export async function sendPostRequestOCR(pdf?: any) {
  try {
    const formData = new FormData();
    formData.append("jordan_new_pdf", pdf);
    return await fetch(Config.hostOCR, {
      method: "POST",
      body: formData,
    });
  } catch (e) {
    console.log(e);
  }
}
