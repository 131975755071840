import { Config } from "../app/AppConfig.ts";
import { decryptString } from "./decrypt-encrypt-string.ts";
export async function sendGetRequest(url: string) {
  try {
    const userGetLocalStorage = localStorage.getItem("user");
    const decrypt = userGetLocalStorage
      ? JSON.parse(decryptString(userGetLocalStorage, Config.jwtSecret))
      : undefined;
    const token = decrypt && decrypt.token;
    return await fetch(Config.host + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
  }
}
