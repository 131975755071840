import { useEffect, useState } from "react";
import styles from "./header.styles.module.scss";
import { useLocation } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { useSidebar } from "../sidebar";
import PopperComponent from "../popper/popper.component.tsx";
import SelectLanguageComponent from "../select-language/select-language.component.tsx";

export default function HeaderComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const location = useLocation();
  const [find, setFind] = useState<number>(-1);

  const sidebar = useSidebar();
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    setFind(location.pathname.indexOf("login"));
  }, [location.pathname]);
  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.header_container}
      style={find !== -1 ? { display: "none" } : {}}
    >
      <div className={styles.header_popper}>
        <button
          className={styles.button_sidebar}
          onClick={() => {
            sidebar.open({
              width: "20",
              closeOnEsc: true,
              closeOnOutsideClick: true,
            });
          }}
        >
          <BiMenu size={22} style={{ fill: "#5b21b6" }} />
        </button>
        <SelectLanguageComponent />
        <PopperComponent />
      </div>
    </div>
  );
}
