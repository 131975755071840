import React, { useState } from "react";
import { LanguageContext } from "./language.context.tsx";

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const getLanguageLS = localStorage.getItem("lang");
  const validateLS =
    getLanguageLS && (getLanguageLS === "en" || getLanguageLS === "ar");
  const [lang, setLang] = useState<"en" | "ar">(
    validateLS ? getLanguageLS : "en"
  );
  return (
    <LanguageContext.Provider value={{ lang: lang, setLang: setLang }}>
      {children}
    </LanguageContext.Provider>
  );
}
