import { HeaderContext } from "./header.context.tsx";
import HeaderComponent from "./header.component.tsx";
import { useState } from "react";

export function HeaderProvider(props: { children: React.ReactNode }) {
  const [show, setShow] = useState(true);
  const toggleShow = () => {
    setShow(false);
  };
  return (
    <HeaderContext.Provider
      value={{
        show,
        toggleShow,
      }}
    >
      {show && <HeaderComponent />}
      {props.children}
    </HeaderContext.Provider>
  );
}
