import { Config } from "../app/AppConfig.ts";
import { decryptString } from "./decrypt-encrypt-string.ts";
export async function sendPutRequest(
  url: string,
  data?: {
    groupLoanThreshold: number;
    indivLoanThreshold: number;
  }
) {
  try {
    const userGetLocalStorage = localStorage.getItem("user");
    const decrypt = userGetLocalStorage
      ? JSON.parse(decryptString(userGetLocalStorage, Config.jwtSecret))
      : undefined;
    const token = decrypt && decrypt.token;
    if (!data) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      return await fetch(Config.host + url, requestOptions);
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        groupLoanThreshold: data.groupLoanThreshold,
        indivLoanThreshold: data.indivLoanThreshold,
      }),
    };
    return await fetch(Config.host + url, requestOptions);
  } catch (e) {
    console.log(e);
  }
}
