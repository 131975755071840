import { PanelContext } from "./panel.context.tsx";
import PanelComponent from "./panel.component.tsx";
import { useState } from "react";

export function PanelProvider(props: { children: React.ReactNode }) {
  const [show, setShow] = useState(true);
  const toggleShow = () => {
    setShow(false);
  };
  return (
    <PanelContext.Provider
      value={{
        show,
        toggleShow,
      }}
    >
      {show && <PanelComponent />}
      {props.children}
    </PanelContext.Provider>
  );
}
