import styles from "./404-style.module.scss";
import { useContext, useEffect } from "react";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
export default function PageNotFound() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData?.token) {
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status !== 200) {
          navigate("/login");
          return;
        }
      });
    } else {
      navigate("/login");
      return;
    }
  }, [navigate]);
  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  return (
    <div className={styles.page_not_find_container}>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}
