import styles from "./license-styles.module.scss";
import { useContext, useEffect, useState } from "react";
import ModalRefresh from "./modal-refresh/modal-refresh.tsx";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { useNavigate } from "react-router-dom";
import ModalAutoRefreshComponent from "./modal-auto-refresh/modal-auto-refresh.component.tsx";
import { sendGetRequest } from "../../utils/send-get-request.ts";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { formatDate } from "../../utils/formatDate.ts";
import ModalCreateLicenseComponent from "./modal-create-license/modal-create-license.component.tsx";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { text } from "../../../text.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
export default function LicensePageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [modalCancelActive, setModalCancelActive] = useState(false);
  const navigate = useNavigate();
  const [autoRefreshLicenseModalActive, setAutoRefreshLicenseModalActive] =
    useState(false);
  const [createLicense, setCreateLicense] = useState(false);
  const [state, setState] = useState({
    text: "Лицензии нет",
    date: "",
    expired: false,
    modal: false,
    type: "",
    title: "",
    loading: false,
    method: () => {},
  });
  const [loading, setLoading] = useState(false);
  const { authData } = useContext(AuthContext);
  const [role, setRole] = useState<string | null>(null);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (authData?.role) {
      setRole(authData?.role.toLocaleLowerCase());
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (authData && authData.token) {
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status !== 200) {
          navigate("/login");
          return;
        }
      });

      sendGetRequest("/License").then((res) => {
        if (res) {
          res.json().then((e) => {
            const date = e.expirationDate;
            const now = new Date();
            const expireDate = new Date(date);
            if (expireDate.getTime() > now.getTime()) {
              setState((prev) => ({
                ...prev,
                date,
                loading: false,
              }));
            } else {
              setState((prev) => ({
                ...prev,
                date,
                expired: true,
                loading: false,
              }));
            }
          });
        }
        setLoading(false);
      });
    } else {
      navigate("/login");
    }
  }, []);

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.license_container}
      style={
        lang === "ar"
          ? {
              direction: "rtl",
            }
          : {}
      }
    >
      {modalCancelActive && (
        <ModalRefresh setModalCancelActive={setModalCancelActive} />
      )}
      {autoRefreshLicenseModalActive && (
        <ModalAutoRefreshComponent
          setModalCancelActive={setAutoRefreshLicenseModalActive}
        />
      )}
      {createLicense && (
        <ModalCreateLicenseComponent setModalCancelActive={setCreateLicense} />
      )}
      <div className={styles.license_block}>
        <div className={styles.header_text_container}>
          <h2 className={styles.header_text}>{text.license[lang]}</h2>
        </div>
        <div className={styles.main_container}>
          {loading ? (
            <LoadingSpinner />
          ) : !state.expired ? (
            <>
              <span style={{ fontSize: "18px", textAlign: "center" }}>
                {text.license_expires[lang]}:{" "}
                <em style={{ color: "green" }}>
                  {formatDate(state.date, lang)}
                </em>
              </span>
            </>
          ) : (
            <span style={{ fontSize: "18px" }}>
              {text.license_expired[lang]}
            </span>
          )}
          <div className={styles.button_container}>
            {authData && role === "zypl" && (
              <ButtonComponent
                style={{ width: "200px", height: "40px", margin: "10px" }}
                mode={"primary"}
                className={styles.button_create_license}
                onClick={() => setCreateLicense(true)}
              >
                {text.create_a_new_license[lang]}
              </ButtonComponent>
            )}

            <ButtonComponent
              mode={"primary"}
              style={{ margin: "10px" }}
              className={styles.button_first}
              onClick={() => setAutoRefreshLicenseModalActive(true)}
            >
              {text.request_an_extension[lang]}
            </ButtonComponent>
            <button
              className={styles.button_second}
              onClick={() => {
                setModalCancelActive(true);
              }}
            >
              {text.update[lang]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
