import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ThemeProvider } from "./contexts/theme-context/theme.provider.tsx";
import { AuthProvider } from "./contexts/auth-context/auth.provider.tsx";
import { LanguageProvider } from "./contexts/language-context/language.provider.tsx";

const basePath = String(import.meta.env.VITE_BASE_PATH);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter basename={basePath}>
    <LanguageProvider>
      <ThemeProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ThemeProvider>
    </LanguageProvider>
  </BrowserRouter>
);
