import React, { useContext, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { BiCheckCircle } from "react-icons/bi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import styles from "./modal-create-license.styles.module.scss";
import { LoadingSpinner } from "../../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { ButtonComponent } from "../../../ui-components/button/button.component.tsx";
import { sendGetRequest } from "../../../utils/send-get-request.ts";
import { text } from "../../../../text.tsx";
import { LanguageContext } from "../../../contexts/language-context/language.context.tsx";

export default function ModalCreateLicenseComponent({
  setModalCancelActive,
}: {
  setModalCancelActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [onInputChange, setInputChange] = useState(dayjs());
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  async function send() {
    setLoading(true);
    const date = onInputChange.format("YYYY-MM-DDT00:00:00.000[Z]");
    const res = await sendGetRequest(
      `/License/createNewLicense?expirationDate=${date}`
    );
    setLoading(false);
    if (!res) {
      setModalWarning(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setModalWarning(false);
      }, 3000);
      return;
    }
    if (res.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setSuccess(false);
      }, 3000);
    } else {
      setModalWarning(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setModalWarning(false);
      }, 3000);
    }
  }
  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.modal_container}
      onClick={() => setModalCancelActive(false)}
    >
      {success ? (
        <div className={styles.modal_container_success}>
          <div className={styles.statusModal}>
            <BiCheckCircle size={100} />
            <p>{text.license_successfully_created[lang]}</p>
          </div>
        </div>
      ) : modalWarning ? (
        <div
          style={{
            width: "400px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
          <p style={{ color: "orangered" }}>
            {text.you_do_not_have_access_to_create_a_license[lang]}
          </p>
        </div>
      ) : (
        <div
          className={styles.modal_block}
          onClick={(e) => e.stopPropagation()}
        >
          <h3>Create a new license</h3>
          <label htmlFor={"1"} className={styles.label}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                onChange={(e: any) => {
                  setInputChange(e);
                }}
                value={onInputChange}
              />
            </LocalizationProvider>
          </label>
          <div className={styles.button_container}>
            <ButtonComponent
              mode={"border"}
              className={styles.button_cancel}
              onClick={() => {
                setModalCancelActive(false);
              }}
            >
              {text.cancel[lang]}
            </ButtonComponent>
            <ButtonComponent
              mode={"primary"}
              className={styles.button_save}
              onClick={send}
            >
              {loading ? <LoadingSpinner /> : `${text.save[lang]}`}
            </ButtonComponent>
          </div>
        </div>
      )}
    </div>
  );
}
