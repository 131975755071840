import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { BiCheckCircle } from "react-icons/bi";
import styles from "./prediction-styles.module.scss";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { Input } from "../../ui-components/input/input.component.tsx";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import ModalPdfComponent from "../../ui-components/modal-pdf/modal-pdf.component.tsx";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import {
  HistoryType,
  PredictType,
  PredictTypeGroupLoan,
  PredictTypeValidation,
  ValidationGroupLoanErrors,
} from "../../types/types.ts";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
import { text } from "../../../text.tsx";
import { formatNumber } from "../../utils/format-number.ts";
import { sendPostRequestOCR } from "../../utils/send-post-request-ocr.ts";
import { Icon } from "../../ui-components/icon/icon.component.tsx";
import ModalLicense from "../../ui-components/modal-license/modal-license.component.tsx";
import Switcher from "../../ui-components/swithcher/switcher.component.tsx";
import ModalPdfGroupLoanComponent from "../../ui-components/modal-pdf/modal-pdf-group-loan.component.tsx";

export default function PredictionPageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [data, setData] = useState<PredictType | null>(null);

  const [dataGroupLoan, setDataGroupLoan] = useState<PredictTypeGroupLoan>({
    groupLoan: [
      {
        id: 1,
        crif_national_id: "",
        kyc_national_id: "",
        age: "",
        familyStatus: "",
        gender: "",
        creditScore: "",
        dependants: "",
        numberOfLoans: "",
        numberOfActiveLoans: "",
        totalMonthlyPayment: "",
        delayPayments: "",
        numberOfObligations: "",
        dbr: "",
        totalIncome: "",
        selectedFile: null,
        loadingUpload: false,
        selectedFileStatus: true,
        textErrorUpload: true,
        errorUploadFile: false,
        successUploadFile: false,
        uploadFile: false,
        uploadStatus: false,
        ocR_status: "0",
        loanAmount: "",
        loanMonthlyPayment: "",
        maxDelayedDays: "",
      },
      {
        id: 2,
        crif_national_id: "",
        kyc_national_id: "",
        age: "",
        familyStatus: "",
        gender: "",
        creditScore: "",
        dependants: "",
        numberOfLoans: "",
        numberOfActiveLoans: "",
        totalMonthlyPayment: "",
        delayPayments: "",
        numberOfObligations: "",
        dbr: "",
        totalIncome: "",
        selectedFile: null,
        loadingUpload: false,
        selectedFileStatus: true,
        textErrorUpload: true,
        errorUploadFile: false,
        successUploadFile: false,
        uploadFile: false,
        uploadStatus: false,
        ocR_status: "0",
        loanAmount: "",
        loanMonthlyPayment: "",
        maxDelayedDays: "",
      },
    ],
    branch: "",
    inflationRate: "",
    interestRateMonthly: "",
    loanDuration: "",
    ocR_status: "",
    coBorrower: "",
  });

  const [resultGroupLoan, setResultHistoryLoan] = useState<any>([]);

  const [validationGroupLoan, setValidationGroupLoan] =
    useState<ValidationGroupLoanErrors>();

  const [errorValidation, setErrorValidation] = useState<PredictTypeValidation>(
    {
      age: "",
      interestRateMonthly: "",
      loanAmount: "",
      loanDuration: "",
      totalIncome: "",
      // creditScore: "",
      // dependants: "",
      // numberOfLoans: "",
      // numberOfActiveLoans: "",
      // totalMonthlyPayment: "",
      // delayPayments: "",
      loanMonthlyPayment: "",
      KYCNationalId: "",
      CRIFNationalId: "",
    }
  );

  const [groupLoan, setGroupLoan] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [result, setResult] = useState<HistoryType | null>(null);
  const [error, setError] = useState({
    validation: false,
    serverError: false,
  });
  const [pdfModal, setPdfModal] = useState(false);
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  const { lang } = useContext(LanguageContext);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorUploadFile, setErrorUploadFile] = useState(false);
  const [textErrorUpload, setTextErrorUpload] = useState(true);
  const [successUploadFile, setSuccessUploadFile] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [disable] = useState(true);
  const [modalLicense, setModalLicense] = useState(false);
  const [selectedFileStatus, setSelectedFileStatus] = useState(true);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [selectedGroupLoan, setSelectedGroupLoan] = useState(0);
  const [allResult, setAllResult] = useState<any>({});

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData && authData.token) {
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status !== 200) {
          navigate("/login");
          return;
        }
      });
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const find = dataGroupLoan.groupLoan.find(
      (item) => item.id === selectedGroupLoan
    );

    if (!find) {
      setSelectedGroupLoan(dataGroupLoan.groupLoan[0].id);
    }
  }, [dataGroupLoan.groupLoan]);

  useEffect(() => {
    setData({
      ...data!,
      dbr: calculateDBR(
        data?.totalIncome,
        data?.loanMonthlyPayment,
        data?.totalMonthlyPayment
      ),
    });
  }, [data?.totalMonthlyPayment, data?.totalIncome, data?.loanMonthlyPayment]);

  useEffect(() => {
    if (groupLoan) {
      setAllResult(resultGroupLoan);
    } else {
      setAllResult(result);
    }
  }, [result, resultGroupLoan]);

  useEffect(() => {
    if (groupLoan) {
      const find = dataGroupLoan.groupLoan.find(
        (item) => item.id === selectedGroupLoan
      );
      if (find) {
        setDataGroupLoan((prevData) => {
          const updatedGroupLoan = prevData.groupLoan.map((loan) => {
            if (loan.id === find.id) {
              return {
                ...loan,
                dbr: calculateDBR(
                  loan.totalIncome,
                  loan.loanMonthlyPayment,
                  loan.totalMonthlyPayment
                ),
              };
            }
            return loan;
          });
          return {
            ...prevData,
            groupLoan: updatedGroupLoan,
          };
        });
      } else {
        return;
      }
    } else return;
  }, [
    groupLoan,
    selectedGroupLoan,
    dataGroupLoan.groupLoan.find((item) => item.id === selectedGroupLoan) &&
      dataGroupLoan.groupLoan.find((item) => item.id === selectedGroupLoan)!
        .totalIncome,
    dataGroupLoan.groupLoan.find((item) => item.id === selectedGroupLoan) &&
      dataGroupLoan.groupLoan.find((item) => item.id === selectedGroupLoan)!
        .totalMonthlyPayment,
    dataGroupLoan.groupLoan.find((item) => item.id === selectedGroupLoan) &&
      dataGroupLoan.groupLoan.find((item) => item.id === selectedGroupLoan)!
        .loanMonthlyPayment,
  ]);

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------

  function calculateDBR(
    totalIncome?: string,
    loanMonthlyPayment?: string,
    totalMonthlyPayment?: string
  ): string {
    if (
      Number(totalIncome) &&
      Number(totalMonthlyPayment) &&
      Number(loanMonthlyPayment)
    ) {
      if (
        totalIncome === "1" ||
        totalIncome === "" ||
        totalMonthlyPayment === "" ||
        loanMonthlyPayment === ""
      ) {
        return "";
      } else {
        const res =
          ((Number(totalMonthlyPayment) + Number(loanMonthlyPayment)) /
            Number(totalIncome)) *
          100;

        return res.toFixed(2).toString() + "%";
      }
    } else if (Number(totalIncome) && Number(loanMonthlyPayment)) {
      if (
        totalIncome === "1" ||
        totalIncome === "" ||
        loanMonthlyPayment === ""
      ) {
        return "";
      } else {
        return (
          String(
            ((Number(loanMonthlyPayment) / Number(totalIncome)) * 100).toFixed(
              2
            )
          ) + "%"
        );
      }
    } else {
      return "";
    }
  }

  function validation() {
    const objectError = {
      KYCNationalId:
        data?.kyc_national_id.length !== 10
          ? lang === "en"
            ? "KY C_National_Id must be 10 characters in length."
            : "يجب أن يتكون طول KY C_National_Id من 10 أحرف."
          : "",
      CRIFNationalId:
        data?.crif_national_id.length !== 10
          ? lang === "en"
            ? "CRIF NationalId must be 10 characters in length."
            : "يجب أن يتكون طول CRIF NationalId من 10 أحرف."
          : "",
      interestRateMonthly:
        Number(data?.interestRateMonthly) < 0
          ? lang === "en"
            ? "Value must be greater than or equal to 0"
            : "يجب أن تكون القيمة أكبر من أو تساوي 0"
          : "",
      loanDuration:
        Number(data?.loanDuration) > 128
          ? lang === "en"
            ? "Value must be less than or equal to 128"
            : "يجب أن تكون القيمة أقل من أو تساوي 128"
          : Number(data?.loanDuration) < 6
          ? lang === "en"
            ? "Value must be greater than or equal to 6"
            : "يجب أن تكون القيمة أكبر من أو تساوي 6"
          : "",
      totalIncome:
        Number(data?.totalIncome) > 10000
          ? lang === "en"
            ? "Value must be less than or equal to 10000"
            : "يجب أن تكون القيمة أقل من أو تساوي 10000"
          : Number(data?.totalIncome) < 0
          ? lang === "en"
            ? "Value must be greater than or equal to 0"
            : "يجب أن تكون القيمة أكبر من أو تساوي 0"
          : "",
      age:
        Number(data?.age) > 100
          ? lang === "en"
            ? "Value must be less than or equal to 100"
            : "يجب أن تكون القيمة أقل من أو تساوي 100"
          : Number(data?.age) < 1
          ? lang === "en"
            ? "Value must be greater than or equal to 1"
            : "يجب أن تكون القيمة أكبر من أو تساوي 1"
          : "",
      loanAmount:
        Number(data?.loanAmount) > 20000
          ? lang === "en"
            ? "Value must be less than or equal to 20000"
            : "يجب أن تكون القيمة أقل من أو تساوي 20000"
          : Number(data?.loanAmount) < 100
          ? lang === "en"
            ? "Value must be greater than or equal to 100"
            : "يجب أن تكون القيمة أكبر من أو تساوي 100"
          : "",
      // inflationRate:
      //   Number(data?.inflationRate) > 6
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 6"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 6"
      //     : Number(data?.inflationRate) < 1
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 1"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 1"
      //     : "",
      // creditScore:
      //   Number(Number(data?.creditScore.replace(/,/g, "."))) > 900
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 900"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 900"
      //     : Number(Number(data?.creditScore.replace(/,/g, "."))) < 0
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 0"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 0"
      //     : "",
      // dependants:
      //   Number(Number(data?.dependants.replace(/,/g, "."))) > 65
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 65"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 65"
      //     : Number(Number(data?.dependants.replace(/,/g, "."))) < 0
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 0"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 0"
      //     : "",
      // numberOfLoans:
      //   Number(data?.numberOfLoans) > 35
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 35"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 35"
      //     : Number(data?.numberOfLoans) < 0
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 0"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 0"
      //     : "",
      // numberOfActiveLoans:
      //   Number(data?.numberOfActiveLoans) > 7
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 7"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 7"
      //     : Number(data?.numberOfActiveLoans) < 0
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 0"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 0"
      //     : "",
      // totalMonthlyPayment:
      //   Number(data?.totalMonthlyPayment) > 1800
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 1800"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 1800"
      //     : Number(data?.totalMonthlyPayment) < 0
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 0"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 0"
      //     : "",
      // delayPayments:
      //   Number(data?.delayPayments) > 57
      //     ? lang === "en"
      //       ? "Value must be less than or equal to 57"
      //       : "يجب أن تكون القيمة أقل من أو تساوي 57"
      //     : Number(data?.delayPayments) < 0
      //     ? lang === "en"
      //       ? "Value must be greater than or equal to 0"
      //       : "يجب أن تكون القيمة أكبر من أو تساوي 0"
      //     : "",
      loanMonthlyPayment:
        Number(data?.loanMonthlyPayment) > 100000
          ? lang === "en"
            ? "Value must be less than or equal to 100000"
            : "يجب أن تكون القيمة أقل من أو تساوي 100000"
          : Number(data?.loanMonthlyPayment) < 0
          ? lang === "en"
            ? "Value must be greater than or equal to 0"
            : "يجب أن تكون القيمة أكبر من أو تساوي 0"
          : "",
    };
    setErrorValidation(objectError);

    function findValue(obj: any, targetValue: string) {
      for (const key in obj) {
        if (obj[key] !== targetValue) {
          return key;
        }
      }
      return null;
    }

    const findError = findValue(objectError, "");

    if (findError) {
      const elements = document.querySelector(`[name="${findError}"]`);
      if (elements) {
        elements.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }
    //
    // if (
    //   objectError.CRIFNationalId !== "" ||
    //   objectError.creditScore !== "" ||
    //   objectError.dependants !== "" ||
    //   objectError.numberOfActiveLoans !== "" ||
    //   objectError.numberOfLoans !== "" ||
    //   objectError.totalMonthlyPayment !== "" ||
    //   objectError.delayPayments !== ""
    // ) {
    //   setErrorUploadFile(true);
    // }

    return (
      objectError.loanDuration === "" &&
      objectError.totalIncome === "" &&
      objectError.interestRateMonthly === "" &&
      objectError.loanAmount === "" &&
      objectError.age === "" &&
      // objectError.creditScore === "" &&
      // objectError.dependants === "" &&
      // objectError.numberOfActiveLoans === "" &&
      // objectError.numberOfLoans === "" &&
      // objectError.totalMonthlyPayment === "" &&
      // objectError.delayPayments === "" &&
      objectError.CRIFNationalId === "" &&
      objectError.KYCNationalId === ""
    );
  }

  function validationGroupLoanfunction() {
    const objectErrorGroupLoan = {
      groupLoan: dataGroupLoan.groupLoan.map((loan) => ({
        id: loan.id,
        crif_national_id:
          loan.crif_national_id.length !== 10
            ? lang === "en"
              ? "CRIF NationalId must be 10 characters in length."
              : "يجب أن يتكون طول CRIF NationalId من 10 أحرف."
            : "",
        kyc_national_id:
          loan.kyc_national_id.length !== 10
            ? lang === "en"
              ? "KY C_National_Id must be 10 characters in length."
              : "يجب أن يتكون طول KY C_National_Id من 10 أحرف."
            : "",
        age:
          Number(loan.age) > 100
            ? lang === "en"
              ? "Value must be less than or equal to 100"
              : "يجب أن تكون القيمة أقل من أو تساوي 100"
            : Number(loan.age) < 1
            ? lang === "en"
              ? "Value must be greater than or equal to 1"
              : "يجب أن تكون القيمة أكبر من أو تساوي 1"
            : "",
        totalIncome:
          Number(loan.totalIncome) > 10000
            ? lang === "en"
              ? "Value must be less than or equal to 10000"
              : "يجب أن تكون القيمة أقل من أو تساوي 10000"
            : Number(loan.totalIncome) < 0
            ? lang === "en"
              ? "Value must be greater than or equal to 0"
              : "يجب أن تكون القيمة أكبر من أو تساوي 0"
            : "",
        loanAmount:
          Number(loan.loanAmount) > 1000
            ? lang === "en"
              ? "Loan amount exceeding the limit of 1000 JOD"
              : "يجب أن تكون القيمة أقل من أو تساوي 1000"
            : Number(loan.loanAmount) < 300
            ? lang === "en"
              ? "Value must be greater than or equal to 300"
              : "يجب أن تكون القيمة أكبر من أو تساوي 300"
            : "",

        loanMonthlyPayment:
          Number(loan.loanMonthlyPayment) > 1000
            ? lang === "en"
              ? "Value must be less than or equal to 1000"
              : "يجب أن تكون القيمة أقل من أو تساوي 100000"
            : Number(loan.loanMonthlyPayment) < 0
            ? lang === "en"
              ? "Value must be greater than or equal to 0"
              : "يجب أن تكون القيمة أكبر من أو تساوي 0"
            : "",
        familyStatus: !loan.familyStatus ? "Marital status is requried." : "",
        gender: !loan.gender ? "Gender is required." : "",
      })),

      branch:
        dataGroupLoan.branch.length === 0
          ? lang === "en"
            ? "Branch is required."
            : "الفرع مطلوب."
          : "",
      inflationRate:
        Number(dataGroupLoan.inflationRate) > 100
          ? lang === "en"
            ? "Value must be less than or equal to 100"
            : "يجب أن تكون القيمة أقل من أو تساوي 100"
          : Number(dataGroupLoan.inflationRate) < 0
          ? lang === "en"
            ? "Value must be greater than or equal to 0"
            : "يجب أن تكون القيمة أكبر من أو تساوي 0"
          : "",
      interestRateMonthly:
        Number(dataGroupLoan.interestRateMonthly) < 0
          ? lang === "en"
            ? "Value must be greater than or equal to 0"
            : "يجب أن تكون القيمة أكبر من أو تساوي 0"
          : "",
      loanDuration:
        Number(dataGroupLoan.loanDuration) > 64
          ? lang === "en"
            ? "Value must be less than or equal to 64"
            : "يجب أن تكون القيمة أقل من أو تساوي 64"
          : Number(dataGroupLoan.loanDuration) < 6
          ? lang === "en"
            ? "Value must be greater than or equal to 6"
            : "يجب أن تكون القيمة أكبر من أو تساوي 6"
          : "",
      ocR_status: "",
      coBorrower: "",
    };

    setValidationGroupLoan(objectErrorGroupLoan as any);

    function findValue(obj: any, targetValue: string) {
      for (const key in obj) {
        if (key === "id" || key === "groupLoan") continue;
        if (obj[key] !== targetValue) {
          return key;
        }
      }
      return null;
    }
    function findErrorGroupLoan(obj: any) {
      let getId = 0;
      let keyForReturn = "";
      for (const iterator of obj.groupLoan) {
        for (const key in iterator) {
          if (key === "id") continue;
          if ((iterator as any)[key] !== "") {
            getId = iterator.id;
            keyForReturn = key;
          }
        }
      }

      getId && setSelectedGroupLoan(getId);
      if (keyForReturn) {
        return keyForReturn;
      }
      return null;
    }

    const findError = findValue(objectErrorGroupLoan, "");
    const findErrorGroup = findErrorGroupLoan(objectErrorGroupLoan);

    if (findError) {
      const elements = document.querySelector(`[name="${findError}"]`);
      if (elements) {
        elements.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }

    // Return true if there are no errors, false otherwise
    return findError === null && findErrorGroup === null;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (groupLoan) {
      let findError: null | number = null;
      //selected File Status
      let cntSelectedFileStatus = 0;
      for (const groupLoan of dataGroupLoan.groupLoan) {
        if (!groupLoan.selectedFile) {
          findError = groupLoan.id;
          cntSelectedFileStatus += 1;
          setDataGroupLoan((prevData) => {
            const updatedGroupLoan = prevData.groupLoan.map((loan) => {
              if (loan.id === groupLoan.id) {
                return {
                  ...loan,
                  selectedFileStatus: false,
                };
              }
              return loan;
            });
            return {
              ...prevData,
              groupLoan: updatedGroupLoan,
            };
          });
        }
      }

      findError && setSelectedGroupLoan(findError);

      if (cntSelectedFileStatus) {
        return;
      }

      let cntUploadStatus = 0;
      for (const groupLoan of dataGroupLoan.groupLoan) {
        if (!groupLoan.uploadStatus) {
          cntUploadStatus += 1;
          findError = groupLoan.id;
          setDataGroupLoan((prevData) => {
            const updatedGroupLoan = prevData.groupLoan.map((loan) => {
              if (loan.id === groupLoan.id) {
                return {
                  ...loan,
                  textErrorUpload: false,
                };
              }
              return loan;
            });
            return {
              ...prevData,
              groupLoan: updatedGroupLoan,
            };
          });
        }
      }

      findError && setSelectedGroupLoan(findError);

      if (cntUploadStatus) {
        return;
      }

      const validationError = validationGroupLoanfunction();

      if (dataGroupLoan && validationError) {
        const loanModels: any = [];

        for (const loanModel of dataGroupLoan.groupLoan) {
          const dbr = Number(loanModel.dbr.replace("%", ""))
            ? Number(loanModel.dbr.replace("%", ""))
            : 0;
          const newObj = {
            familyStatus: loanModel.familyStatus,
            totalIncome: Number(loanModel.totalIncome),
            gender: loanModel.gender === "male",
            loanAmount: Number(loanModel.loanAmount),
            age: Number(loanModel.age),
            monthlyPayment: Number(loanModel.loanMonthlyPayment),
            dependants:
              loanModel.dependants === "-"
                ? 0
                : Number(loanModel.dependants)
                ? Number(loanModel.dependants.replace(/,/g, "."))
                : 0,
            creditScore:
              loanModel.creditScore === "-"
                ? 100
                : Number(loanModel.creditScore)
                ? Number(loanModel.creditScore.replace(/,/g, "."))
                : 0,
            numberOfLoans:
              loanModel.numberOfLoans === "-"
                ? 0
                : Number(parseInt(loanModel.numberOfLoans))
                ? Number(parseInt(loanModel.numberOfLoans))
                : 0,
            numberOfActiveLoans:
              loanModel.numberOfActiveLoans === "-"
                ? 0
                : Number(parseInt(loanModel.numberOfActiveLoans))
                ? Number(parseInt(loanModel.numberOfActiveLoans))
                : 0,
            totalMonthlyPayment:
              loanModel.totalMonthlyPayment === "-"
                ? 0
                : Number(parseInt(loanModel.totalMonthlyPayment))
                ? Number(parseInt(loanModel.totalMonthlyPayment))
                : 0,
            maxDelayDays:
              loanModel.maxDelayedDays === "-"
                ? 0
                : Number(
                    parseInt(loanModel.maxDelayedDays) > 1000
                      ? 1000
                      : parseInt(loanModel.maxDelayedDays)
                  )
                ? Number(
                    parseInt(loanModel.maxDelayedDays) > 1000
                      ? 1000
                      : parseInt(loanModel.maxDelayedDays)
                  )
                : 0,
            delayPayments:
              loanModel.delayPayments === "-"
                ? 0
                : Number(parseInt(loanModel.delayPayments))
                ? Number(parseInt(loanModel.delayPayments))
                : 0,
            kyC_National_Id: loanModel.kyc_national_id,
            criF_National_Id: loanModel.crif_national_id,
            dbr: dbr > 100 ? 100 : dbr < 0 ? 0 : dbr,
            obligations:
              loanModel.numberOfObligations === "-"
                ? 0
                : Number(parseInt(loanModel.numberOfObligations))
                ? Number(parseInt(loanModel.numberOfObligations))
                : 0,
            ocR_status: Number(parseInt(loanModel.ocR_status)),
          };
          loanModels.push(newObj);
        }

        const dataForReady = {
          id: Math.floor(Math.random() * 100000) + 1,
          interestRate: Number(
            dataGroupLoan.interestRateMonthly.replace(/,/g, ".")
          ),
          inflationRate: Number(dataGroupLoan.inflationRate.replace(/,/g, ".")),
          loanDuration: Number(dataGroupLoan.loanDuration),
          branch: dataGroupLoan.branch,
          loanModels: loanModels,
        };

        setLoading(true);

        const res = await sendPostRequest(
          "/BulkScore/PredictBulk",
          dataForReady
        );

        setLoading(false);

        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status === 401) {
          navigate("/login");
          return;
        }
        if (res.status === 400 || res.status === 500) {
          setError((prev) => ({
            ...prev,
            validation: true,
          }));
          setModalActive(true);
          setTimeout(() => {
            setModalActive(false);
            setError({ serverError: false, validation: false });
          }, 3000);
        } else if (res.status === 403) {
          setModalLicense(true);
        } else if (res.status === 200) {
          const json: any = await res.json();
          setResultHistoryLoan(json);
          if (
            !json.ageVerificationStatus ||
            !json.dbrVerificationStatus ||
            !json.obligationsVerificationStatus ||
            !json.maxDelayDaysStatus ||
            !json.nationalIdsVerificationStatus
          ) {
            setModalError(true);
            setDataGroupLoan({
              groupLoan: [
                {
                  id: 1,
                  crif_national_id: "",
                  kyc_national_id: "",
                  age: "",
                  familyStatus: "",
                  gender: "",
                  creditScore: "",
                  dependants: "",
                  numberOfLoans: "",
                  numberOfActiveLoans: "",
                  totalMonthlyPayment: "",
                  delayPayments: "",
                  numberOfObligations: "",
                  dbr: "",
                  totalIncome: "",
                  selectedFile: null,
                  loadingUpload: false,
                  selectedFileStatus: true,
                  textErrorUpload: true,
                  errorUploadFile: false,
                  successUploadFile: false,
                  uploadFile: false,
                  uploadStatus: false,
                  ocR_status: "0",
                  loanAmount: "",
                  loanMonthlyPayment: "",
                  maxDelayedDays: "",
                },
              ],
              branch: "",
              inflationRate: "",
              interestRateMonthly: "",
              loanDuration: "",
              ocR_status: "",
              coBorrower: "",
            });

            return;
          }
          setModalActive(true);
        }
        setData(null);
        setErrorUploadFile(false);
        setSuccessUploadFile(false);
      }
    } else {
      if (!selectedFile) {
        setSelectedFileStatus(false);
        return;
      }

      if (!uploadStatus) {
        setTextErrorUpload(false);
        return;
      }

      const validationError = validation();

      // if (errorUploadFile) {
      //   return;
      // }

      if (data && validationError) {
        setPdfModal(false);
        const dbr = Number(data.dbr.replace("%", ""))
          ? Number(data.dbr.replace("%", ""))
          : 0;
        const newData = {
          ...data,
          kyC_National_Id: data.kyc_national_id,
          criF_National_Id: data.crif_national_id,
          age: Number(data.age),
          inflationRate: 1,
          creditScore:
            data.creditScore === "-"
              ? 100
              : Number(data.creditScore)
              ? Number(data.creditScore.replace(/,/g, "."))
              : 0,
          dependants:
            data.dependants === "-"
              ? 0
              : Number(data.dependants)
              ? Number(data.dependants.replace(/,/g, "."))
              : 0,
          loanAmount: Number(data.loanAmount),
          loanDuration: Number(data.loanDuration),
          totalIncome: Number(data.totalIncome.replace(/,/g, ".")),
          numberOfLoans:
            data.numberOfLoans === "-"
              ? 0
              : Number(parseInt(data.numberOfLoans))
              ? Number(parseInt(data.numberOfLoans))
              : 0,
          maxDelayDays:
            data.maxDelayedDays === "-"
              ? 0
              : Number(
                  parseInt(data.maxDelayedDays) > 1000
                    ? 1000
                    : parseInt(data.maxDelayedDays)
                )
              ? Number(
                  parseInt(data.maxDelayedDays) > 1000
                    ? 1000
                    : parseInt(data.maxDelayedDays)
                )
              : 0,
          numberOfActiveLoans:
            data.numberOfActiveLoans === "-"
              ? 0
              : Number(parseInt(data.numberOfActiveLoans))
              ? Number(parseInt(data.numberOfActiveLoans))
              : 0,
          totalMonthlyPayment:
            data.totalMonthlyPayment === "-"
              ? 0
              : Number(parseInt(data.totalMonthlyPayment))
              ? Number(parseInt(data.totalMonthlyPayment))
              : 0,
          delayPayments:
            data.delayPayments === "-"
              ? 0
              : Number(parseInt(data.delayPayments))
              ? Number(parseInt(data.delayPayments))
              : 0,
          gender: data.gender === "male",
          interestRateMonthly: Number(data.interestRateMonthly) / 100,
          obligations:
            data.numberOfObligations === "-"
              ? 0
              : Number(parseInt(data.numberOfObligations))
              ? Number(parseInt(data.numberOfObligations))
              : 0,
          monthlyPayment: Number(data.loanMonthlyPayment.replace(/,/g, ".")),
          dbr: dbr > 100 ? 100 : dbr < 0 ? 0 : dbr,
          ocR_status: Number(parseInt(data.ocR_status)),
          coBorrower: 0,
        };

        delete (newData as any).kyc_national_id;
        delete (newData as any).crif_national_id;
        delete (newData as any).numberOfObligations;

        setLoading(true);
        const res = await sendPostRequest("/Score/Predict", newData);
        setLoading(false);
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status === 401) {
          navigate("/login");
          return;
        }
        if (res.status === 400 || res.status === 500) {
          setError((prev) => ({
            ...prev,
            validation: true,
          }));
          setModalActive(true);
          setTimeout(() => {
            setModalActive(false);
            setError({ serverError: false, validation: false });
          }, 3000);
        } else if (res.status === 403) {
          setModalLicense(true);
        }
        if (res.status === 200) {
          const json: HistoryType = await res.json();
          setResult(json);
          if (
            !json.ageVerificationStatus ||
            !json.dbrVerificationStatus ||
            !json.obligationsVerificationStatus ||
            !json.maxDelayDaysStatus ||
            !json.nationalIdsVerificationStatus
          ) {
            setModalError(true);
            setData(null);
            setErrorUploadFile(false);
            setSuccessUploadFile(false);
            return;
          }
          setModalActive(true);
        }
        setData(null);
        setErrorUploadFile(false);
        setSuccessUploadFile(false);
      }
    }
  }

  function checkNumber(string: string) {
    return !!(parseInt(string) || parseInt(string) === 0);
  }

  const handleButtonSubmit = async () => {
    setLoadingUpload(true);
    const res = await sendPostRequestOCR(selectedFile);
    setUploadStatus(true);
    setTextErrorUpload(true);
    if (res) {
      if (res.status !== 200) {
        setSuccessUploadFile(false);
        setErrorUploadFile(true);
        setLoadingUpload(false);
        setSelectedFile(null);
        setData({
          ...data!,
          crif_national_id: "0000000000",
          numberOfLoans: "0",
          creditScore: "100",
          delayPayments: "0",
          totalMonthlyPayment: "0",
          numberOfActiveLoans: "0",
          dependants: "0",
          numberOfObligations: "0",
          ocR_status: "1",
          maxDelayedDays: "0",
        });

        return;
      }
      const json = await res.json();

      if (json.response_code === 1) {
        setErrorUploadFile(true);
        setSuccessUploadFile(false);
      }
      if (!data) return;
      setData({
        ...data,
        crif_national_id: json.identification_code
          ? String(json.identification_code)
          : "",
        maxDelayedDays:
          json.max_delays_days !== null
            ? checkNumber(json.max_delays_days)
              ? String(json.max_delays_days)
              : "-"
            : "-",
        numberOfLoans:
          json.number_of_loans !== null
            ? checkNumber(json.number_of_loans)
              ? String(json.number_of_loans)
              : "-"
            : "-",
        creditScore:
          json.credit_score !== null
            ? checkNumber(json.credit_score)
              ? String(json.credit_score)
              : "-"
            : "-",
        delayPayments:
          json.delay_payments !== null
            ? checkNumber(json.delay_payments)
              ? String(json.delay_payments)
              : "-"
            : "-",
        totalMonthlyPayment:
          json.total_monthly_payment !== null
            ? checkNumber(json.total_monthly_payment)
              ? String(json.total_monthly_payment)
              : "-"
            : "-",
        numberOfActiveLoans:
          json.number_of_active_loans !== null
            ? checkNumber(json.number_of_active_loans)
              ? String(json.number_of_active_loans)
              : "-"
            : "-",
        dependants:
          json.number_of_dependants !== null
            ? checkNumber(json.number_of_dependants)
              ? String(json.number_of_dependants)
              : "-"
            : "-",
        numberOfObligations:
          json.number_of_obligations !== null
            ? checkNumber(json.number_of_obligations)
              ? String(json.number_of_obligations)
              : "-"
            : "-",
        ocR_status:
          json.response_code !== null
            ? checkNumber(json.response_code)
              ? String(json.response_code)
              : "-"
            : "-",
      });
      setErrorUploadFile(false);
      if (json.response_code === 1) {
        setErrorUploadFile(true);
        setSuccessUploadFile(false);
      } else {
        setSuccessUploadFile(true);
        setErrorUploadFile(false);
      }
    } else {
      setSuccessUploadFile(false);
      setErrorUploadFile(true);
      setLoadingUpload(false);
      setSelectedFile(null);
      return;
    }
    setLoadingUpload(false);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFileStatus(true);
    setUploadStatus(false);
    const file = event.target.files?.[0];
    setSelectedFile(file!);
  };

  const handleGroupLoan = (value: boolean) => {
    setGroupLoan(value);
  };

  const handleButtonSubmitGroupLoan = async () => {
    setDataGroupLoan((prevData) => {
      const updatedGroupLoan = prevData.groupLoan.map((loan) => {
        if (loan.id === selectedGroupLoan) {
          return {
            ...loan,
            loadingUpload: true,
          };
        }
        return loan;
      });
      return {
        ...prevData,
        groupLoan: updatedGroupLoan,
      };
    });

    const res = await sendPostRequestOCR(
      dataGroupLoan.groupLoan[selectedGroupLoan - 1].selectedFile
    );

    setDataGroupLoan((prevData) => {
      const find = prevData.groupLoan.find(
        (loan) => loan.id === selectedGroupLoan
      );

      const updatedGroupLoan = prevData.groupLoan.map((loan) => {
        if (loan.id === (find && find.id)) {
          return {
            ...loan,
            uploadStatus: true,
            textErrorUpload: true,
          };
        }
        return loan;
      });
      return {
        ...prevData,
        groupLoan: updatedGroupLoan,
      };
    });

    if (res) {
      if (res.status !== 200) {
        setDataGroupLoan((prevData) => {
          const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
            if (index === selectedGroupLoan - 1) {
              return {
                ...loan,
                successUploadFile: false,
                errorUploadFile: true,
                loadingUpload: false,
                selectedFile: null,
                crif_national_id: "0000000000",
                numberOfLoans: "0",
                creditScore: "100",
                delayPayments: "0",
                totalMonthlyPayment: "0",
                numberOfActiveLoans: "0",
                dependants: "0",
                numberOfObligations: "0",
                ocR_status: "1",
                maxDelayedDays: "0",
              };
            }
            return loan;
          });
          return {
            ...prevData,
            groupLoan: updatedGroupLoan,
          };
        });
        return;
      }
      const json = (await res.json()).Jordan_NEW;

      if (json.response_code === 1) {
        setDataGroupLoan((prevData) => {
          const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
            if (index === selectedGroupLoan - 1) {
              return {
                ...loan,
                successUploadFile: false,
                errorUploadFile: true,
              };
            }
            return loan;
          });
          return {
            ...prevData,
            groupLoan: updatedGroupLoan,
          };
        });
      }

      setDataGroupLoan((prevData) => {
        const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
          if (index === selectedGroupLoan - 1) {
            return {
              ...loan,
              crif_national_id: json.identification_code
                ? String(json.identification_code)
                : "",
              numberOfLoans:
                json.number_of_loans !== null
                  ? checkNumber(json.number_of_loans)
                    ? String(json.number_of_loans)
                    : "-"
                  : "-",
              creditScore:
                json.credit_score !== null
                  ? checkNumber(json.credit_score)
                    ? String(json.credit_score)
                    : "-"
                  : "-",
              delayPayments:
                json.delay_payments !== null
                  ? checkNumber(json.delay_payments)
                    ? String(json.delay_payments)
                    : "-"
                  : "-",
              maxDelayedDays:
                json.max_delays_days !== null
                  ? checkNumber(json.max_delays_days)
                    ? String(json.max_delays_days)
                    : "-"
                  : "-",
              totalMonthlyPayment:
                json.total_monthly_payment !== null
                  ? checkNumber(json.total_monthly_payment)
                    ? String(json.total_monthly_payment)
                    : "-"
                  : "-",
              numberOfActiveLoans:
                json.number_of_active_loans !== null
                  ? checkNumber(json.number_of_active_loans)
                    ? String(json.number_of_active_loans)
                    : "-"
                  : "-",
              dependants:
                json.number_of_dependants !== null
                  ? checkNumber(json.number_of_dependants)
                    ? String(json.number_of_dependants)
                    : "-"
                  : "-",
              numberOfObligations:
                json.number_of_obligations !== null
                  ? checkNumber(json.number_of_obligations)
                    ? String(json.number_of_obligations)
                    : "-"
                  : "-",
              ocR_status:
                json.response_code !== null
                  ? checkNumber(json.response_code)
                    ? String(json.response_code)
                    : "-"
                  : "-",
            };
          }
          return loan;
        });
        return {
          ...prevData,
          groupLoan: updatedGroupLoan,
        };
      });
      if (json.response_code === 1) {
        setDataGroupLoan((prevData) => {
          const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
            if (index === selectedGroupLoan - 1) {
              return {
                ...loan,
                successUploadFile: false,
                errorUploadFile: true,
              };
            }
            return loan;
          });
          return {
            ...prevData,
            groupLoan: updatedGroupLoan,
          };
        });
      } else {
        setDataGroupLoan((prevData) => {
          const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
            if (index === selectedGroupLoan - 1) {
              return {
                ...loan,
                successUploadFile: true,
                errorUploadFile: false,
              };
            }
            return loan;
          });
          return {
            ...prevData,
            groupLoan: updatedGroupLoan,
          };
        });
      }
    } else {
      setDataGroupLoan((prevData) => {
        const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
          if (index === selectedGroupLoan - 1) {
            return {
              ...loan,
              successUploadFile: false,
              errorUploadFile: true,
              loadingUpload: true,
              selectedFile: null,
            };
          }
          return loan;
        });
        return {
          ...prevData,
          groupLoan: updatedGroupLoan,
        };
      });
      return;
    }
    setDataGroupLoan((prevData) => {
      const updatedGroupLoan = prevData.groupLoan.map((loan, index) => {
        if (index === selectedGroupLoan - 1) {
          return {
            ...loan,
            loadingUpload: false,
          };
        }
        return loan;
      });
      return {
        ...prevData,
        groupLoan: updatedGroupLoan,
      };
    });
  };

  const handleFileChangeGroupLoan = (event: ChangeEvent<HTMLInputElement>) => {
    setDataGroupLoan((prevData) => {
      const find = prevData.groupLoan.find(
        (loan) => loan.id === selectedGroupLoan
      );
      const updatedGroupLoan = prevData.groupLoan.map((loan) => {
        if (loan.id === (find && find.id)) {
          return {
            ...loan,
            selectedFileStatus: true,
            uploadStatus: false,
            selectedFile: event.target.files?.[0]!,
          };
        }
        return loan;
      });
      return {
        ...prevData,
        groupLoan: updatedGroupLoan,
      };
    });
  };

  function addClient() {
    const newGroupLoanItem = {
      id: dataGroupLoan.groupLoan[dataGroupLoan.groupLoan.length - 1].id + 1,
      crif_national_id: "",
      kyc_national_id: "",
      age: "",
      familyStatus: "",
      gender: "",
      creditScore: "",
      dependants: "",
      numberOfLoans: "",
      numberOfActiveLoans: "",
      totalMonthlyPayment: "",
      delayPayments: "",
      numberOfObligations: "",
      dbr: "",
      totalIncome: "",
      selectedFile: null,
      loadingUpload: false,
      selectedFileStatus: true,
      textErrorUpload: true,
      errorUploadFile: false,
      successUploadFile: false,
      uploadFile: true,
      uploadStatus: false,
      ocR_status: "",
      loanAmount: "",
      loanMonthlyPayment: "",
      maxDelayedDays: "",
    };

    setDataGroupLoan((prevData) => ({
      ...prevData,
      groupLoan: [...prevData.groupLoan, newGroupLoanItem],
    }));
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.prediction_container}
      style={
        lang === "ar"
          ? {
              direction: "rtl",
            }
          : {}
      }
    >
      <div className={styles.prediction_block}>
        <h2 className={styles.header_text}>{text.get_scoring_result[lang]}</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.group_loan_switch}>
            <Switcher
              label={"Group loan"}
              onSwitchChange={(checked) => handleGroupLoan(checked)}
            />
          </div>

          {!groupLoan ? (
            <div className={styles.form_block}>
              {/* <h3 className={styles.data_client_container}>
              {text.client_and_credit_data[lang]}
            </h3> */}

              <div className={styles.section}>
                <h3>Loan info</h3>
                <div className={styles.data_client_block}>
                  {/*<label className={styles.label} htmlFor={"2"}>*/}
                  {/*  <span className={styles.span_label}>*/}
                  {/*    {text.prior_loans[lang]}*/}
                  {/*  </span>*/}
                  {/*  <Input*/}
                  {/*    mode={"primary"}*/}
                  {/*    type={"text"}*/}
                  {/*    className={styles.input_text}*/}
                  {/*    name="priorLoans"*/}
                  {/*    id={"2"}*/}
                  {/*    autofocus={true}*/}
                  {/*    onChange={(e) => {*/}
                  {/*      const regex = /^[0-9]*$/;*/}
                  {/*      const newInputValue = e.target.value;*/}
                  {/*      if (*/}
                  {/*        newInputValue === "" ||*/}
                  {/*        (regex.test(newInputValue) && newInputValue.length < 4)*/}
                  {/*      ) {*/}
                  {/*        setData({*/}
                  {/*          ...data!,*/}
                  {/*          priorLoans:*/}
                  {/*            newInputValue !== ""*/}
                  {/*              ? String(Number(newInputValue))*/}
                  {/*              : newInputValue,*/}
                  {/*        });*/}
                  {/*      }*/}
                  {/*    }}*/}
                  {/*    value={data?.priorLoans ? data.priorLoans : ""}*/}
                  {/*    required*/}
                  {/*  />*/}
                  {/*  <p className={styles.error_validation}>*/}
                  {/*    {errorValidation?.priorLoans}*/}
                  {/*  </p>*/}
                  {/*</label>*/}{" "}
                  {/*<label className={styles.label} htmlFor={"2"}>*/}
                  {/*  <span className={styles.span_label}>Co-borrower</span>*/}
                  {/*  <select*/}
                  {/*    className={styles.input_select}*/}
                  {/*    name="coBorrower"*/}
                  {/*    onChange={(e) => {*/}
                  {/*      setData({*/}
                  {/*        ...data!,*/}
                  {/*        coBorrower: e.target.value,*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*    id={"2"}*/}
                  {/*    value={data?.coBorrower ? data.coBorrower : ""}*/}
                  {/*    required*/}
                  {/*  >*/}
                  {/*    <option value="">{text.choose[lang]}</option>*/}
                  {/*    <option value="1">{text.yes[lang]}</option>*/}
                  {/*    <option value="0">No</option>*/}
                  {/*  </select>*/}
                  {/*  <p className={styles.error_validation}></p>*/}
                  {/*</label>*/}
                  <label htmlFor={"4"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.interest_rate[lang]} (%)
                    </span>
                    <Input
                      mode={"primary"}
                      name="interestRateMonthly"
                      id={"4"}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 5)
                        ) {
                          setData({
                            ...data!,
                            interestRateMonthly:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.interestRateMonthly
                          ? data.interestRateMonthly
                          : ""
                      }
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation?.interestRateMonthly}
                    </p>
                  </label>
                </div>
                <div className={styles.data_client_block}>
                  <label htmlFor={"5"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.duration[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="loanDuration"
                      id={"5"}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            loanDuration:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.loanDuration ? data.loanDuration : ""}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation?.loanDuration}
                    </p>
                  </label>
                  <label htmlFor={"12"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.branch[lang]}
                    </span>
                    <select
                      className={styles.input_select}
                      name="branch"
                      onChange={(e) => {
                        setData({
                          ...data!,
                          branch: e.target.value,
                        });
                      }}
                      id={"12"}
                      value={data?.branch ? data.branch : ""}
                      required
                    >
                      <option value="">{text.choose[lang]}</option>
                      <option value="hussein">{text.hussein[lang]}</option>
                      <option value="salt_branch">
                        {text.salt_branch[lang]}
                      </option>
                      <option value="hitteen">{text.hitteen[lang]}</option>
                      <option value="sahab">{text.sahab[lang]}</option>
                      <option value="zarqa">{text.zarqa[lang]}</option>
                      <option value="rsaifeh_branch">Rsaifeh Branch</option>
                      <option value="koura_branch">
                        {text.koura_branch[lang]}
                      </option>
                      <option value="irbid">{text.irbid[lang]}</option>
                      <option value="dayr_alla">{text.dayr_alla[lang]}</option>
                      <option value="madaba">{text.madaba[lang]}</option>
                      <option value="n_shouna">{text.n_shouna[lang]}</option>
                      <option value="beni_kenana_branch">
                        {text.beni_kenana_branch[lang]}
                      </option>
                      <option value="jerash">{text.jerash[lang]}</option>
                      <option value="marka_branch">
                        {text.marka_branch[lang]}
                      </option>
                      <option value="al_karak_branch">
                        {text.al_karak_branch[lang]}
                      </option>
                      <option value="mafraq">{text.Mafraq[lang]}</option>
                      <option value="aqaba">{text.Aqaba[lang]}</option>
                      <option value="zyzia">Zyzia</option>
                      <option value="other">Other</option>
                    </select>
                    <p className={styles.error_validation}></p>
                  </label>
                </div>
                <div className={styles.data_client_block}>
                  <label htmlFor={"19"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.amount_of_loan[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      name="loanAmount"
                      id={"19"}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 5)
                        ) {
                          setData({
                            ...data!,
                            loanAmount:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.loanAmount ? data.loanAmount : ""}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation?.loanAmount}
                    </p>
                  </label>

                  <label htmlFor={"monthly_payment"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.loan_monthly_payment[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="id"
                      id={"monthly_payment"}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        const regex = /^[0-9,]*$/;
                        const regex2 = /^([^,]*,){0,1}[^,]*$/;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            regex2.test(newInputValue) &&
                            newInputValue.length < 8)
                        ) {
                          setData({
                            ...data!,
                            loanMonthlyPayment: newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.loanMonthlyPayment ? data.loanMonthlyPayment : ""
                      }
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.loanMonthlyPayment}
                    </p>
                  </label>
                </div>
                <div className={styles.data_client_block}></div>
              </div>
              <div className={styles.section}>
                <h3>KYC</h3>
                <div className={styles.data_client_block}>
                  <label htmlFor={"KYCNationalId"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.national_id[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="KYCNationalId"
                      id={"KYCNationalId"}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        const regex = /^[a-zA-Z0-9]+$/;

                        if (
                          newInputValue === "" ||
                          (newInputValue.length < 11 &&
                            regex.test(newInputValue))
                        ) {
                          setData({
                            ...data!,
                            kyc_national_id:
                              newInputValue !== ""
                                ? String(newInputValue)
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.kyc_national_id ? data.kyc_national_id : ""}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.KYCNationalId}
                    </p>
                  </label>
                  <label htmlFor={"6"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.total_income[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="totalIncome"
                      required
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 6)
                        ) {
                          setData({
                            ...data!,
                            totalIncome:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.totalIncome ? data.totalIncome : ""}
                      id={"6"}
                    />
                    <p className={styles.error_validation}>
                      {errorValidation?.totalIncome}
                    </p>
                  </label>
                </div>
                <div className={styles.data_client_block}>
                  <label htmlFor={"9"} className={styles.label}>
                    <span className={styles.span_label}>{text.age[lang]}</span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="age"
                      id={"9"}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            age:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.age ? data.age : ""}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation?.age}
                    </p>
                  </label>
                  <label htmlFor={"10"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.family_status[lang]}
                    </span>
                    <select
                      className={styles.input_select}
                      name="familyStatus"
                      onChange={(e) => {
                        setData({
                          ...data!,
                          familyStatus: e.target.value,
                        });
                      }}
                      value={data?.familyStatus ? data?.familyStatus : ""}
                      id={"10"}
                      required
                    >
                      <option value="">{text.choose[lang]}</option>
                      <option value="married">{text.married[lang]}</option>
                      <option value="divorced">{text.divorced[lang]}</option>
                      <option value="single">{text.single[lang]}</option>
                      <option value="widowed">{text.widowed[lang]}</option>
                      <option value="separated">{text.separated[lang]}</option>
                    </select>
                    <p className={styles.error_validation}></p>
                  </label>
                </div>
                <div className={styles.data_client_block_sex}>
                  <span className={styles.span_lable}>{text.gender[lang]}</span>
                  <div>
                    <label
                      htmlFor={"7"}
                      className={styles.label_sex}
                      style={{ marginRight: "10px", marginTop: 0 }}
                    >
                      <input
                        type="radio"
                        value="male"
                        name="gender"
                        required
                        onChange={(e) => {
                          setData({ ...data!, gender: e.target.value });
                        }}
                        id={"7"}
                      />
                      <span
                        style={
                          lang === "ar"
                            ? { paddingLeft: "10px" }
                            : { paddingRight: "10px" }
                        }
                        className={styles.span_lable}
                      >
                        {text.male[lang]}
                      </span>
                    </label>
                    <label htmlFor={"8"} className={styles.label}>
                      <input
                        type="radio"
                        value="female"
                        name="gender"
                        required
                        onChange={(e) => {
                          setData({ ...data!, gender: e.target.value });
                        }}
                        id={"8"}
                      />
                      <span className={styles.span_lable}>
                        {text.female[lang]}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                {/* //TODO:text */}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <h3>Verification</h3>
                  {successUploadFile && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "green",
                        gap: "2px",
                      }}
                    >
                      <BiCheckCircle size={20} style={{ fill: "green" }} />
                      Successfully extracted report data
                    </p>
                  )}
                  {errorUploadFile && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "orangered",
                        gap: "2px",
                      }}
                    >
                      <RiErrorWarningLine
                        size={20}
                        style={{ fill: "orangered" }}
                      />
                      OCR could not read the PDF file. Please upload another PDF
                      file
                    </p>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    height: "80px",
                    gap: "20px",
                    margin: "20px",
                  }}
                >
                  {/* <div className={styles.data_client_block_choose_upload}>
                <span className={styles.span_lable}>Upload</span>
                <div>
                  <label
                    htmlFor={"78"}
                    className={styles.label_sex}
                    style={{ marginRight: "10px", marginTop: 0 }}
                  >
                    <input
                      type="radio"
                      value="true"
                      name="upload"
                      required
                      onChange={(e) => {
                        setUpload(e.target.value === "true");
                      }}
                      id={"78"}
                    />
                    <span
                      style={
                        lang === "ar"
                          ? { paddingLeft: "10px" }
                          : { paddingRight: "10px" }
                      }
                      className={styles.span_lable}
                    >
                      true
                    </span>
                  </label>
                  <label htmlFor={"87"} className={styles.label}>
                    <input
                      type="radio"
                      value="false"
                      name="upload"
                      required
                      checked={!upload}
                      onChange={(e) => {
                        setUpload(e.target.value !== "false");
                      }}
                      id={"87"}
                    />
                    <span className={styles.span_lable}>false</span>
                  </label>
                </div>
              </div> */}

                  <div>
                    <input
                      accept=".pdf"
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <p>CRIF:</p>
                      <ButtonComponent
                        type="button"
                        mode="border"
                        style={{
                          width: "50px",
                          height: "40px",
                        }}
                        onClick={handleButtonClick}
                      >
                        <Icon name="fa-solid fa-file-arrow-up" size="small" />
                      </ButtonComponent>
                    </div>

                    <div>
                      <p>{selectedFile ? selectedFile.name : ""}</p>
                    </div>

                    <p className={styles.error_validation}>
                      {!selectedFileStatus
                        ? "File not selected"
                        : !textErrorUpload && "file not uploaded"}
                    </p>
                  </div>

                  <div className={styles.button_container_crif}>
                    <ButtonComponent
                      mode="primary"
                      type="button"
                      style={{
                        width: "100px",
                        height: "40px",
                      }}
                      onClick={handleButtonSubmit}
                      disabled={loadingUpload || selectedFile === null}
                    >
                      {loadingUpload ? <LoadingSpinner /> : "Upload file"}
                    </ButtonComponent>
                  </div>
                </div>
                <div className={styles.data_client_block}>
                  <label
                    htmlFor={"CRIFNationalId"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.national_id[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="CRIFNationalId"
                      id={"CRIFNationalId"}
                      disabled={disable}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        const regex = /^[a-zA-Z0-9]+$/;

                        if (
                          newInputValue === "" ||
                          (newInputValue.length < 11 &&
                            regex.test(newInputValue))
                        ) {
                          setData({
                            ...data!,
                            crif_national_id:
                              newInputValue !== ""
                                ? String(newInputValue)
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.crif_national_id ? data.crif_national_id : ""
                      }
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.CRIFNationalId}
                    </p>
                  </label>

                  <label
                    htmlFor={"MaxDelayedDays"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>Max delayed days</span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="MaxDelayedDays"
                      id={"MaxDelayedDays"}
                      disabled={disable}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        const regex = /^[a-zA-Z0-9]+$/;

                        if (
                          newInputValue === "" ||
                          (newInputValue.length < 11 &&
                            regex.test(newInputValue))
                        ) {
                          setData({
                            ...data!,
                            maxDelayedDays:
                              newInputValue !== ""
                                ? String(newInputValue)
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.maxDelayedDays ? data.maxDelayedDays : ""}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.maxDelayedDays}
                    </p>
                  </label>
                </div>
                <div className={styles.data_client_block}>
                  <label
                    htmlFor={"77adssd"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.credit_score[lang]}
                    </span>
                    <Input
                      name="creditScore"
                      disabled={disable}
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        const regex = /^[0-9,]*$/;
                        const regex2 = /^([^,]*,){0,1}[^,]*$/;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            regex2.test(newInputValue) &&
                            newInputValue.length < 8)
                        ) {
                          setData({
                            ...data!,
                            creditScore: newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.creditScore !== undefined
                          ? data?.creditScore
                          : data?.creditScore === "nan"
                          ? ""
                          : ""
                      }
                      id={"77adssd"}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.creditScore}
                    </p>
                  </label>
                  <label
                    htmlFor={"001"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.dependants[lang]}
                    </span>
                    <Input
                      disabled={disable}
                      name="dependants"
                      onChange={(e) => {
                        const newInputValue = e.target.value;
                        const regex = /^[0-9,]*$/;
                        const regex2 = /^([^,]*,){0,1}[^,]*$/;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            regex2.test(newInputValue) &&
                            newInputValue.length < 8)
                        ) {
                          setData({
                            ...data!,
                            dependants: newInputValue,
                          });
                        }
                      }}
                      value={data?.dependants ? data?.dependants : ""}
                      id={"001"}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.dependants}
                    </p>
                  </label>
                </div>

                <div className={styles.data_client_block}>
                  <label
                    htmlFor={"22112"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.number_of_loans[lang]}
                    </span>
                    <Input
                      disabled={disable}
                      name="numberOfLoans"
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            numberOfLoans:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={data?.numberOfLoans ? data?.numberOfLoans : ""}
                      id={"22112"}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.numberOfLoans}
                    </p>
                  </label>
                  <label
                    htmlFor={"12221"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.number_of_active_loans[lang]}
                    </span>
                    <Input
                      disabled={disable}
                      name="numberOfActiveLoans"
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            numberOfActiveLoans:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.numberOfActiveLoans !== undefined
                          ? data?.numberOfActiveLoans
                          : ""
                      }
                      id={"12221"}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.numberOfActiveLoans}
                    </p>
                  </label>
                </div>

                <div className={styles.data_client_block}>
                  <label
                    htmlFor={"22112"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.total_monthly_payment[lang]}
                    </span>
                    <Input
                      disabled={disable}
                      name="totalMonthlyPayment"
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 6)
                        ) {
                          setData({
                            ...data!,
                            totalMonthlyPayment:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.totalMonthlyPayment !== undefined
                          ? data?.totalMonthlyPayment
                          : ""
                      }
                      id={"22112"}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.totalMonthlyPayment}
                    </p>
                  </label>
                  <label
                    htmlFor={"3443"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {text.delay_payments[lang]}
                    </span>
                    <Input
                      disabled={disable}
                      name="delayPayments"
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            delayPayments:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.delayPayments !== undefined
                          ? data?.delayPayments
                          : ""
                      }
                      id={"3443"}
                      required
                    />
                    <p className={styles.error_validation}>
                      {errorValidation.delayPayments}
                    </p>
                  </label>
                </div>

                <div className={styles.data_client_block}>
                  <label
                    htmlFor={"3443ae"}
                    className={styles.label}
                    style={
                      disable
                        ? {
                            opacity: "0.5",
                          }
                        : {}
                    }
                  >
                    <span className={styles.span_label}>
                      {/* {text.delay_payments[lang]} */}
                      {/* TODO: add to text */}
                      Number of obligations
                    </span>
                    <Input
                      disabled={disable}
                      name="number_of_obligations"
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            numberOfObligations:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.numberOfObligations
                          ? data?.numberOfObligations
                          : ""
                      }
                      id={"3443ae"}
                      required
                    />
                    <p className={styles.error_validation}></p>
                  </label>
                  <label
                    htmlFor={"3443cv"}
                    className={styles.label}
                    style={{
                      opacity: "0.5",
                    }}
                  >
                    <span className={styles.span_label}>
                      {/* {text.delay_payments[lang]} */}
                      {/* TODO: add to text */}
                      DBR
                    </span>
                    <Input
                      name="dbr"
                      disabled
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setData({
                            ...data!,
                            dbr:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        data?.dbr
                          ? Number(data?.dbr?.replace("%", "")) > 100
                            ? "100%"
                            : data?.dbr?.replace("%", "") === "NaN"
                            ? ""
                            : data?.dbr
                          : ""
                      }
                      id={"3443cv"}
                      required
                    />
                    <p className={styles.error_validation}></p>
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.form_block}>
              <div className={styles.section}>
                <h3>Loan info</h3>
                <div className={styles.data_client_block}>
                  {/*<label className={styles.label} htmlFor={"2"}>*/}
                  {/*  <span className={styles.span_label}>*/}
                  {/*    {text.prior_loans[lang]}*/}
                  {/*  </span>*/}
                  {/*  <Input*/}
                  {/*    mode={"primary"}*/}
                  {/*    type={"text"}*/}
                  {/*    className={styles.input_text}*/}
                  {/*    name="priorLoans"*/}
                  {/*    id={"2"}*/}
                  {/*    autofocus={true}*/}
                  {/*    onChange={(e) => {*/}
                  {/*      const regex = /^[0-9]*$/;*/}
                  {/*      const newInputValue = e.target.value;*/}
                  {/*      if (*/}
                  {/*        newInputValue === "" ||*/}
                  {/*        (regex.test(newInputValue) && newInputValue.length < 4)*/}
                  {/*      ) {*/}
                  {/*        setData({*/}
                  {/*          ...data!,*/}
                  {/*          priorLoans:*/}
                  {/*            newInputValue !== ""*/}
                  {/*              ? String(Number(newInputValue))*/}
                  {/*              : newInputValue,*/}
                  {/*        });*/}
                  {/*      }*/}
                  {/*    }}*/}
                  {/*    value={data?.priorLoans ? data.priorLoans : ""}*/}
                  {/*    required*/}
                  {/*  />*/}
                  {/*  <p className={styles.error_validation}>*/}
                  {/*    {errorValidation?.priorLoans}*/}
                  {/*  </p>*/}
                  {/*</label>*/}{" "}
                  {/*<label className={styles.label} htmlFor={"2"}>*/}
                  {/*  <span className={styles.span_label}>Co-borrower</span>*/}
                  {/*  <select*/}
                  {/*    className={styles.input_select}*/}
                  {/*    name="coBorrower"*/}
                  {/*    onChange={(e) => {*/}
                  {/*      setData({*/}
                  {/*        ...data!,*/}
                  {/*        coBorrower: e.target.value,*/}
                  {/*      });*/}
                  {/*    }}*/}
                  {/*    id={"2"}*/}
                  {/*    value={data?.coBorrower ? data.coBorrower : ""}*/}
                  {/*    required*/}
                  {/*  >*/}
                  {/*    <option value="">{text.choose[lang]}</option>*/}
                  {/*    <option value="1">{text.yes[lang]}</option>*/}
                  {/*    <option value="0">No</option>*/}
                  {/*  </select>*/}
                  {/*  <p className={styles.error_validation}></p>*/}
                  {/*</label>*/}
                  <label htmlFor={"4"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.interest_rate[lang]} (%)
                    </span>
                    <Input
                      mode={"primary"}
                      name="interestRateMonthly"
                      id={"4"}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 5)
                        ) {
                          setDataGroupLoan({
                            ...dataGroupLoan!,
                            interestRateMonthly:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        dataGroupLoan?.interestRateMonthly
                          ? dataGroupLoan.interestRateMonthly
                          : ""
                      }
                      required
                    />
                    <p className={styles.error_validation}>
                      {validationGroupLoan?.interestRateMonthly}
                    </p>
                  </label>
                  <label htmlFor={"5"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.duration[lang]}
                    </span>
                    <Input
                      mode={"primary"}
                      className={styles.input_text}
                      name="loanDuration"
                      id={"5"}
                      onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        const newInputValue = e.target.value;
                        if (
                          newInputValue === "" ||
                          (regex.test(newInputValue) &&
                            newInputValue.length < 4)
                        ) {
                          setDataGroupLoan({
                            ...dataGroupLoan!,
                            loanDuration:
                              newInputValue !== ""
                                ? String(Number(newInputValue))
                                : newInputValue,
                          });
                        }
                      }}
                      value={
                        dataGroupLoan?.loanDuration
                          ? dataGroupLoan.loanDuration
                          : ""
                      }
                      required
                    />
                    <p className={styles.error_validation}>
                      {validationGroupLoan?.loanDuration}
                    </p>
                  </label>
                </div>
                <div className={styles.data_client_block}>
                  <label htmlFor={"12"} className={styles.label}>
                    <span className={styles.span_label}>
                      {text.branch[lang]}
                    </span>
                    <select
                      className={styles.input_select}
                      name="branch"
                      onChange={(e) => {
                        setDataGroupLoan({
                          ...dataGroupLoan!,
                          branch: e.target.value,
                        });
                      }}
                      id={"12"}
                      value={dataGroupLoan?.branch ? dataGroupLoan.branch : ""}
                      required
                    >
                      <option value="">{text.choose[lang]}</option>
                      <option value="hussein">{text.hussein[lang]}</option>
                      <option value="salt_branch">
                        {text.salt_branch[lang]}
                      </option>
                      <option value="hitteen">{text.hitteen[lang]}</option>
                      <option value="sahab">{text.sahab[lang]}</option>
                      <option value="zarqa">{text.zarqa[lang]}</option>
                      <option value="rsaifeh_branch">Rsaifeh Branch</option>
                      <option value="koura_branch">
                        {text.koura_branch[lang]}
                      </option>
                      <option value="irbid">{text.irbid[lang]}</option>
                      <option value="dayr_alla">{text.dayr_alla[lang]}</option>
                      <option value="madaba">{text.madaba[lang]}</option>
                      <option value="n_shouna">{text.n_shouna[lang]}</option>
                      <option value="beni_kenana_branch">
                        {text.beni_kenana_branch[lang]}
                      </option>
                      <option value="jerash">{text.jerash[lang]}</option>
                      <option value="marka_branch">
                        {text.marka_branch[lang]}
                      </option>
                      <option value="al_karak_branch">
                        {text.al_karak_branch[lang]}
                      </option>
                      <option value="mafraq">{text.Mafraq[lang]}</option>
                      <option value="aqaba">{text.Aqaba[lang]}</option>
                    </select>
                    <p className={styles.error_validation}>
                      {validationGroupLoan?.branch}
                    </p>
                  </label>
                </div>
              </div>

              <div className={styles.group_loan_array}>
                {dataGroupLoan.groupLoan.map((groupLoan, i) => (
                  <div
                    key={i}
                    onClick={() => setSelectedGroupLoan(groupLoan.id)}
                    className={
                      selectedGroupLoan === groupLoan.id
                        ? styles.group_loan_array_item__active
                        : styles.group_loan_array_item
                    }
                  >
                    Client {i + 1}
                    <div
                      className={styles.delete_group_loan}
                      onClick={(e) => {
                        e.stopPropagation();
                        const filteredGroupLoanArray =
                          dataGroupLoan.groupLoan.filter(
                            (e) => e.id !== groupLoan.id
                          );
                        dataGroupLoan.groupLoan.length !== 2 &&
                          setDataGroupLoan((prevData) => ({
                            ...prevData,
                            groupLoan: filteredGroupLoanArray,
                          }));
                      }}
                    >
                      <Icon name={"fa-solid fa-x"} size={"tiny"} />
                    </div>
                  </div>
                ))}
                <div className={styles.plus_button} onClick={addClient}>
                  <Icon name={"fa-solid fa-plus"} size={"small"} />
                </div>
              </div>

              {dataGroupLoan.groupLoan.map(
                (groupLoan, i) =>
                  groupLoan.id === selectedGroupLoan && (
                    <div className={styles.form_block} key={i}>
                      <div className={styles.section}>
                        <h3>KYC</h3>
                        <div className={styles.data_client_block}>
                          <label
                            htmlFor={"KYCNationalId"}
                            className={styles.label}
                          >
                            <span className={styles.span_label}>
                              {text.national_id[lang]}
                            </span>
                            <Input
                              mode={"primary"}
                              className={styles.input_text}
                              name="KYCNationalId"
                              id={"KYCNationalId"}
                              onChange={(e) => {
                                const newInputValue = e.target.value;
                                const regex = /^[a-zA-Z0-9]+$/;

                                if (
                                  newInputValue === "" ||
                                  (newInputValue.length < 11 &&
                                    regex.test(newInputValue))
                                ) {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            kyc_national_id:
                                              newInputValue !== ""
                                                ? String(newInputValue)
                                                : newInputValue,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }
                              }}
                              value={
                                groupLoan.kyc_national_id
                                  ? groupLoan.kyc_national_id
                                  : ""
                              }
                              required
                            />
                            <p className={styles.error_validation}>
                              {
                                validationGroupLoan?.groupLoan.find(
                                  (e) => e.id === groupLoan.id
                                )?.kyc_national_id
                              }
                            </p>
                          </label>
                          <label htmlFor={"19"} className={styles.label}>
                            <span className={styles.span_label}>
                              {text.amount_of_loan[lang]}
                            </span>
                            <Input
                              mode={"primary"}
                              name="loanAmount"
                              id={"19"}
                              onChange={(e) => {
                                const regex = /^[0-9]*$/;
                                const newInputValue = e.target.value;
                                if (
                                  newInputValue === "" ||
                                  (regex.test(newInputValue) &&
                                    newInputValue.length < 6)
                                ) {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            loanAmount:
                                              newInputValue !== ""
                                                ? String(newInputValue)
                                                : newInputValue,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }
                              }}
                              value={
                                groupLoan?.loanAmount
                                  ? groupLoan.loanAmount
                                  : ""
                              }
                              required
                            />
                            <p className={styles.error_validation}>
                              {
                                validationGroupLoan?.groupLoan.find(
                                  (e) => e.id === groupLoan.id
                                )?.loanAmount
                              }
                            </p>
                          </label>
                        </div>
                        <div className={styles.data_client_block}>
                          <label
                            htmlFor={"monthly_payment"}
                            className={styles.label}
                          >
                            <span className={styles.span_label}>
                              {text.loan_monthly_payment[lang]}
                            </span>
                            <Input
                              mode={"primary"}
                              className={styles.input_text}
                              name="id"
                              id={"monthly_payment"}
                              onChange={(e) => {
                                const regex = /^[0-9]*$/;
                                const newInputValue = e.target.value;
                                if (
                                  newInputValue === "" ||
                                  (regex.test(newInputValue) &&
                                    newInputValue.length < 6)
                                ) {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            loanMonthlyPayment:
                                              newInputValue !== ""
                                                ? String(newInputValue)
                                                : newInputValue,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }
                              }}
                              value={
                                groupLoan?.loanMonthlyPayment
                                  ? groupLoan.loanMonthlyPayment
                                  : ""
                              }
                              required
                            />
                            <p className={styles.error_validation}>
                              {
                                validationGroupLoan?.groupLoan.find(
                                  (e) => e.id === groupLoan.id
                                )?.loanMonthlyPayment
                              }
                            </p>
                          </label>
                          <label htmlFor={"6"} className={styles.label}>
                            <span className={styles.span_label}>
                              {text.total_income[lang]}
                            </span>
                            <Input
                              mode={"primary"}
                              className={styles.input_text}
                              name="totalIncome"
                              required
                              onChange={(e) => {
                                const regex = /^[0-9]*$/;
                                const newInputValue = e.target.value;
                                if (
                                  newInputValue === "" ||
                                  (regex.test(newInputValue) &&
                                    newInputValue.length < 6)
                                ) {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            totalIncome:
                                              newInputValue !== ""
                                                ? String(newInputValue)
                                                : newInputValue,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }
                              }}
                              value={
                                groupLoan?.totalIncome
                                  ? groupLoan.totalIncome
                                  : ""
                              }
                              id={"6"}
                            />
                            <p className={styles.error_validation}>
                              {
                                validationGroupLoan?.groupLoan.find(
                                  (e) => e.id === groupLoan.id
                                )?.totalIncome
                              }
                            </p>
                          </label>
                        </div>

                        <div className={styles.data_client_block}>
                          <label htmlFor={"10"} className={styles.label}>
                            <span className={styles.span_label}>
                              {text.family_status[lang]}
                            </span>
                            <select
                              className={styles.input_select}
                              name="familyStatus"
                              onChange={(e) => {
                                setDataGroupLoan((prevData) => {
                                  const updatedGroupLoan =
                                    prevData.groupLoan.map((loan, index) => {
                                      if (index === i) {
                                        return {
                                          ...loan,
                                          familyStatus: e.target.value,
                                        };
                                      }
                                      return loan;
                                    });
                                  return {
                                    ...prevData,
                                    groupLoan: updatedGroupLoan,
                                  };
                                });
                              }}
                              value={
                                groupLoan?.familyStatus
                                  ? groupLoan?.familyStatus
                                  : ""
                              }
                              id={"10"}
                              required
                            >
                              <option value="">{text.choose[lang]}</option>
                              <option value="married">
                                {text.married[lang]}
                              </option>
                              <option value="divorced">
                                {text.divorced[lang]}
                              </option>
                              <option value="single">
                                {text.single[lang]}
                              </option>
                              <option value="widowed">
                                {text.widowed[lang]}
                              </option>
                              <option value="separated">
                                {text.separated[lang]}
                              </option>
                            </select>
                            <p className={styles.error_validation}>
                              {
                                validationGroupLoan?.groupLoan.find(
                                  (e) => e.id === groupLoan.id
                                )?.familyStatus
                              }
                            </p>
                          </label>
                          <label htmlFor={"9"} className={styles.label}>
                            <span className={styles.span_label}>
                              {text.age[lang]}
                            </span>
                            <Input
                              mode={"primary"}
                              className={styles.input_text}
                              name="age"
                              id={"9"}
                              onChange={(e) => {
                                const regex = /^[0-9]*$/;
                                const newInputValue = e.target.value;
                                if (
                                  newInputValue === "" ||
                                  (regex.test(newInputValue) &&
                                    newInputValue.length < 4)
                                ) {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            age:
                                              newInputValue !== ""
                                                ? String(newInputValue)
                                                : newInputValue,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }
                              }}
                              value={groupLoan.age ? groupLoan.age : ""}
                              required
                            />
                            <p className={styles.error_validation}>
                              {
                                validationGroupLoan?.groupLoan.find(
                                  (e) => e.id === groupLoan.id
                                )?.age
                              }
                            </p>
                          </label>
                        </div>
                        <div className={styles.data_client_block_sex}>
                          <span className={styles.span_lable}>
                            {text.gender[lang]}
                          </span>
                          <div>
                            <label htmlFor={"7"} className={styles.label_sex}>
                              <input
                                type="radio"
                                value="male"
                                name="gender"
                                required
                                checked={groupLoan.gender === "male"}
                                onChange={(e) => {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            gender: e.target.value,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }}
                                id={"7"}
                              />
                              <span
                                style={
                                  lang === "ar"
                                    ? { paddingLeft: "10px" }
                                    : { paddingRight: "10px" }
                                }
                                className={styles.span_lable}
                              >
                                {text.male[lang]}
                              </span>
                            </label>
                            <label htmlFor={"8"} className={styles.label_sex}>
                              <input
                                type="radio"
                                value="female"
                                name="gender"
                                required
                                checked={groupLoan.gender === "female"}
                                onChange={(e) => {
                                  setDataGroupLoan((prevData) => {
                                    const updatedGroupLoan =
                                      prevData.groupLoan.map((loan, index) => {
                                        if (index === i) {
                                          return {
                                            ...loan,
                                            gender: e.target.value,
                                          };
                                        }
                                        return loan;
                                      });
                                    return {
                                      ...prevData,
                                      groupLoan: updatedGroupLoan,
                                    };
                                  });
                                }}
                                id={"8"}
                              />
                              <span className={styles.span_lable}>
                                {text.female[lang]}
                              </span>
                            </label>
                          </div>
                          <p className={styles.error_validation}>
                            {
                              validationGroupLoan?.groupLoan.find(
                                (e) => e.id === groupLoan.id
                              )?.gender
                            }
                          </p>
                        </div>
                      </div>

                      {
                        <div className={styles.section}>
                          {/* //TODO:text */}
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <h3>Verification</h3>
                            {groupLoan.successUploadFile && (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "green",
                                  gap: "2px",
                                }}
                              >
                                <BiCheckCircle
                                  size={20}
                                  style={{ fill: "green" }}
                                />
                                Successfully extracted report data
                              </p>
                            )}
                            {groupLoan.errorUploadFile && (
                              <p
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "orangered",
                                  gap: "2px",
                                }}
                              >
                                <RiErrorWarningLine
                                  size={20}
                                  style={{ fill: "orangered" }}
                                />
                                OCR could not read the PDF file. Please upload
                                another PDF file
                              </p>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              height: "80px",
                              gap: "20px",
                              margin: "20px",
                            }}
                          >
                            {/* <div
                              className={styles.data_client_block_choose_upload}
                            >
                              <span className={styles.span_lable}>Upload</span>
                              <div>
                                <label
                                  htmlFor={"78"}
                                  className={styles.label_sex}
                                  style={{ marginRight: "10px", marginTop: 0 }}
                                >
                                  <input
                                    type="radio"
                                    value="true"
                                    name="upload"
                                    required
                                    onChange={(e) => {
                                      setUpload(e.target.value === "true");
                                    }}
                                    id={"78"}
                                  />
                                  <span
                                    style={
                                      lang === "ar"
                                        ? { paddingLeft: "10px" }
                                        : { paddingRight: "10px" }
                                    }
                                    className={styles.span_lable}
                                  >
                                    true
                                  </span>
                                </label>
                                <label htmlFor={"87"} className={styles.label}>
                                  <input
                                    type="radio"
                                    value="false"
                                    name="upload"
                                    required
                                    checked={!upload}
                                    onChange={(e) => {
                                      setUpload(e.target.value !== "false");
                                    }}
                                    id={"87"}
                                  />
                                  <span className={styles.span_lable}>
                                    false
                                  </span>
                                </label>
                              </div>
                            </div> */}

                            <div>
                              <input
                                accept=".pdf"
                                type="file"
                                onChange={handleFileChangeGroupLoan}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                              />

                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <p>CRIF:</p>
                                <ButtonComponent
                                  type="button"
                                  mode="border"
                                  style={{
                                    width: "50px",
                                    height: "40px",
                                  }}
                                  onClick={handleButtonClick}
                                >
                                  <Icon
                                    name="fa-solid fa-file-arrow-up"
                                    size="small"
                                  />
                                </ButtonComponent>
                              </div>

                              <div>
                                <p>
                                  {groupLoan.selectedFile
                                    ? groupLoan.selectedFile.name
                                    : ""}
                                </p>
                              </div>

                              <p className={styles.error_validation}>
                                {!groupLoan.selectedFileStatus
                                  ? "File not selected"
                                  : !groupLoan.textErrorUpload &&
                                    "file not uploaded"}
                              </p>
                            </div>

                            <div className={styles.button_container_crif}>
                              <ButtonComponent
                                mode="primary"
                                type="button"
                                style={{
                                  width: "100px",
                                  height: "40px",
                                }}
                                onClick={handleButtonSubmitGroupLoan}
                                disabled={
                                  groupLoan.loadingUpload ||
                                  groupLoan.selectedFile === null
                                }
                              >
                                {groupLoan.loadingUpload ? (
                                  <LoadingSpinner />
                                ) : (
                                  "Upload file"
                                )}
                              </ButtonComponent>
                            </div>
                          </div>
                          <div className={styles.data_client_block}>
                            <label
                              htmlFor={"CRIFNationalId"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.national_id[lang]}
                              </span>
                              <Input
                                mode={"primary"}
                                className={styles.input_text}
                                name="CRIFNationalId"
                                id={"CRIFNationalId"}
                                disabled={disable}
                                onChange={(e) => {
                                  const newInputValue = e.target.value;
                                  const regex = /^[a-zA-Z0-9]+$/;

                                  if (
                                    newInputValue === "" ||
                                    (newInputValue.length < 11 &&
                                      regex.test(newInputValue))
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                crif_national_id: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan.crif_national_id
                                    ? groupLoan.crif_national_id
                                    : ""
                                }
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.CRIFNationalId}
                              </p>
                            </label>

                            <label
                              htmlFor={"maxDelayedDays"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                Max delayed days
                              </span>
                              <Input
                                mode={"primary"}
                                className={styles.input_text}
                                name="maxDelayedDays"
                                id={"maxDelayedDays"}
                                disabled={disable}
                                onChange={(e) => {
                                  const newInputValue = e.target.value;
                                  const regex = /^[a-zA-Z0-9]+$/;

                                  if (
                                    newInputValue === "" ||
                                    (newInputValue.length < 11 &&
                                      regex.test(newInputValue))
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                maxDelayedDays: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan.maxDelayedDays
                                    ? groupLoan.maxDelayedDays
                                    : ""
                                }
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.maxDelayedDays}
                              </p>
                            </label>
                          </div>
                          <div className={styles.data_client_block}>
                            <label
                              htmlFor={"77adssd"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.credit_score[lang]}
                              </span>
                              <Input
                                name="creditScore"
                                disabled={disable}
                                onChange={(e) => {
                                  const newInputValue = e.target.value;
                                  const regex = /^[0-9,]*$/;
                                  const regex2 = /^([^,]*,){0,1}[^,]*$/;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      regex2.test(newInputValue) &&
                                      newInputValue.length < 8)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                creditScore: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.creditScore !== undefined
                                    ? groupLoan?.creditScore
                                    : groupLoan?.creditScore === "nan"
                                    ? ""
                                    : ""
                                }
                                id={"77adssd"}
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.creditScore}
                              </p>
                            </label>
                            <label
                              htmlFor={"001"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.dependants[lang]}
                              </span>
                              <Input
                                disabled={disable}
                                name="dependants"
                                onChange={(e) => {
                                  const newInputValue = e.target.value;
                                  const regex = /^[0-9,]*$/;
                                  const regex2 = /^([^,]*,){0,1}[^,]*$/;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      regex2.test(newInputValue) &&
                                      newInputValue.length < 8)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                dependants: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.dependants
                                    ? groupLoan?.dependants
                                    : ""
                                }
                                id={"001"}
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.dependants}
                              </p>
                            </label>
                          </div>

                          <div className={styles.data_client_block}>
                            <label
                              htmlFor={"22112"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.number_of_loans[lang]}
                              </span>
                              <Input
                                disabled={disable}
                                name="numberOfLoans"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  const newInputValue = e.target.value;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      newInputValue.length < 4)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                numberOfLoans: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.numberOfLoans
                                    ? groupLoan?.numberOfLoans
                                    : ""
                                }
                                id={"22112"}
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.numberOfLoans}
                              </p>
                            </label>
                            <label
                              htmlFor={"12221"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.number_of_active_loans[lang]}
                              </span>
                              <Input
                                disabled={disable}
                                name="numberOfActiveLoans"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  const newInputValue = e.target.value;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      newInputValue.length < 4)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                numberOfActiveLoans:
                                                  newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.numberOfActiveLoans !== undefined
                                    ? groupLoan?.numberOfActiveLoans
                                    : ""
                                }
                                id={"12221"}
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.numberOfActiveLoans}
                              </p>
                            </label>
                          </div>

                          <div className={styles.data_client_block}>
                            <label
                              htmlFor={"22112"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.total_monthly_payment[lang]}
                              </span>
                              <Input
                                disabled={disable}
                                name="totalMonthlyPayment"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  const newInputValue = e.target.value;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      newInputValue.length < 6)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                totalMonthlyPayment:
                                                  newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.totalMonthlyPayment !== undefined
                                    ? groupLoan?.totalMonthlyPayment
                                    : ""
                                }
                                id={"22112"}
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.totalMonthlyPayment}
                              </p>
                            </label>
                            <label
                              htmlFor={"3443"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {text.delay_payments[lang]}
                              </span>
                              <Input
                                disabled={disable}
                                name="delayPayments"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  const newInputValue = e.target.value;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      newInputValue.length < 4)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                delayPayments: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.delayPayments !== undefined
                                    ? groupLoan?.delayPayments
                                    : ""
                                }
                                id={"3443"}
                                required
                              />
                              <p className={styles.error_validation}>
                                {errorValidation.delayPayments}
                              </p>
                            </label>
                          </div>

                          <div className={styles.data_client_block}>
                            <label
                              htmlFor={"3443ae"}
                              className={styles.label}
                              style={
                                disable
                                  ? {
                                      opacity: "0.5",
                                    }
                                  : {}
                              }
                            >
                              <span className={styles.span_label}>
                                {/* {text.delay_payments[lang]} */}
                                {/* TODO: add to text */}
                                Number of obligations
                              </span>
                              <Input
                                disabled={disable}
                                name="number_of_obligations"
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  const newInputValue = e.target.value;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      newInputValue.length < 4)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                numberOfObligations:
                                                  newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.numberOfObligations
                                    ? groupLoan?.numberOfObligations
                                    : ""
                                }
                                id={"3443ae"}
                                required
                              />
                              <p className={styles.error_validation}></p>
                            </label>
                            <label
                              htmlFor={"3443cv"}
                              className={styles.label}
                              style={{
                                opacity: "0.5",
                              }}
                            >
                              <span className={styles.span_label}>
                                {/* {text.delay_payments[lang]} */}
                                {/* TODO: add to text */}
                                DBR
                              </span>
                              <Input
                                name="dbr"
                                disabled
                                onChange={(e) => {
                                  const regex = /^[0-9]*$/;
                                  const newInputValue = e.target.value;
                                  if (
                                    newInputValue === "" ||
                                    (regex.test(newInputValue) &&
                                      newInputValue.length < 4)
                                  ) {
                                    setDataGroupLoan((prevData) => {
                                      const updatedGroupLoan =
                                        prevData.groupLoan.map(
                                          (loan, index) => {
                                            if (index === i) {
                                              return {
                                                ...loan,
                                                dbr: newInputValue,
                                              };
                                            }
                                            return loan;
                                          }
                                        );
                                      return {
                                        ...prevData,
                                        groupLoan: updatedGroupLoan,
                                      };
                                    });
                                  }
                                }}
                                value={
                                  groupLoan?.dbr
                                    ? Number(groupLoan?.dbr?.replace("%", "")) >
                                      100
                                      ? "100%"
                                      : groupLoan?.dbr?.replace("%", "") ===
                                        "NaN"
                                      ? ""
                                      : groupLoan?.dbr
                                    : ""
                                }
                                id={"3443cv"}
                                required
                              />
                              <p className={styles.error_validation}></p>
                            </label>
                          </div>
                        </div>
                      }
                    </div>
                  )
              )}
            </div>
          )}
          <div className={styles.footer_block}>
            <div className={styles.data_client_block}></div>
            <div className={styles.button_container}>
              <ButtonComponent className={styles.button} mode={"primary"}>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  `${text.get_scoring_result[lang]}`
                )}
              </ButtonComponent>
            </div>
          </div>
        </form>

        {modalError && (
          <div className={styles.modal_result_container}>
            {
              <div className={styles.error_validate_container}>
                <RiErrorWarningLine size={150} style={{ fill: "orangered" }} />
                {!allResult?.ageVerificationStatus && (
                  <p
                    style={{
                      color: "orangered",
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Loan application rejected due to age limit
                  </p>
                )}
                {!allResult?.obligationsVerificationStatus && (
                  <p
                    style={{
                      color: "orangered",
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Loan application rejected due to high number of obligations
                  </p>
                )}
                {!allResult?.maxDelayDaysStatus && (
                  <p
                    style={{
                      color: "orangered",
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Loan application rejected due to high number of Max delayed
                    days
                  </p>
                )}
                {!allResult?.nationalIdsVerificationStatus && (
                  <p
                    style={{
                      color: "orangered",
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Loan application rejected due to National Ids don't match
                  </p>
                )}
                {!allResult?.dbrVerificationStatus && (
                  <p
                    style={{
                      color: "orangered",
                      fontSize: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Loan application rejected due to high DBR
                  </p>
                )}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonComponent
                    mode="border"
                    style={{
                      width: "auto",
                      height: "40px",
                      padding: "0 10px",
                      fontSize: "15px",
                      minWidth: "70px",
                      marginRight: "20px",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      setModalError(false);
                      navigate("/");
                    }}
                  >
                    OK
                  </ButtonComponent>
                </div>
              </div>
            }
          </div>
        )}

        {modalLicense && (
          <div className={styles.license_modal_container}>
            <ModalLicense setModal={setModalLicense} />
          </div>
        )}

        {!groupLoan && result?.loanModelInput && (
          <ModalPdfComponent
            modal={pdfModal}
            data={result}
            setModal={setPdfModal}
          />
        )}

        {groupLoan && pdfModal && (
          <ModalPdfGroupLoanComponent
            modal={pdfModal}
            data={allResult}
            setModal={setPdfModal}
          />
        )}
        <div
          className={styles.modal_result_container}
          style={!modalActive ? { display: "none" } : {}}
        >
          {error.validation && (
            <div className={styles.error_validate_container}>
              <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
              <p style={{ color: "orangered" }}>Validation error</p>
            </div>
          )}

          {error.serverError && (
            <div className={styles.error_server_container}>
              <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
              <p style={{ color: "orangered" }}>Server Error</p>
            </div>
          )}

          {!error.serverError && !error.validation && (
            <div className={styles.result_container}>
              {allResult && allResult.scoringStatus && (
                <>
                  {allResult.scoringStatus === "Approve" ? (
                    <>
                      <h3>{text.result[lang]}</h3>
                      <BiCheckCircle size={100} />
                      <p>
                        {text.approved[lang]}{" "}
                        {allResult &&
                          Number(allResult.probabilityOfGood * 100).toFixed(2)}
                        %
                      </p>
                    </>
                  ) : allResult.scoringStatus === "Reject" ? (
                    <>
                      <h3 style={{ color: "orangered" }}>
                        {text.result[lang]}
                      </h3>
                      <RiErrorWarningLine
                        size={100}
                        style={{ fill: "orangered" }}
                      />
                      <p style={{ color: "orangered" }}>
                        {text.denied[lang]}{" "}
                        {lang === "ar"
                          ? formatNumber(
                              Number(
                                Number(
                                  allResult.probabilityOfGood * 100
                                ).toFixed(2)
                              )
                            )
                          : Number(allResult.probabilityOfGood * 100).toFixed(
                              2
                            )}
                        %
                      </p>
                    </>
                  ) : allResult.scoringStatus === "Review" ? (
                    <>
                      <h3 style={{ color: "orange" }}>{text.result[lang]}</h3>
                      <RiErrorWarningLine
                        size={100}
                        style={{ fill: "orange" }}
                      />
                      <p style={{ color: "orange" }}>
                        Review{" "}
                        {lang === "ar"
                          ? formatNumber(
                              Number(
                                Number(
                                  allResult.probabilityOfGood * 100
                                ).toFixed(2)
                              )
                            )
                          : Number(allResult.probabilityOfGood * 100).toFixed(
                              2
                            )}
                        %
                      </p>
                    </>
                  ) : (
                    <>
                      {!allResult?.isBadLoan ? (
                        <>
                          <h3>{text.result[lang]}</h3>
                          <BiCheckCircle size={100} />
                          <p>
                            {text.approved[lang]}{" "}
                            {allResult &&
                              Number(allResult.probabilityOfGood * 100).toFixed(
                                2
                              )}
                            %
                          </p>
                        </>
                      ) : (
                        <>
                          <h3 style={{ color: "orangered" }}>
                            {text.result[lang]}
                          </h3>
                          <RiErrorWarningLine
                            size={100}
                            style={{ fill: "orangered" }}
                          />
                          <p style={{ color: "orangered" }}>
                            {text.denied[lang]}{" "}
                            {lang === "ar"
                              ? formatNumber(
                                  Number(
                                    Number(
                                      allResult.probabilityOfGood * 100
                                    ).toFixed(2)
                                  )
                                )
                              : Number(
                                  allResult.probabilityOfGood * 100
                                ).toFixed(2)}
                            %
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <div className={styles.button_container_modal_success}>
                <ButtonComponent
                  mode={"border"}
                  className={styles.button_result_cansel}
                  onClick={() => {
                    setModalActive(false);
                    navigate("/");
                  }}
                >
                  {text.cancel[lang]}
                </ButtonComponent>
                <ButtonComponent
                  className={styles.button_result_download}
                  mode={"primary"}
                  onClick={() => {
                    setPdfModal(true);
                    setModalActive(false);
                  }}
                >
                  {text.download[lang]}
                </ButtonComponent>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
