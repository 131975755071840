import styles from "./modal-auto-refresh.styles.module.scss";
import React, { useContext, useState } from "react";
import { LoadingSpinner } from "../../../ui-components/loading-spinner/loading-spinner.component.tsx";
import { RiErrorWarningLine } from "react-icons/ri";
import { ButtonComponent } from "../../../ui-components/button/button.component.tsx";
import { sendGetRequest } from "../../../utils/send-get-request.ts";
import { BiCheckCircle } from "react-icons/bi";
import { text } from "../../../../text.tsx";
import { LanguageContext } from "../../../contexts/language-context/language.context.tsx";
export default function ModalAutoRefreshComponent({
  setModalCancelActive,
}: {
  setModalCancelActive: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [checked, setChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalWarning, setModalWarning] = useState(false);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  async function send() {
    setLoading(true);
    const res = await sendGetRequest(
      `/License/requestRenewal?autoUpdateForMonth=${checked}`
    );
    setLoading(false);
    if (!res) {
      setModalWarning(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setModalWarning(false);
      }, 3000);
      return;
    }
    if (res.status === 200) {
      setSuccess(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setSuccess(false);
      }, 3000);
    } else {
      setModalWarning(true);
      setTimeout(() => {
        setModalCancelActive(false);
        setModalWarning(false);
      }, 3000);
    }
  }
  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.modal_container}
      onClick={() => setModalCancelActive(false)}
    >
      {success ? (
        // <SuccessModal text={"License successfully updated"} />
        <div className={styles.modal_container_success}>
          <div className={styles.statusModal}>
            <BiCheckCircle size={100} />
            <p
              style={{
                textAlign: "center",
              }}
            >
              License successfully updated!
              {text.license_successfully_updated[lang]}
            </p>
          </div>
        </div>
      ) : modalWarning ? (
        <div
          style={{
            width: "400px",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <RiErrorWarningLine size={100} style={{ fill: "orangered" }} />
          <p style={{ color: "orangered", textAlign: "center" }}>
            {
              text.you_have_a_issues_with_an_automatic_update_of_the_license[
                lang
              ]
            }
          </p>
        </div>
      ) : (
        <div
          className={styles.modal_block}
          onClick={(e) => e.stopPropagation()}
        >
          <RiErrorWarningLine size={120} style={{ fill: "#fdac58" }} />
          <h2
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              margin: "10px",
            }}
          >
            {text.are_you_sure_you_want_to_request_an_extension[lang]}
          </h2>
          <label htmlFor={"1"}>
            <input
              type={"checkbox"}
              className={styles.checkbox}
              id={"1"}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
            <span style={{ marginLeft: "5px" }}>
              {text.enable_automatic_license_renewal[lang]}
            </span>
          </label>
          <div className={styles.button_container}>
            <ButtonComponent
              mode={"primary"}
              className={styles.button_cancel}
              onClick={send}
            >
              {loading ? <LoadingSpinner /> : `${text.yes[lang]}`}
            </ButtonComponent>
            <ButtonComponent
              mode={"primary"}
              className={styles.button_save}
              onClick={() => {
                setModalCancelActive(false);
              }}
            >
              {text.cancel[lang]}
            </ButtonComponent>
          </div>
        </div>
      )}
    </div>
  );
}
