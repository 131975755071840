import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendGetRequest } from "../../utils/send-get-request.ts";
import { userGetResponse } from "../../types/types.ts";
import textWrap from "../../utils/text-wrap.ts";
import { LoadingSpinner } from "../../ui-components/loading-spinner/loading-spinner.component.tsx";
import styles from "./list-user-styles.module.scss";
import { sendPostRequest } from "../../utils/send-post-request.ts";
import { Input } from "../../ui-components/input/input.component.tsx";
import { ButtonComponent } from "../../ui-components/button/button.component.tsx";
import { AuthContext } from "../../contexts/auth-context/auth.context.tsx";
import { text } from "../../../text.tsx";
import { LanguageContext } from "../../contexts/language-context/language.context.tsx";
export default function ListUserPageComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [onSearchChange, setSearchChange] = useState("");
  const [users, setUsers] = useState<userGetResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loadingBlock, setLoadingBlock] = useState(false);
  const [userName, setUserName] = useState("");
  const [filterUser, setFilterUser] = useState<userGetResponse[]>([]);
  const { authData } = useContext(AuthContext);
  const { lang } = useContext(LanguageContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (authData && authData.token) {
      setLoading(true);
      sendPostRequest("/Login/validateToken").then((res) => {
        if (!res) {
          navigate("/login");
          return;
        }
        if (res.status !== 200) {
          navigate("/login");
          return;
        }
      });

      sendGetRequest("/Login/users").then((res) => {
        if (!res) {
          return;
        }
        res.json().then((e) => {
          setUsers(e);
          setFilterUser(e);
        });
        setLoading(false);
      });
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (!onSearchChange.trim()) {
      setFilterUser(users);
    }
  }, [onSearchChange]);

  // ---------------------------------------------------------------------------
  // function
  // ---------------------------------------------------------------------------
  function searchUsers(query: string) {
    query = query.toLowerCase();
    return users.filter(
      (user) =>
        user.username.toLowerCase().includes(query) ||
        user.lastName.toLowerCase().includes(query) ||
        user.firstName.toLowerCase().includes(query) ||
        user.role.toLowerCase().includes(query)
    );
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      className={styles.users_list_container}
      style={
        lang === "ar"
          ? {
              direction: "rtl",
            }
          : {}
      }
    >
      <div className={styles.users_list_input_block}>
        <h2 className={styles.users_list_h3}>{text.user_list[lang]}</h2>
        <Input
          style={{ width: "90%", display: "flex", justifyContent: "center" }}
          mode={"primary"}
          className={styles.users_list_input}
          placeholder={lang === "en" ? "Search" : "يبحث"}
          onChange={(e) => {
            setSearchChange(e.target.value);
            setFilterUser(searchUsers(e.target.value));
          }}
          value={onSearchChange}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <span>
            <LoadingSpinner />
          </span>
        ) : users.length !== 0 &&
          onSearchChange.trim() &&
          filterUser.length === 0 ? (
          <p>{lang === "en" ? "user not find" : "المستخدم لا تجد"}</p>
        ) : (
          <div className={styles.users_container}>
            {users.length !== 0 &&
              filterUser.map(
                (user, i) =>
                  user.username !== authData?.username && (
                    <div className={styles.users_row} key={i}>
                      <div
                        className={styles.users_name}
                        style={{ marginLeft: "20px" }}
                      >
                        <span className={styles.name_text}>
                          {text.name_user_list[lang]}
                        </span>
                        <span
                          className={styles.name_text}
                          style={{ color: "black" }}
                        >
                          {textWrap(user.firstName, 20)}
                        </span>
                      </div>
                      <div className={styles.users_name}>
                        <span className={styles.name_text}>
                          {text.username[lang]}
                        </span>
                        <span
                          className={styles.name_text}
                          style={{ color: "black" }}
                        >
                          {textWrap(user.username, 20)}
                        </span>
                      </div>
                      <div className={styles.users_name}>
                        <span className={styles.name_text}>
                          {text.role[lang]}
                        </span>
                        <span
                          className={styles.name_text}
                          style={{ color: "black" }}
                        >
                          {user.role}
                        </span>
                      </div>
                      <div className={styles.users_name}>
                        <span className={styles.name_text}>
                          {text.status[lang]}
                        </span>
                        <span
                          className={styles.name_text}
                          style={{ color: "black" }}
                        >
                          {user.isBlocked
                            ? `${text.blocked[lang]}`
                            : `${text.active[lang]}`}
                        </span>
                      </div>
                      <div
                        className={styles.users_name}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ButtonComponent
                          mode={"primary"}
                          className={
                            !user.isBlocked
                              ? styles.button_status
                              : styles.button_status_active
                          }
                          onClick={async () => {
                            setUserName(user.username);
                            setLoadingBlock(true);
                            const res = await sendPostRequest(
                              "/Login/updateUserStatus",
                              {
                                targetUser: user.username,
                                isBlocked: !user.isBlocked,
                              }
                            );
                            setLoadingBlock(false);
                            if (!res) return;
                            if (res.status === 200) {
                              const elementToUpdate = users.find(
                                (element) => element.username === user.username
                              );
                              if (!elementToUpdate) return;
                              elementToUpdate.isBlocked = !user.isBlocked;
                              const updatedArray = users.map((element) => {
                                if (
                                  element.username === elementToUpdate.username
                                ) {
                                  return elementToUpdate;
                                }
                                return element;
                              });
                              setUsers(updatedArray);
                            }
                          }}
                        >
                          {!loadingBlock ? (
                            !user.isBlocked ? (
                              `${text.blocked[lang]}`
                            ) : (
                              `${text.active[lang]}`
                            )
                          ) : user.username === userName ? (
                            <LoadingSpinner />
                          ) : !user.isBlocked ? (
                            `${text.blocked[lang]}`
                          ) : (
                            `${text.active[lang]}`
                          )}
                        </ButtonComponent>
                      </div>
                    </div>
                  )
              )}
          </div>
        )}
      </div>
    </div>
  );
}
